
export function hasSpecialCharacter(string, option='standard') {
	/* eslint-disable no-useless-escape */
	let pattern = {
		'standard' : new RegExp(/[~`@!#$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/),
		'email' : new RegExp(/[~`!#$%\^%&*+=\-\[\]\\';,/{}()|\\":<>\?]/),
		'phone' : new RegExp(/[~`@!#$%\^&*=\\[\]\\';,/{}()|\\":<>\?]/),
	}
	/* eslint-enable no-useless-escape */

	return pattern[option].test(string);
}


export function formatNumber(number) {
	//format number to 1,234.00
	return number.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

export function isObjectEmpty(obj) {
  for(var key in obj) {
		if(Object.prototype.hasOwnProperty.call(obj, key)){
			return false;
		}
	}
	return true;
}
