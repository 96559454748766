import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {typing, save, load, clearForm} from '../actions/contact';
import {Grid,InputLabel,TextField,Button} from '@material-ui/core';
import {PeopleOutlineRounded} from '@material-ui/icons';
import {hasSpecialCharacter} from '../helpers/GeneralFunctions.js';
import PageHeader from '../components/PageHeader.js';
import {useStyles, InputText, InputTextRequired, SuccessMessage, ErrorMessage} from '../components/FormElements';

const ContactEdit = (props) => {

  const classes = useStyles();

  useEffect(() => {
    props.clearForm();
    props.load({ id: props.match.params.id });
  }, [])

	const handleFormChange = event => {

		let field = event.target.name;
		let value = event.target.value;

		let pattern = ['phone','email'].indexOf(field) !== -1 ? field : 'standard';

		if(hasSpecialCharacter(value, pattern)) {
			return;
		}

    props.typing({ [field]: value })
  }

  const handleFormSubmit = event => {
    event.preventDefault();
		props.save(props.contact);
	}

  return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PageHeader
					icon={<PeopleOutlineRounded />}
					headerTitle="Contacts"
					subTitle={props.contact.id ? "Edit Details" : "Create New"}
				/>
			</Grid>
			<Grid item md={10} sm={12}>
				<form className={"pt-4 " + classes.container} autoComplete="off" onSubmit={handleFormSubmit} id="form">
					<Grid container className="mb-2">
						<Grid item xs={12}>
							<SuccessMessage
								message="Successfully saved."
								display={props.success}
							/>
							<ErrorMessage
								error={props.error}
								display={props.error}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputTextRequired
								label="First Name"
								name="firstname"
								value={props.contact.firstname}
								handleFormChange={(event) => handleFormChange(event)}
							/>
							<InputTextRequired
								label="Last Name"
								name="lastname"
								value={props.contact.lastname}
								handleFormChange={(event) => handleFormChange(event)}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputText
								label="Email"
								name="email"
								value={props.contact.email}
								handleFormChange={(event) => handleFormChange(event)}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputLabel shrink>Phone</InputLabel>
							<TextField
								fullWidth
								variant="outlined"
								margin="dense"
								className="mt-0"
								type="number"
								name="phone"
								value={props.contact.phone}
								onChange={(event) => handleFormChange(event)}
								// required
							/>
						</Grid>
						<Grid item xs={12}>
							<InputText
								label="Address"
								name="address"
								value={props.contact.address}
								handleFormChange={(event) => handleFormChange(event)}
							/>
						</Grid>
						<Grid item xs={12} className="text-right">
							<Button type="submit" variant="contained" color="primary">
								{ props.isProcessing ? "Saving ..." : "Save"}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
  );
}

const mapStateToProps = state => ({
	isProcessing: state.Contact.isProcessing,
  success: state.Contact.success,
	error: state.Contact.error,
  contact: state.Contact.contact,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  load: (payload) => load(payload),
  save: (payload) => save(payload),
  typing: payload => typing(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactEdit);
