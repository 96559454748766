/* global global */

import { createMachine, assign } from 'xstate';

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'User',
    type: 'parallel',
    context: {
      deleted: [],
    },
    states: {
      deleted: {
        id: 'Deleted',
        initial: 'idle',
        states: {
          idle: {
            on: {
              DELETE_FETCH: 'fetch.process',
              DELETE_REVIVE: 'revive.confirm',
            },
          },
          fetch: {
            states: {
              process: {
                invoke: {
                  src: 'deletedFetching',
                  onDone: {
                    target: '#Deleted.idle',
                    actions: ['setDeletedUsers'],
                  },
                  onError: 'failure',
                },
              },
              failure: {},
            },
          },
          revive: {
            states: {
              confirm: {
                on: {
                  DELETE_REVIVE_NO: '#Deleted.idle',
                  DELETE_REVIVE_YES: 'process',
                },
              },
              process: {
                invoke: {
                  src: 'deletedRevivingUser',
                  onDone: {
                    target: 'success',
                    actions: ['deletedRemoveRow'],
                  },
                  onError: 'failure',
                },
              },
              success: {
                on: {
                  CLOSE: '#Deleted.idle',
                },
              },
              failure: {
                on: {
                  DELETE_REVIVE: 'confirm',
                  CLOSE: '#Deleted.idle',
                },
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      deletedRemoveRow: assign({
        deleted(ctx, event) {
          const data = event.data;
          const list = [...ctx.deleted];
          for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (item.id == data.id) {
              list.splice(i, 1);
              break;
            }
          }
          return list;
        },
      }),
      setDeletedUsers: assign({
        deleted(ctx, event) {
          return event.data;
        },
      }),
    },
    services: {
      deletedRevivingUser(ctx, event) {
        return global.axios
          .get('/venue/revive', {
            params: {
              id: event.data,
            },
          })
          .then((response) => response.data);
      },
      deletedFetching(ctx, event) {
        return global.axios.get('/venue/deleted').then((response) => response.data);
      },
    },
  }
);
