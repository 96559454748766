import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { typing, clearForm } from '../actions/deal';
import { loadAll as loadContacts, clearForm as clearContactForm } from '../actions/contact';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextareaAutosize,
  InputLabel,
  TextField,
  Fab,
  Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Add as AddIcon } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { InputSelect, InputTextDollar } from '../components/FormElements';
import DateFnsUtils from '@date-io/date-fns';
import DealContactEditDialog from './DealContactEditDialog.js';
import AlertFormError from '../components/AlertFormError';
import { isObjectEmpty } from '../helpers/GeneralFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const types = [
  { val: '' },
  { val: 'Birthday' },
  { val: 'Corporate' },
  { val: 'Engagement' },
  { val: 'Wedding' },
  { val: 'Other' },
];

const DealEditDialog = (props) => {
  const handleFormChange = (event) => {
    let value = event.target.value;

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    props.typing({ [event.target.name]: value });
  };

  const handleContactChange = (contact_id) => {
    props.typing({ contact_id: contact_id });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    props.handleSave();
  };

  const handleDateChange = (dateStr) => {
    let date = '';

    if (dateStr) {
      dateStr = new Date(dateStr);
      date = dateStr.getFullYear() + '-' + (dateStr.getMonth() + 1) + '-' + dateStr.getDate();
    }

    props.typing({ date: date });
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.closeEditorModal()}
        maxWidth="sm"
        fullWidth
      >
        <form autoComplete="off" onSubmit={handleFormSubmit}>
          <DialogTitle className="modal-header">
            <span>{props.deal.id ? 'Update Deal Details' : 'Create New Deal'}</span>
          </DialogTitle>
          <DialogContent className="pt-4">
            {!isObjectEmpty(props.errors) && <AlertFormError errors={props.errors} />}
            <div className="mb-2">
              <label style={{ fontSize: '12px' }}>Contact</label>
              <ContactSelect
                label="Contact"
                name="contact_id"
                handleSelectChange={(contact_id) => handleContactChange(contact_id)}
                {...props}
              />
            </div>

            <InputLabel shrink> Title </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              className="mt-0"
              name="title"
              defaultValue={props.deal.title}
              onBlur={(event) => handleFormChange(event)}
              error={Object.prototype.hasOwnProperty.call(props.errors, 'title') ? true : false}
            />

            <Grid container spacing={2}>
              <Grid item sm={7} xs={12}>
                <InputLabel shrink> Date </InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils} className="date-picker-div">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    format="MM/dd/yyyy"
                    value={props.deal.date ? new Date(props.deal.date) : null}
                    onChange={handleDateChange}
                    fullWidth
                    animateYearScrolling={true}
                    inputVariant="outlined"
                    margin="dense"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={5} xs={12}>
                <InputTextDollar
                  label="Value (optional)"
                  name="value"
                  value={props.deal.value}
                  handleFormChange={(event) => handleFormChange(event)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item sm={7} xs={12}>
                <InputSelect
                  label="Type"
                  name="type"
                  value={props.deal.type}
                  handleSelectChange={(event) => handleFormChange(event)}
                  optionText="val"
                  optionVal="val"
                  data={types}
                />
              </Grid>
              {props.deal.type === 'Other' && (
                <Grid item sm={5} xs={12}>
                  <InputLabel shrink> Specify </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    className={props.deal.type === 'Other' ? 'd-block' : 'd-none'}
                    name="type_other"
                    defaultValue={props.deal.type_other}
                    onBlur={(event) => handleFormChange(event)}
                    error={
                      Object.prototype.hasOwnProperty.call(props.errors, 'type_other')
                        ? true
                        : false
                    }
                  />
                </Grid>
              )}
            </Grid>

            <label style={{ fontSize: '12px' }}>Description</label>
            <TextareaAutosize
              style={{ width: '100%', padding: '4px' }}
              aria-label="minimum height"
              rows={3}
              name="notes"
              defaultValue={props.deal.notes}
              onBlur={(event) => handleFormChange(event)}
            />
            {props.deal.id && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.deal.archived ? true : false}
                        onChange={(event) => handleFormChange(event)}
                        name="archived"
                      />
                    }
                    label="Archived this deal"
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.closeEditorModal()} color="primary" className="mr-1 mb-2">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" className="mr-3 mb-2">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const ContactSelect = (props) => {
  const [contact, setContact] = React.useState({
    firstname: '',
    lastname: '',
  });

  useEffect(() => {
    if (props.deal.contact_id !== '') {
      let temp_contact = props.contacts.filter((item) => item.id === props.deal.contact_id)[0];
      setContact(temp_contact);
    }
  }, [props.deal.contact_id]);

  const handleOnChange = (event, contact) => {
    props.handleSelectChange(contact.id);
    setContact(props.contacts.filter((item) => item.id === contact.id)[0]);
  };

  const [editorOpen, setEditorOpen] = React.useState(false);
  const openEditorModal = () => {
    props.clearContactForm();
    setEditorOpen(true);
  };
  const closeEditorModal = async () => {
    setEditorOpen(false);
    props.clearContactForm();
  };

  const handleSaveSuccess = async (new_contact) => {
    await props.loadContacts();
    setContact(new_contact);
    props.handleSelectChange(new_contact.id);
    closeEditorModal();
  };

  return (
    <Grid container>
      <Grid item xs={11}>
        {editorOpen && (
          <DealContactEditDialog
            open={editorOpen}
            openEditorModal={() => openEditorModal()}
            closeEditorModal={() => closeEditorModal()}
            handleSaveSuccess={(contact) => handleSaveSuccess(contact)}
          />
        )}
        <Autocomplete
          disableClearable
          options={props.contacts}
          getOptionLabel={(option) =>
            `${option.firstname} ${option.lastname}` + (option.email ? ` (${option.email})` : '')
          }
          value={contact}
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
              value={
                `${contact.firstname} ${contact.lastname}` +
                (contact.email ? ` (${contact.email})` : '')
              }
              error={
                Object.prototype.hasOwnProperty.call(props.errors, 'contact_id') ? true : false
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={1} className="text-right">
        <Fab size="small" color="primary" onClick={() => openEditorModal()}>
          <AddIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isProcessing: state.Deal.isProcessing,
  success: state.Deal.success,
  errors: state.Deal.errors,
  deal: state.Deal.deal,
  contacts: state.Contact.contacts,
  contact: state.Contact.contact,
});

const mapDispatchToProps = {
  typing: (payload) => typing(payload),
  clearForm: () => clearForm(),
  loadContacts: () => loadContacts(),
  clearContactForm: () => clearContactForm(),
};

export default connect(mapStateToProps, mapDispatchToProps)(DealEditDialog);
