import * as Yup from 'yup';
import Localization from '../localization';

Yup.addMethod(Yup.string, 'decimal', function decimal(message = '') {
  return this.test(
    'is-decimal',
    ({ label }) => {
      const hasMsg = Boolean(message);
      return !hasMsg ? Localization.formatString(Localization.formik.decimal, label) : message;
    },
    (value, _testContext) => {
      if (value === undefined) {
        return false;
      }
      if (value.length === 1) {
        return /\d/.test(value);
      }
      return /^([0-9]+,?.?[0-9]+)+$/.test(value);
    }
  );
});

export default Yup;
