/* global global */
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import axios from 'axios';
import { CONFIG } from './config.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme.js';
import { GlobalStateProvider } from './machines/state';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './App.css';

global.axios = axios;
axios.defaults.baseURL = CONFIG.API_URL;

axios.interceptors.request.use(
  (config) => {
    let token = store.getState().Auth.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    console.error(error);
  }
);

ReactDOM.render(
  <HashRouter>
    <GlobalStateProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </GlobalStateProvider>
  </HashRouter>,
  document.getElementById('root')
);
