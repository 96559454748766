import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Enums from '../enums';
import moment from 'moment';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
// Icons
// import AddIcon from '@material-ui/icons/Add';
// Components
import Dialog from '../components/Dialog';
import Inputs from '../components/Inputs';
// Validator
import { useFormik } from 'formik';
// import * as yup from 'yup';
// Localization
import Localization from '../localization';
import yup from '../helpers/yup';

const DEFAULT_VALUES = {
  firstname: '',
  lastname: '',
  emailaddress: '',
  phone: '',
  address: '',
  contactType: '',
  compFileRef: '',
  docketNumb: '',
  p1Name: '',
  p2Name: '',
  approveDate: '',
  approveAmount: '',
  fileRef: '',
};

function ContactsCreateDialog({ errors, open, reset, onSave, onCancel, submitting }) {
  const formik = useFormik({
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: yup.object({
      firstname: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'first name')),
      // lastname: yup
      //   .string()
      //   .required(Localization.formatString(Localization.formik.required, 'last name')),
      emailaddress: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'email address'))
        .email(Localization.formatString(Localization.formik.email, 'email address')),
      phone: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'phone'))
        .test(
          'is-phone-number',
          Localization.formatString(Localization.formik.isPhoneNumber, 'phone'),
          function (value, context) {
            return /^[\d\-+()\s]+$/i.test(value);
          }
        ),
      address: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'address')),
      contactType: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'contact type')),
      compFileRef: yup.string().when('contactType', {
        is: (val) => val === 'Investor',
        then: (schema) =>
          schema.required(
            Localization.formatString(Localization.formik.required, 'Company File Ref')
          ),
        otherwise: (schema) => schema,
      }),
      docketNumb: yup.string().nullable(),
      // .when('contactType', {
      //   is: (val) => val === 'Investor',
      //   then: (schema) =>
      //     schema.required(Localization.formatString(Localization.formik.required, 'Docket Number')),
      //   otherwise: (schema) => schema,
      // }),
      p1Name: yup.string().nullable(),
      // .when('contactType', {
      //   is: (val) => val === 'Investor',
      //   then: (schema) =>
      //     schema.required(Localization.formatString(Localization.formik.required, 'Person 1 Name')),
      //   otherwise: (schema) => schema,
      // }),
      p2Name: yup.string().nullable(),
      // .when('contactType', {
      //   is: (val) => val === 'Investor',
      //   then: (schema) =>
      //     schema.required(Localization.formatString(Localization.formik.required, 'Person 2 Name')),
      //   otherwise: (schema) => schema,
      // }),
      approveAmount: yup.string().when('contactType', {
        is: (val) => val === 'Client',
        then: (schema) =>
          schema
            .required(Localization.formatString(Localization.formik.required, 'Approve Amount'))
            .decimal(Localization.formatString(Localization.formik.decimal, 'Approve Amount')),
        otherwise: (schema) => schema,
      }),
      approveDate: yup.mixed().when('contactType', {
        is: (val) => val === 'Client',
        then: (schema) =>
          schema
            .test(
              'non-string',
              Localization.formatString(Localization.formik.notValiDate, 'Approvee Date'),
              (text) => {
                return moment(text).isValid();
              }
            )
            .required(Localization.formatString(Localization.formik.required, 'Approvee Date')),
        otherwise: (schema) => schema,
      }),
      fileRef: yup.string().when('contactType', {
        is: (val) => val === 'Client',
        then: (schema) =>
          schema.required(Localization.formatString(Localization.formik.required, 'File Ref')),
        otherwise: (schema) => schema,
      }),
    }),
    onSubmit(values) {
      formik.setSubmitting(false);
      // console.log({...values, contactType:values.contactType.toLocaleLowerCase()})
      onSave({
        ...values,
        approveAmount: values.approveAmount.replaceAll(',', ''),
        contactType: values.contactType.toLocaleLowerCase(),
      });
      // console.log("Saving contact")
    },
  });
  const handleOnClose = () => {
    formik.resetForm({
      values: { ...DEFAULT_VALUES },
    });
    onCancel();
  };
  useEffect(() => {
    const fields = Object.keys(errors);
    if (fields.length > 0) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        formik.setFieldError(field, errors[field]);
      }
    }
  }, [errors]);
  useEffect(() => {
    if (reset) {
      formik.resetForm({
        values: { ...DEFAULT_VALUES },
      });
    }
  }, [reset]);
  return (
    <Dialog
      title={
        formik.values.contactType === Enums.Contact.TYPE.CLIENT
          ? 'Create New EBX Contact'
          : 'Create New BWHF Contact'
      }
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button
            disabled={formik.isSubmitting || submitting}
            color="secondary"
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting || submitting}
            color="primary"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Inputs.Text
            label="Name"
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={
              formik.touched.firstname &&
              Boolean(formik.errors.firstname) &&
              formik.errors.firstname
            }
          />
        </Grid>
        {/*<Grid item xs={12}>
          <Inputs.Text
            label="Last Name"
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={
              formik.touched.lastname && Boolean(formik.errors.lastname) && formik.errors.lastname
            }
          />
        </Grid>*/}
        <Grid item xs={12}>
          <Inputs.Text
            label="Email Address"
            name="emailaddress"
            value={formik.values.emailaddress}
            onChange={formik.handleChange}
            error={formik.touched.emailaddress && Boolean(formik.errors.emailaddress)}
            helperText={
              formik.touched.emailaddress &&
              Boolean(formik.errors.emailaddress) &&
              formik.errors.emailaddress
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Inputs.Text
            label="Phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && Boolean(formik.errors.phone) && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <Inputs.TextArea
            label="Address"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={
              formik.touched.address && Boolean(formik.errors.address) && formik.errors.address
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Inputs.Select
            label="Contact Type"
            options={[
              { label: 'Select', value: '' },
              Enums.Contact.TYPE.INVESTOR,
              {
                label: Enums.Contact.TYPE_LABEL[Enums.Contact.TYPE.CLIENT],
                value: Enums.Contact.TYPE.CLIENT,
              },
            ]}
            name="contactType"
            value={formik.values.contactType}
            onChange={formik.handleChange}
            error={formik.touched.contactType && Boolean(formik.errors.contactType)}
            helperText={
              formik.touched.contactType &&
              Boolean(formik.errors.contactType) &&
              formik.errors.contactType
            }
          />
        </Grid>
        {formik.values.contactType === Enums.Contact.TYPE.INVESTOR && (
          <InvestorFields formik={formik} />
        )}
        {formik.values.contactType === Enums.Contact.TYPE.CLIENT && (
          <ClientFields formik={formik} />
        )}
      </Grid>
    </Dialog>
  );
}

const InvestorFields = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <Inputs.Text
          label="Company Code"
          name="compFileRef"
          value={formik.values.compFileRef}
          onChange={formik.handleChange}
          error={formik.touched.compFileRef && Boolean(formik.errors.compFileRef)}
          helperText={
            formik.touched.compFileRef &&
            Boolean(formik.errors.compFileRef) &&
            formik.errors.compFileRef
          }
        />
      </Grid>
      {/*<Grid item xs={12}>
        <Inputs.Text
          label="Docket Number"
          name="docketNumb"
          value={formik.values.docketNumb}
          onChange={formik.handleChange}
          error={formik.touched.docketNumb && Boolean(formik.errors.docketNumb)}
          helperText={
            formik.touched.docketNumb &&
            Boolean(formik.errors.docketNumb) &&
            formik.errors.docketNumb
          }
        />
      </Grid>
      */}
      <Grid item xs={12}>
        <Inputs.Text
          label="Person 1 Name"
          name="p1Name"
          value={formik.values.p1Name}
          onChange={formik.handleChange}
          error={formik.touched.p1Name && Boolean(formik.errors.p1Name)}
          helperText={
            formik.touched.p1Name && Boolean(formik.errors.p1Name) && formik.errors.p1Name
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Inputs.Text
          label="Person 2 Name"
          name="p2Name"
          value={formik.values.p2Name}
          onChange={formik.handleChange}
          error={formik.touched.p2Name && Boolean(formik.errors.p2Name)}
          helperText={
            formik.touched.p2Name && Boolean(formik.errors.p2Name) && formik.errors.p2Name
          }
        />
      </Grid>
    </>
  );
};

const ClientFields = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <Inputs.TextDate
          label="Line of Credit Approve Date"
          name="approveDate"
          value={formik.values.approveDate}
          onChange={formik.handleChange}
          error={formik.touched.approveDate && Boolean(formik.errors.approveDate)}
          helperText={
            formik.touched.approveDate &&
            Boolean(formik.errors.approveDate) &&
            formik.errors.approveDate
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Inputs.TextDollar
          label="Line of credit approved amount"
          name="approveAmount"
          value={formik.values.approveAmount}
          onChange={formik.handleChange}
          error={formik.touched.approveAmount && Boolean(formik.errors.approveAmount)}
          helperText={
            formik.touched.approveAmount &&
            Boolean(formik.errors.approveAmount) &&
            formik.errors.approveAmount
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Inputs.Text
          label="Company File"
          name="fileRef"
          value={formik.values.fileRef}
          onChange={formik.handleChange}
          error={formik.touched.fileRef && Boolean(formik.errors.fileRef)}
          helperText={
            formik.touched.fileRef && Boolean(formik.errors.fileRef) && formik.errors.fileRef
          }
        />
      </Grid>
    </>
  );
};

ContactsCreateDialog.propTypes = {
  errors: PropTypes.object,
  open: PropTypes.bool,
  reset: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
};
ContactsCreateDialog.defaultProps = {
  errors: {},
  submitting: false,
};

export default ContactsCreateDialog;
