export default {
  formik: {
    required: 'The {0} field is required.',
    email: 'The {0} field is invalid.',
    isPhoneNumber: 'The {0} field is not valid.',
    maxAmount: 'The {0} should not be greater than {1} and equal to 0.',
    mustBeString: 'The {0} field must be string.',
    notValiDate: 'The {0} is not valid date format.',
    decimal: 'The {0} is not on decimal format.',
    deal: {
      insuficientBalance: 'The available balance in the investment deal is insufficient.',
      amountIsZero: 'The {0} should be greater than 0 and not equal to 0.'
    }
  },
  deal: {
    initialize: 'Please wait. . .',
    create: {
      process: 'Creating new deal. . .',
      success: 'New deal created!',
      failure: 'Failed to create deal.',
    },
    edit: {
      process: 'Updating deal. . .',
      success: 'Deal updated!',
      invalid: 'Invalid deal fields.',
      failure: 'Failed to update deal.',

      transfer: {
        process: 'Transferring. . .',
        success: 'Deal successfully transfered!',
        failure: 'Failed to transfer!',

        remove: {
          process: 'Removing transfer record. . .',
          success: 'Transfer record successfully removed.',
          failure: 'Failed to remove transfer record.',
        },
      },
    },

    transfer: {
      edit: {
        fetch: {
          process: 'Fetching transfer detail. . .',
          failure: 'Failed to fetch transfer detail!',
        },
        update: {
          process: 'Updating transfer detail. . .',
          failure: 'Failed to update transfer detail!',
          success: 'Transfer successfully updated!',
        },
      },
    },
    contact: {
      create: {
        process: 'Creating new contact. . .',
        success: 'New contact was added!',
        invalid: 'Invalid form field.',
        failure: 'Failed to create contact.',
      },
    },
    board: {
      removingColumn: 'Removing column. . .',
      failedToRemove: 'Failed to remove column!',
      updatingBoard: 'Updating board. . .',
      columnCreating: 'Creating column. . .',
      columnCreated: 'Column created!',
      columnFailed: 'Failed to create column!',
      columnUpdating: 'Updating column. . .',
      columnUpdateFailed: 'Failed to update column!',
      card: {
        remove: {
          process: 'Removing card. . .',
          failure: 'Failed to remove card!',
        },
      },
    },
  },
  contact: {
    create: {
      process: 'Creating new contact. . .',
      success: 'New contact created!',
      failure: 'Failed to create contact.',
      invalid: 'Invalid form field.',
    },
    edit: {
      process: 'Saving changes. . .',
      success: 'Contact detail updated!',
      failure: 'Failed to update contact.',
      invalid: 'Invalid form field.',
    },
  },
};
