import {CONTACT} from '../constants/contact';

const initialState = {
  error: '',
  success: false,
	isProcessing: false,
  contacts: [],
  contact: {
    'id': '',
    'lastname': '',
    'firstname': '',
    'address': '',
    'email': '',
    'phone': '',
    'status': 'active',
    'venue_id': '',
	},
};

const Contact = (state = initialState, action) => {

  let newState = JSON.parse(JSON.stringify(state));

  if(localStorage.user && newState.contact.venue_id === '') {
    let user = JSON.parse(localStorage.user);
    newState.contact.venue_id = user.venue_id;
  }

  switch(action.type) {

		case CONTACT.CONTACT_CLEAR_FORM : {

      newState.error = false;
      newState.success = false;
      newState.isProcessing = false;

			newState.contact = {
				'id': '',
				'lastname': '',
				'firstname': '',
				'address': '',
				'email': '',
        'phone': '',
        'status': 'active',
        'venue_id': newState.contact.venue_id,
			}

			return newState;
    }

    case CONTACT.CONTACT_DELETE : {
      newState.contacts = action.payload;
			return newState;
		}

    case CONTACT.CONTACT_LOAD : {
      newState.contact = action.payload;
			return newState;
		}

		case CONTACT.CONTACT_LOAD_ALL : {
			newState.contacts = action.payload;
      return newState;
		}

    case CONTACT.CONTACT_SAVE_REQUEST : {

			newState.isProcessing = true;
			newState.error = '';
			newState.success = false;

			return newState;
    }

    case CONTACT.CONTACT_SAVE_SUCCESS : {

			newState.error = '';
			newState.isProcessing = false;
			newState.success = true;
			newState.contact.id = action.payload.id;

			return newState;
    }

    case CONTACT.CONTACT_SAVE_ERROR : {

			newState.isProcessing = false;
			newState.success = false;

      let err = [];

      if(typeof action.payload === 'object') {
        for(let i in action.payload) {
          err.push(action.payload[i][0]);
        }
      }
      else if(Array.isArray(action.payload)) {
        err = action.payload;
      }
      else {
        err[0] = action.payload;
			}

      newState.error = err;

			return newState;
		}

    case CONTACT.CONTACT_TYPING : {

			newState.error = '';
			let key = Object.keys(action.payload)[0];
			newState.contact[key] = action.payload[key];

			return newState;
    }

    default: return state;
  }

}

export default Contact;
