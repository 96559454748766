import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// Components
import Dialog from '../components/Dialog'


function DealBoardCardConfirmDeleteDialog({
  open,
  onConfirm,
  onCancel
}) {
  
  return (
    <Dialog
      title="Are you sure?"
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button color="secondary" onClick={() => onCancel()}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={() => onConfirm()}>Yes</Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Are you sure to remove card?</Typography>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DealBoardCardConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default DealBoardCardConfirmDeleteDialog