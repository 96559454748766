import React from 'react';
// import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EqualizerRounded from '@material-ui/icons/EqualizerRounded';
import Alerts from '../components/Alerts';

import PageHeader from '../components/PageHeader';
import BankAccounts from './FundManagerReport.bankAccounts';
import Disbursements from './FundManagerReport.Disbursements';

import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';

const FundManagerReport = () => {
  const Context = React.useContext(GlobalState);
  const ReportState = useActor(Context.fundManagerReportState);

  const handleExport = () => {
    ReportState[1]('EXPORT_PROCESS');
  };
  // React.useEffect(() => {
  //   console.info(ReportState[0].value)
  // }, [ReportState[0]])
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <PageHeader headerTitle="Deals Overview Report" icon={<EqualizerRounded />} />
          <Alerts.Loading
            open={ReportState[0].matches('export.process')}
            message="Exporting reports. . ."
          />
          <Alerts.Failure
            open={ReportState[0].matches('export.failure')}
            message="Failed to export"
            onClose={() => ReportState[1]('EXPORT_CLOSE')}
          />
          <Alerts.Success
            open={ReportState[0].matches('export.success')}
            message="Report successfuly generated!"
            onClose={() => ReportState[1]('EXPORT_CLOSE')}
          />
        </div>
        <Button variant="contained" color="primary" onClick={handleExport}>
          Export
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BankAccounts />
      </Grid>
      <Grid item xs={12}>
        <Disbursements />
      </Grid>
    </Grid>
  );
};
// FundManagerReport.propTypes = {

// }
// FundManagerReport.defaultProps = {

// }

export default FundManagerReport;
