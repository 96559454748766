import React from 'react';
import PropTypes from 'prop-types';
// Material
import FormHelperText from '@material-ui/core/FormHelperText';
import { default as MUIAutocomplete } from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  TextDateError: {
    '& .MuiInputBase-root': {
      border: '1px solid ' + theme.palette.error.main,
    },
  },
}));

const Text = (props) => {
  return (
    <div className="form-group mb-0">
      <InputLabel shrink> {props.label} </InputLabel>
      <TextField
        {...props}
        label=""
        fullWidth
        type="text"
        margin="dense"
        className="mt-0"
        variant="outlined"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};
Text.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  // value:
  onChange: PropTypes.func,
};
Text.defaultProps = {
  onChange: () => {},
};

const TextPercent = (props) => {
  return (
    <div className="form-group">
      <InputLabel shrink> {props.label} </InputLabel>
      <TextField
        {...props}
        label=""
        fullWidth
        type="number"
        margin="dense"
        className="mt-0"
        variant="outlined"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </div>
  );
};
TextPercent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const TextDollar = (props) => {
  const onChange = (e) => {
    const value = e.currentTarget.value;
    const formatted = Number(value.replaceAll(',', '')).toLocaleString();

    e.currentTarget.value = formatted === 'NaN' ? value : formatted;
    props.onChange(e);
  };
  return (
    <div className="form-group">
      <InputLabel shrink> {props.label} </InputLabel>
      <TextField
        {...props}
        label=""
        fullWidth
        margin="dense"
        className="mt-0"
        variant="outlined"
        name={props.name}
        value={props.value}
        onChange={onChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">$</InputAdornment>,
        }}
      />
    </div>
  );
};
TextDollar.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

function TextDate(props) {
  const classes = useStyles();
  // const theme = useTheme()
  const handleChange = (date) => {
    props.onChange({
      target: {
        name: props.name,
        value: date,
      },
    });
  };
  return (
    <>
      <InputLabel shrink>{props.label}</InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils} className="date-picker-div">
        <KeyboardDatePicker
          className={props.error ? classes.TextDateError : null}
          disableToolbar
          autoOk
          format={props.format}
          value={props.value ? new Date(props.value) : null}
          onChange={handleChange}
          fullWidth
          animateYearScrolling={true}
          inputVariant="outlined"
          margin="dense"
          placeholder={props.placeholder}
        />
      </MuiPickersUtilsProvider>
      {Boolean(props.helperText) && (
        <FormHelperText error={true}>{props.helperText}</FormHelperText>
      )}
    </>
  );
}
TextDate.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  format: PropTypes.string,
};
TextDate.defaultProps = {
  onChange: () => {},
  placeholder: 'dd/mm/yyyy',
  format: 'dd/MM/yyyy',
};

function Select(props) {
  const theme = useTheme();
  return (
    <>
      <InputLabel shrink>{props.label}</InputLabel>
      <select
        disabled={props.disabled}
        style={{ borderColor: props.error ? theme.palette.error.main : '' }}
        className="form-control"
        name={props.name}
        value={props.value}
        onChange={props.onChange ? props.onChange : () => {}}
      >
        {(props.options || []).map((val) => {
          if (['string', 'number'].includes(typeof val)) {
            return (
              <option key={val} value={val}>
                {val}
              </option>
            );
          }
          return (
            <option key={val[props.optionVal || 'value']} value={val[props.optionVal || 'value']}>
              {val[props.optionText || 'label']}
            </option>
          );
        })}
      </select>
      {Boolean(props.helperText) && (
        <FormHelperText error={true} variant="outlined">
          {props.helperText}
        </FormHelperText>
      )}
    </>
  );
}
Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  options: PropTypes.array,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
Select.defaultProps = {
  disabled: false,
};

const TextArea = (props) => {
  return (
    <div className="form-group">
      <InputLabel shrink> {props.label} </InputLabel>
      <TextField
        {...props}
        minRows={4}
        multiline={true}
        label=""
        fullWidth
        type="text"
        margin="dense"
        className="mt-0"
        variant="outlined"
        name={props.name}
        value={props.value}
        onChange={props.onChange ? props.onChange : null}
      />
    </div>
  );
};
TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

function AutoComplete(props) {
  const { error, helperText: helpertext, ...acProps } = props;
  acProps.helpertext = helpertext;
  const label = props.label;
  const handleChange = (event, value) => {
    props.onChange({
      target: {
        name: props.name,
        value: value,
      },
    });
  };
  return (
    <>
      {Boolean(label) && <InputLabel shrink> {label} </InputLabel>}
      <MUIAutocomplete
        {...acProps}
        onChange={handleChange}
        options={props.options || []}
        getOptionLabel={(option) => option[props.optionlabel || 'label'] || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            size="small"
            placeholder={props.placeholder}
            fullWidth
            variant="outlined"
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </>
  );
}
AutoComplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  optionlabel: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};
AutoComplete.defaultProps = {
  name: '',
  onChange: () => {},
};

export default {
  Text,
  TextPercent,
  TextDollar,
  TextDate,
  Select,
  TextArea,
  AutoComplete,
};
