import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import AddIcon from '@material-ui/icons/Add';
// Components
import Dialog from '../components/Dialog'
import Inputs from '../components/Inputs'
// Validator
import Localization from '../localization'
import { useFormik } from 'formik'
import * as yup from 'yup'

const DEFAULT_VALUES = {
  columnName: ''
}

function DealBoardEditColumnDialog({
  data,
  errors,
  open,
  reset,
  onSave,
  onCancel
}) {
  const formik = useFormik({
    initialValues: {...DEFAULT_VALUES},
    validationSchema: yup.object({
      columnName: yup.string()
        .required(Localization.formatString(Localization.formik.required, 'column name'))
    }),
    onSubmit(values) {
      formik.setSubmitting(false)
      onSave(values)
    }
  })
  const handleOnClose = () => {
    formik.resetForm({
      values: {...DEFAULT_VALUES}
    })
    onCancel()
  }
  useEffect(() => {
    const fields = Object.keys(data)
    if(fields.length > 0) {
      formik.setFieldValue('columnName', data.title)
    }
  }, [data])
  // useEffect(() => {
  //   if(reset) {
  //     formik.resetForm({
  //       values: {...DEFAULT_VALUES}
  //     })
  //   }
  // }, [reset])
  // useEffect(() => {
  //   const fields = Object.keys(errors)
  //   if(fields.length) {
  //     for(let i = 0; i < fields.length; i++) {
  //       const field = fields[i]
  //       formik.setFieldError(field, errors[field][0])
  //     }
  //   }
  // }, [errors])
  return (
    <Dialog
      title="Create New Deal"
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button color="secondary" onClick={handleOnClose}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={formik.handleSubmit}>Save</Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Inputs.Text
            label="Column name"
            name="columnName"
            value={formik.values.columnName}
            onChange={formik.handleChange}
            error={formik.touched.columnName && Boolean(formik.errors.columnName)}
            helperText={formik.touched.columnName && Boolean(formik.errors.columnName) && formik.errors.columnName}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

DealBoardEditColumnDialog.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  open: PropTypes.bool.isRequired,
  reset: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
DealBoardEditColumnDialog.defaultProps = {
  data: {},
  errors: {}
}

export default DealBoardEditColumnDialog