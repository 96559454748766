import React from 'react';
import PropTypes from 'prop-types';
// Maerial
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import CountUp from 'react-countup';
import CircularProgress from '@material-ui/core/CircularProgress';

const Counter = ({ balance, deals, header, headers, borderRight, color, loading }) => {
  const theme = useTheme();
  const borderColor = theme.palette.grey[400];
  const textColor = theme.palette[color].main || theme.palette.info.main;
  const loaderStyle = { display: 'flex', justifyContent: 'center', flexDirection: 'column' };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        borderRight: borderRight ? '1px solid ' + borderColor : '',
        padding: 20,
      }}
    >
      {!balance && (
        <Box sx={{ paddingRight: 20, borderRight: '1px solid ' + borderColor, ...loaderStyle }}>
          {(() => {
            if (loading) {
              return (
                <Typography>
                  <CircularProgress />
                </Typography>
              );
            }
            return (
              <>
                <Typography align="center" variant="h5">
                  Deal
                </Typography>
                <br />
                <Typography align="center" variant="h5" style={{ color: textColor }}>
                  <CountUp start={0} end={deals} duration={3} />
                </Typography>
              </>
            );
          })()}
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          paddingLeft: !balance ? 20 : 0,
          ...loaderStyle,
        }}
      >
        {(() => {
          if (loading) {
            return (
              <Typography align="center">
                <CircularProgress />
              </Typography>
            );
          }

          return (
            <>
              <Typography align="center" variant="h5">
                {header}
              </Typography>
              <br />
              <Typography align="center" variant="h5" style={{ color: textColor }}>
                $<CountUp end={headers} duration={3} separator="," decimal="." decimals={2} />
              </Typography>
            </>
          );
        })()}
      </Box>
    </Box>
  );
};
Counter.propTypes = {
  balance: PropTypes.bool,
  deals: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  headers: PropTypes.number.isRequired,
  borderRight: PropTypes.bool,
  color: PropTypes.oneOf(['info', 'success', 'error', 'warning']),
  loading: PropTypes.bool,
};
Counter.defaultProps = {
  balance: false,
  borderRight: true,
  color: 'info',
  loading: false,
};

const CardCount = ({ balance, loading, title, nonSmsf, smsf, total }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5">
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Counter
                balance={balance}
                loading={loading}
                deals={nonSmsf.deals}
                header={nonSmsf.title}
                headers={nonSmsf.amount}
                color="info"
              />
            </Grid>
            <Grid item xs={4}>
              <Counter
                balance={balance}
                loading={loading}
                deals={smsf.deals}
                header={smsf.title}
                headers={smsf.amount}
                color="success"
              />
            </Grid>
            {Boolean(total) && (
              <Grid item xs={4}>
                <Counter
                  balance={balance}
                  loading={loading}
                  deals={total.deals}
                  header={total.title}
                  headers={total.amount}
                  borderRight={false}
                  color="warning"
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
CardCount.propTypes = {
  balance: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  nonSmsf: PropTypes.shape({
    title: PropTypes.string,
    deals: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
  smsf: PropTypes.shape({
    title: PropTypes.string,
    deals: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
  total: PropTypes.shape({
    title: PropTypes.string,
    deals: PropTypes.number,
    amount: PropTypes.number,
  }),
};

CardCount.defaultProps = {
  balance: false,
  total: null,
  loading: false,
};

export default CardCount;
