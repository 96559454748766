import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from './Dialog';

const DealBoardCardConfirmDeleteDialog = ({ open, onConfirm, onCancel }) => {
  return (
    <Dialog
      title="Are you sure?"
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button color="secondary" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => onConfirm()}>
            Yes
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Are you sure to remove transfer record?</Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DealBoardCardConfirmDeleteDialog;
