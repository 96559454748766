import React from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';

const PrivateRoutes = (props) => {
	return(
		<div>
			{ props.isLoggedIn ? props.children : <Redirect to='/login' /> }
		</div>
	)
}
		
const mapStateToProps = state => ({
	isLoggedIn: state.Auth.isLoggedIn
});

export default connect(
	mapStateToProps
)(PrivateRoutes);