/* global global */
import { createMachine } from 'xstate'

export default createMachine({
  id: 'Stage',
  on: {
    FETCH: 'fetch.process'
  },
  initial: 'idle',
  context: {
    list: [],
  },
  states: {
    idle: {
      // on: {
      //   FETCH: 'fetch.process'
      // }
    },
    fetch: {
      states: {
        process: {
          invoke: {
            src: 'fetching',
            onDone: 'success',
            onError: 'failure'
          }
        },
        success: {},
        failure: {},
      }
    }
  }
}, {
  services: {
    fetching(ctx, event) {
      return global.axios.get('/v2/stages')
    }
  }
})