import React from 'react';
import { connect } from 'react-redux';
import { typing, save } from '../actions/contact';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide,Typography, TextField } from '@material-ui/core';
import FormErrorMessage from '../components/FormErrorMessage';
import {hasSpecialCharacter} from '../helpers/GeneralFunctions.js';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const DealContactEditDialog = (props) => {


	const handleFormChange = event => {

		let field = event.target.name;
		let value = event.target.value;

		let pattern = ['phone','email'].indexOf(field) !== -1 ? field : 'standard';

		if(hasSpecialCharacter(value, pattern)) {
			return;
		}

    props.typing({ [event.target.name]: event.target.value })
	}

	const submitContactForm = async () => {
		let response = await props.save();
		if(response) {
			props.handleSaveSuccess(response);
		}
	}

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
				onClose={() => props.closeEditorModal()}
				maxWidth="sm"
				fullWidth
      >
        <form autoComplete="off">
					<DialogTitle className='modal-header'>
						Create New Contact
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<FormErrorMessage error={props.error} />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" component="div" color="textSecondary" className="mb-1">
									Last Name
								</Typography>
								<TextField
									fullWidth
									size="small"
									margin="dense"
									variant="outlined"
									name="lastname"
									defaultValue={props.contact.lastname}
									onBlur={(event) => handleFormChange(event)}
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" component="div" color="textSecondary" className="mb-1">
									First Name
								</Typography>
								<TextField
									fullWidth
									size="small"
									margin="dense"
									variant="outlined"
									name="firstname"
									defaultValue={props.contact.firstname}
									onBlur={handleFormChange}
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" component="div" color="textSecondary" className="mb-1">
									Email (optional)
								</Typography>
								<TextField
									fullWidth
									size="small"
									margin="dense"
									variant="outlined"
									name="email"
									defaultValue={props.contact.email}
									onBlur={handleFormChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" component="div" color="textSecondary" className="mb-1">
									Phone (optional)
								</Typography>
								<TextField
									fullWidth
									size="small"
									margin="dense"
									variant="outlined"
									type="number"
									name="phone"
									defaultValue={props.contact.phone}
									onBlur={handleFormChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" component="div" color="textSecondary" className="mb-1">
									Address (optional)
								</Typography>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									multiline={true}
									rows={2}
									rowsMax={4}
									name="address"
									defaultValue={props.contact.address}
									onBlur={handleFormChange}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button color="primary" className="mr-1 mb-2" onClick={props.closeEditorModal}>
							Cancel
						</Button>
						<Button type="button" variant="contained" color="secondary" className="mr-3 mb-2"  onClick={() => submitContactForm()}>
							Save
						</Button>
					</DialogActions>
				</form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
	isProcessing: state.Contact.isProcessing,
	success: state.Contact.success,
	error: state.Contact.error,
	contact: state.Contact.contact,
});

const mapDispatchToProps = {
	typing: (payload) => typing(payload),
	save: () => save(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealContactEditDialog);
