/* global global */
import { createMachine, assign } from 'xstate';
import FileSaver from 'file-saver';

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'Report',
    type: 'parallel',
    context: {
      investments: [],
      disbursements: [],
    },
    states: {
      bankAccount: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              BANKACCOUNT_FETCH: 'fetch',
            },
          },
          fetch: {
            invoke: {
              src: 'fetchReceived',
              onDone: {
                target: 'success',
                actions: ['setDeals'],
              },
              onError: 'failure',
            },
          },
          success: {
            on: {
              BANKACCOUNT_REFRESH: 'fetch',
            },
          },
          failure: {
            on: {
              BANKACCOUNT_RETRY: 'fetch',
            },
          },
        },
      },
      disbursements: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              DISBURSEMENT_FETCH: 'fetch',
            },
          },
          fetch: {
            invoke: {
              src: 'fetchDisbursements',
              onDone: {
                target: 'success',
                actions: ['setDisbursements'],
              },
              onError: 'failure',
            },
          },
          success: {
            on: {
              DISBURSEMENT_REFRESH: 'fetch',
            },
          },
          failure: {
            on: {
              DISBURSEMENT_RETRY: 'fetch',
            },
          },
        },
      },
      export: {
        id: 'Export',
        initial: 'idle',
        on: {
          EXPORT_PROCESS: '.process',
        },
        states: {
          idle: {},
          process: {
            invoke: {
              src: 'generateExportReport',
              onDone: {
                target: 'success',
                actions: ['saveDocument']
              },
              onError: 'failure',
            },
          },
          success: {
            on: {
              EXPORT_CLOSE: '#Export.idle',
            },
          },
          failure: {
            on: {
              EXPORT_CLOSE: '#Export.idle',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      saveDocument(ctx, event) {
        FileSaver.saveAs(event.data.data, 'Report.xlsx')
      },
      setDisbursements: assign({
        disbursements: (ctx, event) => {
          const disbursements = event.data.data;
          return disbursements;
        },
      }),
      setDeals: assign({
        investments: (ctx, event) => {
          const investments = event.data.data;
          return investments;
        },
      }),
    },
    services: {
      generateExportReport(_ctx, _event) {
        return global.axios.get('/v2/fund-manager/reports/export', {
          responseType: 'blob'
        });
      },
      fetchDisbursements(ctx, event) {
        return global.axios.get('/v2/fund-manager/reports/disbursements', {
          params: event.data,
        });
      },
      fetchReceived(ctx, event) {
        // if (ctx.investments.length > 0) {
        //   return Promise.resolve({
        //     data: ctx.investments,
        //   });
        // }
        return global.axios.get('/v2/fund-manager/reports/reveiced', {
          params: event.data,
        });
      },
    },
  }
);
