import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';

// import AccessibilityIcon from '@material-ui/icons/Accessibility';

const VenueDeletedTable = ({ loading, failed, users, onRevive }) => {
  const theme = useTheme();
  // console.log(users)
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Email</TableCell>
          <TableCell align="right">Address</TableCell>
          <TableCell align="right">Date Deleted</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(() => {
          if (loading) {
            return (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Fetching archived users. . .
                </TableCell>
              </TableRow>
            );
          }
          if (failed) {
            return (
              <TableRow>
                <TableCell colSpan={5} align="center" style={{ color: theme.palette.error.main }}>
                  Failed to fetch archived users!
                </TableCell>
              </TableRow>
            );
          }
          if (users.length == 0) {
            return (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  There are no archived users.
                </TableCell>
              </TableRow>
            );
          }
          return users.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.user.email}</TableCell>
              <TableCell align="right">{row.address}</TableCell>
              <TableCell align="right">{moment(row.deleted_at).format('DD-MM-YYYY')}</TableCell>
              <TableCell align="right">
                <Tooltip title="unarchive user">
                  <Button
                    size="small"
                    color="success"
                    onClick={() => onRevive(row)}
                    style={{
                      backgroundColor: theme.palette.success.main,
                      color: theme.palette.common.white,
                    }}
                  >
                    Restore
                  </Button>
                  {/*<IconButton onClick={() => onRevive(row)}>
                    <AccessibilityIcon style={{ color: theme.palette.success.main }} />
                  </IconButton>*/}
                </Tooltip>
              </TableCell>
            </TableRow>
          ));
        })()}
      </TableBody>
    </Table>
  );
};
VenueDeletedTable.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  users: PropTypes.array.isRequired,
  onRevive: PropTypes.func,
};
VenueDeletedTable.defaultProps = {
  loading: false,
  failed: false,
  onRevive: () => {},
};

export default VenueDeletedTable;
