import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoutes from './PrivateRoutes';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Contacts from '../pages/Contacts';
import ContactEdit from '../pages/ContactEdit';
import ContactOverview from '../pages/ContactOverview';
import Deals from '../pages/Deals';
import DealOverview from '../pages/DealOverview';
import DealBoard from '../pages/DealBoardV2';
import Reports from '../pages/Reports';
import FundManagerReport from '../pages/FundManagerReport';
import Settings from '../pages/Settings';
import Venues from '../pages/Venues';
import VenueEdit from '../pages/VenueEdit';
import VenueDeleted from '../pages/VenueDeleted';
import ReportPDF from '../pages/ReportPDF';
import UnassignedDeals from '../pages/UnassignedDeals';
import PageNotFound from '../pages/PageNotFound';

import TestInquiryToStg from '../pages/TestInquiryToStg';
import TestInquiryToDev from '../pages/TestInquiryToDev';

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route exact path="/" render={() => (!props.isLoggedIn ? <Login /> : <Dashboard />)} />
      <Route exact path="/404" component={PageNotFound} />
      <Route path="/test-inquiry-to-stg" component={TestInquiryToStg} />
      <Route path="/test-inquiry-to-dev" component={TestInquiryToDev} />

      <PrivateRoutes>
        <Switch>
          <Route exact path="/contacts" component={Contacts} />
          <Route path="/contacts/edit/:id" component={ContactEdit} />
          <Route path="/contacts/view/:id" component={ContactOverview} />

          <Route
            exact
            path="/deals"
            render={() => (props.user.role === 'god_admin' ? <Deals /> : <DealBoard />)}
          />
          <Route path="/deals/view/:id" component={DealOverview} />

          {(() => {
            if (props.user.role === 'god_admin') {
              return <Route exact path="/reports" component={Reports} />;
            }

            return <Route exact path="/reports" component={FundManagerReport} />;
          })()}
          <Route path="/reports/pdf" render={(props) => <ReportPDF {...props} />} />

          <Route path="/settings" component={Settings} />

          {props.user.role === 'god_admin' && (
            <Switch>
              <Route exact path="/users" component={Venues} />
              <Route path="/users/archived" component={VenueDeleted} />
              <Route path="/users/edit/:id" component={VenueEdit} />
              <Route exact path="/deals/unassigned" component={UnassignedDeals} />
            </Switch>
          )}
          <Route component={() => '404 NOT FOUND'} />
        </Switch>
      </PrivateRoutes>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  user: state.Auth.user,
});

export default connect(mapStateToProps)(withRouter(Routes));
