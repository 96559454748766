import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { remove, loadAll } from '../actions/contact';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button, Chip } from '@material-ui/core';
import { AddCircle, PeopleOutlineRounded } from '@material-ui/icons';
import PageHeader from '../components/PageHeader.js';
import { SuccessSnackbar } from '../components/Snackbars.js';
import ConfirmModal from '../components/ConfirmModal';
import TableComponent from '../components/table/TableComponent';
// Components
import ContactsCreateDialog from './Contacts.create.dialog.jsx';
import Alerts from '../components/Alerts';
// Context
import Localization from '../localization';
import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';
import enums from '../enums';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    color: '#ffffff',
    border: '1px solid #82c34c',
    marginTop: '-4px',
    backgroundColor: 'rgb(109,180,78)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgb(109,180,78, 0.7)',
      boxShadow: 'none',
    },
  },
}));

const headCells = [
  { id: 'venue_name', label: 'User', restrict: 'god_admin' },
  {
    id: 'name',
    label: 'Name',
    render(row, value) {
      return value;
    },
  },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  {
    id: 'contactType',
    label: 'Contact Type',
    render(row, value) {
      let type = value;
      const map = {
        investor: 'primary',
        Borrower: 'secondary',
      };
      if (value == enums.Contact.TYPE.CLIENT.toLowerCase()) {
        type = enums.Contact.TYPE_LABEL[enums.Contact.TYPE.BORROWER];
      }
      return (
        <span>
          <Chip label={type || 'N/A'} size="small" color={value ? map[value] : ''} />
        </span>
      );
    },
  },
  { id: 'created_at', label: 'Created at', align: 'center', type: 'date' },
  { id: '', label: 'Action', align: 'right', sortable: false, searchable: false },
];

const Contacts = (props) => {
  const Services = useContext(GlobalState);
  const ContactState = useActor(Services.ContactState);
  const { user, contacts, loadAll, remove, history } = props;
  const classes = useStyles();
  const contactServerError = ((ContactState[0].context.response || {}).data || {}).errors || {};
  useEffect(() => {
    loadAll();
  }, []);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const toggleSnackbar = () => {
    setTimeout(function () {
      setSnackbarOpen(!snackbarOpen);
    }, 1000);
  };

  const [deleteId, setDeleteId] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const confirmDelete = (contact) => {
    setDeleteId(contact.id);
    toggleConfirmModal();
  };

  const handleDelete = async () => {
    let success = await remove({ id: deleteId });
    if (success) {
      toggleConfirmModal();
      toggleSnackbar();
    }
  };

  const handleView = (contact) => {
    history.push(`/contacts/view/${contact.id}`);
  };
  // useEffect(() => {
  // 	ContactState[1]('FETCH')
  // }, [])
  const handleRealodPage = () => {
    ContactState[1]('CLOSE');
    window.location.reload(true);
  };

  return (
    <Grid container spacing={2}>
      <ContactsCreateDialog
        errors={contactServerError}
        submitting={ContactState[0].matches('create.process')}
        open={['create.show', 'create.process', 'create.invalid', 'create.failure'].some(
          ContactState[0].matches
        )}
        reset={ContactState[0].matches('create.success')}
        onSave={(values) => ContactState[1]({ type: 'SAVE', data: values })}
        onCancel={() => ContactState[1]('CANCEL')}
      />
      <Alerts.Loading
        open={ContactState[0].matches('create.process')}
        message={Localization.contact.create.process}
      />
      <Alerts.Success
        open={ContactState[0].matches('create.success')}
        message={Localization.contact.create.success}
        onClose={() => handleRealodPage()}
      />
      <Alerts.Failure
        open={ContactState[0].matches('create.failure')}
        message={Localization.contact.create.failure}
        onClose={() => ContactState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={ContactState[0].matches('create.invalid')}
        message={Localization.contact.create.invalid}
        onClose={() => ContactState[1]('CLOSE')}
      />
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PageHeader icon={<PeopleOutlineRounded />} headerTitle="Contacts" />
          </Grid>
          <Grid item xs={6} className="text-right">
            {props.user.role === 'venue' && (
              <Button
                size="small"
                className={`${classes.addBtn}`}
                variant="contained"
                onClick={() => ContactState[1]('CREATE')}
              >
                <AddCircle style={{ fontSize: 15, marginRight: 4 }} />
                Create New
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          {snackbarOpen && (
            <SuccessSnackbar
              open={snackbarOpen}
              toggleSnackbar={() => toggleSnackbar()}
              message="Contact deleted successfully."
            />
          )}
          {confirmModalOpen && (
            <ConfirmModal
              title=""
              color="action"
              open={confirmModalOpen}
              toggleModal={() => toggleConfirmModal()}
              handleYes={() => handleDelete()}
              DialogTitle="Are you sure?"
              ContentText="Are you sure you want to delete this contact? This process cannot be undone."
            />
          )}
          <TableComponent
            data={contacts}
            headCells={headCells}
            handleView={(contact) => handleView(contact)}
            handleDelete={user.role === 'venue' ? (contact) => confirmDelete(contact) : null}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  contacts: state.Contact.contacts,
});

const mapDispatchToProps = {
  loadAll: () => loadAll(),
  remove: (payload) => remove(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Contacts));
