/* global global */
import { SETTING } from '../constants/setting';
import { AUTH } from '../constants/auth';

export function setErrors(response) {
  return function (dispatch, getState) {
    let errors = {};
    if (response.status == 422) {
      errors = response.data.errors;
    }
    dispatch({ type: SETTING.SETTING_PROFILE_SAVE_ERROR, payload: errors });
  };
}

export function setProfile(payload) {
  return function (dispatch, getState) {
    dispatch({ type: SETTING.SETTING_PROFILE_LOAD, payload: payload.user });
    dispatch({ type: AUTH.LOGIN_UPDATE_PROFILE, payload: payload });
  };
}

export function loadProfile() {
  return function (dispatch, getState) {
    dispatch({ type: SETTING.SETTING_PROFILE_LOAD, payload: getState().Auth.user });
  };
}

export function saveProfile() {
  return async function (dispatch, getState) {
    let postData = getState().Setting.profile;
    delete postData.venue;
    delete postData.avatar;

    try {
      let response = await global.axios({
        method: 'post',
        url: '/save_profile',
        data: postData,
      });

      dispatch({ type: SETTING.SETTING_PROFILE_LOAD, payload: response.data.user });
      dispatch({ type: AUTH.LOGIN_UPDATE_PROFILE, payload: response.data });
      dispatch({ type: SETTING.SETTING_PROFILE_SAVE_SUCCESS });

      return true;
    } catch (error) {
      if (error.response.status === 422) {
        dispatch({ type: SETTING.SETTING_PROFILE_SAVE_ERROR, payload: error.response.data.errors });
      } else {
        console.error(error);
      }
      return false;
    }
  };
}

export function saveAvatar(payload) {
  return function (dispatch, getState) {
    let postData = new FormData();
    postData.append('id', getState().Auth.user.id);
    postData.append('avatar', payload);

    global
      .axios({
        method: 'post',
        url: '/save_avatar',
        data: postData,
      })
      .then(({ data }) => {
        dispatch({ type: SETTING.SETTING_SAVE_AVATAR_SUCCESS, payload: data });
        dispatch({ type: AUTH.LOGIN_UPDATE_AVATAR, payload: data });
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };
}

export function typingProfile(payload) {
  return { type: SETTING.SETTING_PROFILE_TYPING, payload: payload };
}
