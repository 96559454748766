import React from 'react';
import {withRouter} from 'react-router-dom';
import {Grid, Typography, Button} from '@material-ui/core';

const PageNotFound = ({history}) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{height:'100vh'}}>
			<div className="text-center">
				<Typography variant="h1" component="div" style={{fontSize:'144px',fontWeight:'600',color:'rgb(93,94,95)'}}>
					404
				</Typography>
				<Typography variant="h2" component="div" style={{fontWeight:600,color:'rgb(93,94,95)'}}>
					Oops! Page Not Found
				</Typography>
				<Typography variant="h6" component="div" style={{color:'rgb(93,94,95)', marginTop:'20px',marginBottom:'20px'}}>
					We&apos;re sorry, the page you requested is not found. Please go back to dashboard page.
				</Typography>
				<Button
					variant="contained"
					size="large"
					color="primary"
					onClick={() => history.push('/')}
				>
          Dashboard
        </Button>
			</div>
		</Grid>
  );
}
export default withRouter(PageNotFound);
