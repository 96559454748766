import PropTypes from 'prop-types';
import React, { useEffect, useContext, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { load, clearFlag, updateStage, save, update } from '../actions/deal';
import { loadAll as loadContacts } from '../actions/contact';
import { loadAll as loadComments, clearComments } from '../actions/deal_comment';
import { loadAll as loadStages } from '../actions/stage';
import { DateToString } from '../helpers/DateFunctions.js';
import Enums from '../enums';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Chip,
  Paper,
  Box,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import TransformIcon from '@material-ui/icons/Transform';
// import { ArrowDropDown as ArrowDropDownIcon, Edit as EditIcon } from '@material-ui/icons';
import { Edit as EditIcon } from '@material-ui/icons';

import { SuccessSnackbar2 } from '../components/Snackbars.js';
import DealEditDialog from './DealEditDialog';
import DealComments from './DealComments';
import ConfirmModal from '../components/ConfirmModal';
// Icons
import DateRangeIcon from '@material-ui/icons/DateRange';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Components
import Alerts from '../components/Alerts';
import DealTransferDialog from '../components/Deal.Transfer.Dialog';
import DealBoardUpdateDialog from './DealBoard.update.dialog';
import ContactsCreateDialog from './Contacts.create.dialog';
// Context
import Localization from '../localization';
import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';

const useStyles = makeStyles((theme) => ({
  Popper: {
    zIndex: 9999,
  },
  GridLabel: {
    fontWeight: 600,
    color: '#595959',
  },
  ButtonLabel: {
    minWidth: '140px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DealOverview = (props) => {
  const Services = useContext(GlobalState);
  const DealState = useActor(Services.DealState);
  const VenueStageState = useActor(Services.VenueStageState);
  const stages = VenueStageState[0].context.list;
  const ContactState = useActor(Services.ContactState);
  const classes = useStyles();
  const [loadComplete, setLoadComplete] = React.useState(false);
  const contacts = ContactState[0].context.list;
  const tranasfered = DealState[0].context.transfered;
  const dealError = DealState[0].context.errors;
  const lenders = DealState[0].context.list.filter((item) => item.type == Enums.Deal.TYPE.INVEST);

  let editFormErrors = {};
  if (DealState[0].matches('edit')) {
    editFormErrors = ((DealState[0].context.response || {}).data || {}).errors || {};
  }
  useEffect(() => {
    props.clearComments();
    async function fetchData() {
      let loaded = await props.load({ id: props.match.params.id });
      setLoadComplete(loaded);
      if (loaded) {
        await props.loadComments({ id: props.match.params.id });
        await props.loadContacts();
      } else {
        props.history.push('/404');
      }
    }
    fetchData();
  }, []);

  const [EditorOpen, setEditorOpen] = React.useState(false);
  const openEditorModal = (deal_id) => {
    if (deal_id) {
      props.load({ id: deal_id });
    }
    setEditorOpen(true);
  };
  const closeEditorModal = () => {
    setEditorOpen(false);
  };

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const updateStage = () => {
    props.updateStage({ stage_id: stageId }, openSnackbar, setSnackbarMessage);
    setStageNameById(stageId);
    setStageId(null);
    toggleConfirmModal();
  };

  const setStageNameById = (stageId) => {
    // let stage = props.stages.filter((item) => item.stage_id === stageId);
    // if (stage.length > 0) {
    //   setStageName(stage[0].name ? stage[0].name : stage[0].stage.name);
    // }
  };

  const [stageId, setStageId] = React.useState(null);
  // const [stageName, setStageName] = React.useState(null);

  const handleSave = async () => {
    let success = await props.save();
    if (success) {
      setSnackbarMessage('Deal successfully saved.');
      openSnackbar();
      props.clearFlag();
      closeEditorModal();
    }
  };
  const handleTransferDeal = () => {
    DealState[1]({ type: 'TRANSFER', data: {} });
  };
  const handleTransferSubmit = (values) => {
    DealState[1]({
      type: 'CONFIRM',
      data: {
        dealId: props.deal.id,
        amount: values.amountTransfer,
        ebxBankAccount: values.bankAccountOpt,
        inputRef: values.referenceNumber,
        transactionDate: values.transactionDate,
        description: values.description,
      },
    });
  };
  useEffect(() => {
    setStageNameById(props.deal.stage.stage_id);
    if (props.stages.length === 0 && props.deal.venue_id !== '') {
      props.loadStages({ venue_id: props.deal.venue_id });
    }
  }, [props.deal]);
  useEffect(() => {
    if (props.stages.length > 0) {
      setStageNameById(props.deal.stage.stage_id);
    }
  }, [props.stages]);
  useEffect(() => {
    DealState[1]({ type: 'FETCH', data: { stages } });
    ContactState[1]('FETCH');
  }, []);
  useEffect(() => {
    if (ContactState[0].matches('fetch.success')) {
      ContactState[1]('CLOSE');
    }
  }, [ContactState[0]]);
  useEffect(() => {
    if (DealState[0].matches('transfer.success')) {
      props.update({ ...tranasfered });
    }
  }, [DealState[0]]);
  if (!loadComplete) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <div>
        <DealTransferDialog
          isSmsf={props.deal.contact.has_smsf}
          maxAmount={props.deal.balance}
          open={DealState[0].matches('transfer.show')}
          contact={props.deal.contact}
          onClose={() => DealState[1]('CANCEL')}
          onSubmit={handleTransferSubmit}
          loading={DealState[0].matches('transfer.process')}
          errors={dealError}
        />
        <Alerts.Loading
          open={DealState[0].matches('transfer.process')}
          message={Localization.deal.edit.transfer.process}
        />
        <Alerts.Success
          open={DealState[0].matches('transfer.success')}
          message={Localization.deal.edit.transfer.success}
          onClose={() => DealState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={DealState[0].matches('transfer.failure')}
          message={Localization.deal.edit.transfer.failure}
          onClose={() => DealState[1]('CLOSE')}
        />
        <DealBoardUpdateDialog
          errors={editFormErrors}
          data={DealState[0].context.selectedData || {}}
          contacts={contacts}
          lenders={lenders}
          open={DealState[0].matches('edit.show')}
          reset={DealState[0].matches('edit.success')}
          onSave={(values) => DealState[1]({ type: 'SAVE', data: values })}
          onCancel={() => DealState[1]('CANCEL')}
          onCreateContact={() => ContactState[1]('CREATE')}
        />
        <Alerts.Loading
          open={DealState[0].matches('edit.process')}
          message={Localization.deal.edit.process}
        />
        <Alerts.Success
          open={DealState[0].matches('edit.success')}
          message={Localization.deal.edit.success}
          onClose={() => DealState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={DealState[0].matches('edit.invalid')}
          message={Localization.deal.edit.invalid}
          onClose={() => DealState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={DealState[0].matches('edit.failure')}
          message={Localization.deal.edit.failure}
          onClose={() => DealState[1]('CLOSE')}
        />

        <ContactsCreateDialog
          errors={((ContactState[0].context.response || {}).data || {}).errors || []}
          open={ContactState[0].matches('create.show')}
          reset={ContactState[0].matches('create.success')}
          onSave={(values) => ContactState[1]({ type: 'SAVE', data: values })}
          onCancel={() => ContactState[1]('CANCEL')}
        />
        <Alerts.Loading
          open={ContactState[0].matches('create.process')}
          message={Localization.deal.contact.create.process}
        />
        <Alerts.Success
          open={ContactState[0].matches('create.success')}
          message={Localization.deal.contact.create.success}
          onClose={() => ContactState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={ContactState[0].matches('create.failure')}
          message={Localization.deal.contact.create.failure}
          onClose={() => ContactState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={ContactState[0].matches('create.invalid')}
          message={Localization.deal.contact.create.invalid}
          onClose={() => ContactState[1]('CLOSE')}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" className="mb-2">
              <Box p={1} flexGrow={1}>
                <h5 className="mt-1 font-weight-bold">Deal Overview</h5>
              </Box>
              <Box p={1}>
                {(() => {
                  if (props.user.role === 'god_admin') {
                    return null;
                  }

                  return (
                    <>
                      {props.deal.type === Enums.Deal.TYPE.INVEST && (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          disabled={
                            DealState[0].matches('fetch.process') ||
                            ContactState[0].matches('fetch.process')
                          }
                          onClick={handleTransferDeal}
                        >
                          <TransformIcon className="btn-icon mr-1" />
                          Transfer to Bank
                        </Button>
                      )}
                      <Button
                        disabled={
                          DealState[0].matches('fetch.process') ||
                          ContactState[0].matches('fetch.process')
                        }
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => DealState[1]({ type: 'EDIT', data: props.deal.id })}
                      >
                        <EditIcon className="btn-icon mr-1" />
                        Edit
                      </Button>
                    </>
                  );
                })()}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {confirmModalOpen && (
          <ConfirmModal
            title="update"
            color="action"
            open={confirmModalOpen}
            toggleModal={() => toggleConfirmModal()}
            handleYes={() => updateStage()}
            DialogTitle="Confirm Update"
            ContentText="Are you sure you want to update deal stage?"
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {snackbarOpen && (
              <SuccessSnackbar2
                open={snackbarOpen}
                openSnackbar={() => openSnackbar()}
                closeSnackbar={() => closeSnackbar()}
                message={snackbarMessage}
              />
            )}
            {EditorOpen && (
              <DealEditDialog
                open={EditorOpen}
                openEditorModal={() => openEditorModal()}
                closeEditorModal={() => closeEditorModal()}
                handleSave={() => handleSave()}
                {...props}
              />
            )}
            <Paper className="p-3">
              <Grid container spacing={0}>
                <Grid item sm={9} xs={12}>
                  <h5
                    className="font-weight-bold"
                    style={{
                      color: '#008799',
                    }}
                  >
                    {props.deal.title}
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography>
                        <span className="font-weight-bold" style={{ color: '#4d4d4d' }}>
                          <span className="mr-1" style={{ fontSize: '12px' }}>
                            $
                          </span>
                          <span style={{ fontSize: '16px' }}>
                            {props.deal.value
                              ? Number(props.deal.value).toLocaleString('en', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : '0.00'}
                          </span>
                        </span>
                        &nbsp;
                        <span className="font-weight-bold" style={{ color: '#4d4d4d' }}>
                          <i className="far fa-address-book mr-1" style={{ fontSize: '12px' }}></i>
                          <span style={{ fontSize: '16px' }}>
                            {props.deal.contact
                              ? `${props.deal.contact.firstname} ${
                                  props.deal.contact.lastname || ''
                                }`
                              : ''}
                          </span>
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="mt-2">
          <Grid item lg={5} md={6} sm={12}>
            <Grid container spacing={2}>
              <DealDetails {...props} />
              <ContactDetails {...props} />
              {props.deal.type === 'Lend' && (
                <BorrowersDetail history={props.history} borrowers={props.deal.borrowers || []} />
              )}
              {(() => {
                if (props.deal.type === Enums.Deal.TYPE.INVEST) {
                  return props.deal.transfers.map((transfer, index) => (
                    <Transfer
                      transfer={transfer}
                      bankAccount={props.deal.bankacc_option}
                      key={'transfer-' + index}
                    />
                  ));
                }
              })()}

              {props.deal.type === Enums.Deal.TYPE.INVEST && (
                <BorrowersDetail history={props.history} borrowers={props.deal.borrowers || []} />
              )}
              {(() => {
                if (props.deal.type === Enums.Deal.TYPE.LOAN && props.deal.lender !== undefined) {
                  return props.deal.lender.map((lended, index) => (
                    <Grid key={index} item xs={12}>
                      <Paper className="p-3">
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" component="span" className="font-weight-bold">
                              Lender Details
                            </Typography>
                          </Grid>
                          <Grid item sm={3} xs={12} className={classes.GridLabel}>
                            Contact
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            {' '}
                            {`${lended.contact.firstname}${
                              lended.contact.lastname ? ' ' + lended.contact.lastname : ''
                            }`}{' '}
                            ({lended.contact.email})
                          </Grid>
                          <Grid item sm={3} xs={12} className={classes.GridLabel}>
                            Amount
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            {' '}
                            {Number(lended.balance).toLocaleString('en', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ));
                }
              })()}
            </Grid>
          </Grid>
          <Grid item lg={7} md={6} sm={12}>
            <Paper className="p-3">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h6 className="font-weight-bold">Deal Notes</h6>
                </Grid>
                <Grid item xs={12}>
                  <DealComments />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};

const DealDetails = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className="p-3">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span" className="font-weight-bold">
              {(() => {
                if (props.deal.type === Enums.Deal.TYPE.INVEST) {
                  return 'Investment Details';
                }
                return 'Deal Details';
              })()}
            </Typography>
          </Grid>
          {props.user.role === 'god_admin' && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Venue
                </Grid>
                <Grid item sm={7} xs={12}>
                  {' '}
                  {'venue' in props.deal ? props.deal.venue.name : ''}{' '}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item sm={5} xs={12} className={classes.GridLabel}>
            Date
          </Grid>
          <Grid item sm={7} xs={12}>
            {props.deal.date ? DateToString(props.deal.date) : null}
          </Grid>

          <Grid item sm={5} xs={12} className={classes.GridLabel}>
            Type
          </Grid>
          <Grid item sm={7} xs={12}>
            {props.deal.type === 'Other' && props.deal.type_other
              ? props.deal.type_other
              : props.deal.type}
          </Grid>
          {(() => {
            if (props.deal.type == Enums.Deal.TYPE.INVEST) {
              return (
                <>
                  <Grid item sm={5} xs={12} className={classes.GridLabel}>
                    Balance
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    {Number(props.deal.balance).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Grid>
                  <Grid item sm={5} xs={12} className={classes.GridLabel}>
                    Investment Amount
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    {Number(props.deal.transfered_amount).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Grid>
                  <Grid item sm={5} xs={12} className={classes.GridLabel}>
                    Loaned
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    {Number(props.deal.lended).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Grid>
                </>
              );
            }

            return (
              <>
                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Amount
                </Grid>
                <Grid item sm={7} xs={12}>
                  {Number(props.deal.value).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Grid>
              </>
            );
          })()}

          <Grid item sm={5} xs={12} className={classes.GridLabel}>
            Description
          </Grid>
          <Grid item sm={7} xs={12}>
            {props.deal.notes}
          </Grid>

          {/* {props.deal.type === Enums.Deal.TYPE.LOAN && <LoanAddtionalDetails {...props} />} */}
          {props.deal.type === Enums.Deal.TYPE.INVEST && <InvesmentAddtionalDetails {...props} />}
        </Grid>
      </Paper>
    </Grid>
  );
};

const ContactDetails = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className="p-3">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span" className="font-weight-bold">
              Contact Details
            </Typography>
          </Grid>

          {!props.deal.contact ? null : (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Name
                </Grid>
                <Grid item sm={7} xs={12}>
                  {`${props.deal.contact.firstname}${
                    props.deal.contact.lastname ? ' ' + props.deal.contact.lastname : ''
                  }`}
                </Grid>

                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Phone
                </Grid>
                <Grid item sm={7} xs={12}>
                  {props.deal.contact.phone}
                </Grid>

                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Email
                </Grid>
                <Grid item sm={7} xs={12}>
                  {props.deal.contact.email}
                </Grid>

                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Address
                </Grid>
                <Grid item sm={7} xs={12}>
                  {props.deal.contact.address}
                </Grid>
                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Person 1 Name
                </Grid>
                <Grid item sm={7} xs={12}>
                  {props.deal.contact.personName1 || ''}
                </Grid>
                <Grid item sm={5} xs={12} className={classes.GridLabel}>
                  Person 2 Name
                </Grid>
                <Grid item sm={7} xs={12}>
                  {props.deal.contact.personName2 || ''}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const Transfer = ({ bankAccount, transfer }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className="p-3">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span" className="font-weight-bold">
              Deal Transfer Detail
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={5} xs={12} className={classes.GridLabel}>
                Transfer Reference #
              </Grid>
              <Grid item sm={7} xs={12}>
                {transfer.reference}
              </Grid>

              <Grid item sm={5} xs={12} className={classes.GridLabel}>
                From SPV Bank Account
              </Grid>
              <Grid item sm={7} xs={12}>
                {bankAccount}
              </Grid>

              <Grid item sm={5} xs={12} className={classes.GridLabel}>
                EBX Bank Account
              </Grid>
              <Grid item sm={7} xs={12}>
                {transfer.bank_account}
              </Grid>

              <Grid item sm={5} xs={12} className={classes.GridLabel}>
                Amount Transferred
              </Grid>
              <Grid item sm={7} xs={12}>
                {Number(transfer.amount).toLocaleString('en', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Grid>

              <Grid item sm={5} xs={12} className={classes.GridLabel}>
                Transfer Date
              </Grid>
              <Grid item sm={7} xs={12}>
                {DateToString(transfer.transaction_date)}
              </Grid>

              <Grid item sm={5} xs={12} className={classes.GridLabel}>
                Description (note)
              </Grid>
              <Grid item sm={7} xs={12}>
                {transfer.description || '(none)'}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
Transfer.propTypes = {
  bankAccount: PropTypes.string,
  transfer: PropTypes.shape({
    bank_account: PropTypes.string,
    transaction_date: PropTypes.string,
    reference: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};
Transfer.defaultProps = {};

const InvesmentAddtionalDetails = (props) => {
  const classes = useStyles();

  return (
    <>
      <Grid item sm={5} xs={12} className={classes.GridLabel}>
        Investment ref.
      </Grid>
      <Grid item sm={7} xs={12}>
        {props.deal?.input_ref}
      </Grid>

      <Grid item sm={5} xs={12} className={classes.GridLabel}>
        Investment Class
      </Grid>
      <Grid item sm={7} xs={12}>
        {props.deal?.investment_option}
      </Grid>
    </>
  );
};

// const LoanAddtionalDetails = (props) => {
//   const classes = useStyles();
//   const lendFrom = props.deal.lender[0];
//   return (
//     <>
//       <Grid item sm={5} xs={12} className={classes.GridLabel}>
//         Input Reference
//       </Grid>
//       <Grid item sm={7} xs={12}>
//         {props.deal.input_ref}
//       </Grid>
//       <Grid item sm={5} xs={12} className={classes.GridLabel}>
//         Loan From
//       </Grid>
//       <Grid item sm={7} xs={12}>
//         {lendFrom.contact.firstname}
//         {lendFrom.contact.lastname ? ' ' + lendFrom.contact.lastname : ''} ({lendFrom.contact.email}
//         )
//       </Grid>
//       <Grid item sm={5} xs={12} className={classes.GridLabel}>
//         Bank Account
//       </Grid>
//       <Grid item sm={7} xs={12}>
//         {props.deal.ebx_bankacc}
//       </Grid>
//     </>
//   );
// };

function BorrowersDetail({ history, borrowers }) {
  if (borrowers.length === 0) {
    return '';
  }

  const handleNavigate = (borrower) => {
    history.replace('/deals/view/' + borrower.id);
    window.location.reload();
  };
  return borrowers.map((borrower, index) => (
    <Grid key={'borrower-detail-' + index} item xs={12}>
      <Paper className="p-3">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span" className="font-weight-bold">
              Active Borrows
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box style={{ display: 'flex' }}>
              <Avatar>
                <DateRangeIcon />
              </Avatar>
              <Box style={{ paddingLeft: 15 }}>
                <Typography className="font-weight-bold" style={{ color: '#008799' }}>
                  {borrower.contact.firstname}
                  {borrower.contact.lastname ? ' ' + borrower.contact.lastname : ''}
                </Typography>
                <Typography>{DateToString(borrower.created_at)}</Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex' }}>
              <Box style={{ flexGrow: 1, paddingRight: 15 }}>
                <Typography align="right">
                  <Chip
                    size="small"
                    label={borrower.stage.stage.name}
                    style={{ backgroundColor: '#008799', color: '#FFF' }}
                  />
                </Typography>
                <Typography align="right" variant="h6">
                  $
                  {Number(borrower.value).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography align="right">Amount</Typography>
              </Box>
              <Box>
                <IconButton onClick={() => handleNavigate(borrower)}>
                  <VisibilityIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ));
}
BorrowersDetail.propTypes = {
  borrowers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  success: state.Deal.success,
  deal: state.Deal.deal,
  contacts: state.Contact.contacts,
  user: state.Auth.user,
  stages: state.Stage.stages,
});

const mapDispatchToProps = {
  load: (payload) => load(payload),
  loadComments: (payload) => loadComments(payload),
  loadContacts: () => loadContacts(),
  loadStages: (payload) => loadStages(payload),
  update: (payload) => update(payload),
  clearFlag: () => clearFlag(),
  updateStage: (payload, openSnackbar, setSnackbarMessage) =>
    updateStage(payload, openSnackbar, setSnackbarMessage),
  clearComments: () => clearComments(),
  save: () => save(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DealOverview));
