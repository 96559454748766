/* global global, process, Promise */

import { createMachine, assign } from 'xstate';

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'Dashboard',
    initial: 'idle',
    on: {
      CLEARFILTER: {
        actions: ['clearDateFilter'],
      },
    },
    context: {
      user: null,
      investments: {
        nonSmsf: {
          deals: 0,
          total: 0,
        },
        smsf: {
          deals: 0,
          total: 0,
        },
        total: {
          deals: 0,
          total: 0,
        },
      },
      transfers: {
        nonSmsf: {
          deals: 0,
          total: 0,
        },
        smsf: {
          deals: 0,
          total: 0,
        },
        total: {
          deals: 0,
          total: 0,
        },
      },
      balance: {
        smsf: 0,
        nonSmsf: 0,
        total: 0,
      },
      clients: {
        nonSmsf: {
          deals: 0,
          total: 0,
        },
        smsf: {
          deals: 0,
          total: 0,
        },
        total: {
          deals: 0,
          total: 0,
        },
      },
      cardData: {
        investor: {
          total_smsf_spv2: 0,
          total_amount_smsf_spv2: 0.0,
          total_non_smsf_spv1: 0,
          total_amount_non_smsf_spv1: 0.0,
          total_non_smsf_spv2: 0,
          total_amount_non_smsf_spv2: 0.0,
          total: 0,
          total_amount: 0.0,
        },

        client: {
          total_smsf_spv2: 0,
          total_amount_smsf_spv2: 0.0,
          total_non_smsf_spv1: 0,
          total_amount_non_smsf_spv1: 0.0,
          total_non_smsf_spv2: 0,
          total_amount_non_smsf_spv2: 0.0,
          total: 0,
          total_amount: 0.0,
        },
      },
      statsData: null,
      dateFilter: {
        date_from: null,
        date_to: null,
      },
    },
    states: {
      idle: {
        on: {
          FETCH: {
            target: 'fetchReport.process',
            actions: ['setDateFilter', 'setUser'],
          },
        },
      },
      fetchReport: {
        states: {
          process: {
            invoke: {
              src: 'fetchingReport',
              onDone: {
                target: 'success',
                actions: ['setCardData'],
              },
              onError: 'failure',
            },
          },
          success: {
            always: {
              target: ['#Dashboard.fetchStats.process'],
            },
            on: {
              CLOSE: {
                target: ['#Dashboard.idle'],
              },
            },
          },
          failure: {
            always: {
              target: '#Dashboard.idle',
            },
            on: {
              CLOSE: '#Dashboard.idle',
            },
          },
        },
      },
      fetchStats: {
        states: {
          process: {
            invoke: {
              src: 'fetchingStats',
              onDone: {
                target: 'success',
                actions: ['setStatsData'],
              },
              onError: 'failure',
            },
          },
          success: {
            always: {
              target: ['#Dashboard.idle'],
            },
            on: {
              CLOSE: {
                target: ['#Dashboard.idle'],
              },
            },
          },
          failure: {
            always: {
              target: '#Dashboard.idle',
            },
            on: {
              CLOSE: '#Dashboard.idle',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      setCardData: assign({
        // cardData: (ctx, event) => event.data.data,
        investments(ctx, event) {
          return event.data.data.investments;
        },
        transfers(ctx, event) {
          return event.data.data.transfers;
        },
        clients(ctx, event) {
          return event.data.data.clients;
        },
        balance(ctx, event) {
          return event.data.data.balance;
        },
      }),
      setStatsData: assign({
        statsData: (ctx, event) => event.data.data,
      }),
      setDateFilter: assign({
        dateFilter: (ctx, event) => event.data.dateFilter,
      }),
      setUser: assign({
        user: (ctx, event) => event.data.user,
      }),
    },
    services: {
      fetchingReport(ctx, event) {
        const params = {
          ...ctx.dateFilter,
          venue_id: ctx.user.role === 'venue' ? ctx.user.venue.id : 'all',
        };
        return global.axios.post('/deal/dashboard_report', params);
      },
      fetchingStats(ctx, event) {
        if (process.env.NODE_ENV === 'test') {
          return new Promise((resolve) => {
            resolve(true);
          });
        }
        let data = {
          date_from: ctx.dateFilter.date_from,
          date_to: ctx.dateFilter.date_to,
          venue_id: ctx.user.role === 'venue' ? ctx.user.venue.id : 'all',
        };
        return global.axios.post('/stats', data);
      },
    },
  }
);
