import React, {} from 'react';
import PropTypes from 'prop-types';
// Material
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Overlay ({
  open,
  message
}) {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <CircularProgress color="inherit" />
        <Typography align="center">{message}</Typography>
      </div>
    </Backdrop>
  )
}

Overlay.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Overlay.defaultValues = {
  message: ''
}

export default Overlay;