import { DEAL } from '../constants/deal';
import { DateToString } from '../helpers/DateFunctions.js';

const initialState = {
  view: 'list',
  errors: {},
  success: false,
  isProcessing: false,
  unassigned_deals: [],
  deals: [],
  deal: {
    id: '',
    venue_id: '',
    contact_id: '',
    date: '',
    title: '',
    value: '',
    notes: '',
    type: '',
    type_other: '',
    archived: 0,
    stage: [],
  },
  board: {
    lanes: [],
  },
  stats: {
    date_range: [],
    latest_deals: [],
    latest_contacts: [],
    deal_cnt_by_type: [],
    deal_cnt_by_date: [],
    deal_cnt_by_stage: [],
    deals_value: 0,
    deals_cnt: 0,
    deals_won_cnt: 0,
    deals_lost_cnt: 0,
  },
};

const Deal = (state = initialState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  let user = Object.prototype.hasOwnProperty.call(localStorage, 'user')
    ? JSON.parse(localStorage.user)
    : null;

  switch (action.type) {
    case DEAL.DEAL_CHANGE_VIEW: {
      newState.view = action.payload;
      return newState;
    }

    case DEAL.DEAL_CLEAR_FLAG: {
      newState.error = {};
      newState.success = false;
      newState.isProcessing = false;
      return newState;
    }

    case DEAL.DEAL_CLEAR_FORM: {
      newState.errors = {};
      newState.success = false;
      newState.isProcessing = false;

      newState.deal = {
        id: '',
        venue_id: '',
        contact_id: '',
        date: '',
        title: '',
        value: '',
        notes: '',
        type: '',
        type_other: '',
        archived: 0,
        stage: [],
      };
      return newState;
    }

    case DEAL.DEAL_DELETE: {
      newState.deals = action.payload;
      return newState;
    }

    case DEAL.DEAL_LOAD: {
      newState.deal = action.payload;
      return newState;
    }

    case DEAL.DEAL_LOAD_ALL: {
      let deals = action.payload.deals;

      if (user.role === 'venue') {
        let lanes = initBoard(action.payload);
        newState.board.lanes = lanes;
      }

      newState.deals = deals;
      return newState;
    }

    case DEAL.DEAL_LOAD_UNASSIGNED: {
      newState.unassigned_deals = action.payload;
      return newState;
    }

    case DEAL.DEAL_SAVE_REQUEST: {
      newState.isProcessing = true;
      newState.errors = {};
      newState.success = false;
      return newState;
    }

    case DEAL.DEAL_SAVE_SUCCESS: {
      newState.errors = {};
      newState.isProcessing = false;
      newState.success = true;
      newState.deal.id = action.payload.id;
      newState.deal.contact = action.payload.contact;
      return newState;
    }

    case DEAL.DEAL_SAVE_ERROR: {
      newState.isProcessing = false;
      newState.success = false;
      newState.errors = action.payload;
      return newState;
    }

    case DEAL.DEAL_TYPING: {
      newState.errors = {};
      let key = Object.keys(action.payload)[0];
      newState.deal[key] = action.payload[key];
      return newState;
    }

    case DEAL.DEAL_UPDATE_STAGE: {
      newState.deal = action.payload.deals.filter((item) => item.id === newState.deal.id)[0];
      return newState;
    }

    case DEAL.DEAL_BOARD_UPDATE_STAGE: {
      let data = action.payload;

      let boardData = newState.board.lanes;
      let fromLane = boardData.filter((item) => item.id === data.from_stage_id)[0];
      let toLane = boardData.filter((item) => item.id === data.stage_id)[0];

      let fromCards = fromLane.cards;
      let toCards = toLane.cards;

      if (data.from_stage_id === data.stage_id) {
        let tempCards = JSON.parse(JSON.stringify(toCards));

        tempCards[data.from_position] = {};
        tempCards[data.position] = {};

        tempCards[data.from_position] = toCards[data.position];
        tempCards[data.position] = toCards[data.from_position];

        newState.board.lanes[toLane.position].cards = tempCards;
      } else {
        let tempToCards = JSON.parse(JSON.stringify(toCards));
        let newCard = [fromCards[data.from_position]];
        tempToCards.splice(data.position, 0, ...newCard);
        newState.board.lanes[toLane.position].cards = tempToCards;

        let tempFromCards = JSON.parse(JSON.stringify(fromCards));
        tempFromCards = tempFromCards.filter(
          (item) => Boolean(item) && item.position !== data.from_position
        );
        newState.board.lanes[fromLane.position].cards = tempFromCards;
      }
      newState.board.lanes = newState.board.lanes.map((item) => {
        if (item.cards.length > 0) {
          item.cards = item.cards.filter((c) => Boolean(c));
        }
        return item;
      });
      return newState;
    }

    case DEAL.DEAL_BOARD_SWAP_COLUMN: {
      let data = action.payload;

      let lanes = JSON.parse(JSON.stringify(newState.board.lanes));

      let from = lanes[data.from];
      let to = lanes[data.to];

      from.position = data.to;
      to.position = data.titlefrom;

      lanes[data.from] = to;
      lanes[data.to] = from;

      newState.board.lanes = lanes;

      return newState;
    }

    case DEAL.DEAL_LOAD_STATISTICS: {
      newState.stats = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

function initBoard(data) {
  let stages = data.stages;
  let deals = data.deals;

  let lanes = [];

  for (let s in stages) {
    let lane_id = stages[s].stage_id;

    let lane = {
      id: lane_id,
      title: stages[s].name ? stages[s].name : stages[s].stage.name,
      position: stages[s].position,
      color: stages[s].color,
      cards: [],
    };

    for (let d in deals) {
      let card_lane_id = deals[d].stage.stage_id;

      if (lane_id === card_lane_id) {
        let card = {
          id: deals[d].id,
          stage_id: deals[d].stage.id,
          position: deals[d].stage.position,
          title: deals[d].title,
          date: deals[d].date ? DateToString(deals[d].date) : '',
          contact_name: deals[d].contact_name,
          type:
            deals[d].type === 'Other' && deals[d].type_other ? deals[d].type_other : deals[d].type,
          value: deals[d].value,
          balance: deals[d].balance,
          lended: deals[d].lended,
        };

        lane.cards[deals[d].stage.position] = card;
      }
    }

    lanes[stages[s].position] = lane;
  }

  return lanes;
}

export default Deal;
