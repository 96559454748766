import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { connect } from 'react-redux';

/*
	*** headCells keys
	id -
	label -
	sortkey - default sorted by id
	align - default: left
	type - default: text
	sortable - default: true
	searchable - default: true
	restrict - default:all > (venue|god_admin)
	type - default: text > (text|date|number)
*/

const TableHeader = (props) => {

  const { user, headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
	};

  return (
    <TableHead>
      <TableRow>
        {headCells.map( (headCell) => {
        	if(Object.prototype.hasOwnProperty.call(headCell, 'restrict') && headCell.restrict !== user.role) {
						return null;
					}

					return (
						<TableCell
							key={headCell.id}
							align={Object.prototype.hasOwnProperty.call(headCell, 'align') ? headCell.align : 'left'}
							sortDirection={orderBy === headCell.id ? order : false}
							style={{width: `${headCell.width}px`}}
						>
							{
								(Object.prototype.hasOwnProperty.call(headCell, 'sortable') && !headCell.sortable)
								? headCell.label
								: <TableSortLabel
										active={orderBy === headCell.id}
										direction={order}
										onClick={createSortHandler(headCell.sortkey ? headCell.sortkey : headCell.id)}
									>
										{headCell.label}
									</TableSortLabel>
							}
						</TableCell>
					)
				})}
      </TableRow>
    </TableHead>
  );
}

const mapStateToProps = state => ({
  user: state.Auth.user,
});

export default connect(
  mapStateToProps
)(TableHeader);
