/* global global */
import {REPORT} from '../constants/report';
import {TimestampToYYYYMMDD} from '../helpers/DateFunctions.js';

export function changeFilters(payload) {
  return {type: REPORT.REPORT_CHANGE_FILTERS, payload:payload}
}

export function changeYear(payload) {
  return {type: REPORT.REPORT_CHANGE_YEAR, payload:payload}
}

export function loadAllStats(venue_id='') {
  return async function(dispatch, getState) {

		dispatch({ type: REPORT.REPORT_LOAD_REQUEST});

		let date_from = TimestampToYYYYMMDD(getState().Report.date_from);
		let date_to = TimestampToYYYYMMDD(getState().Report.date_to);

		if(getState().Auth.user.role === 'venue') {
			venue_id = getState().Auth.user.venue_id;
		}
		let request = `?venue_id=${venue_id}&date_from=${date_from}&date_to=${date_to}`;

		try {
			let response = await global.axios({
				method: "get",
				url: '/reports/all_stats' +  request,
			});
			dispatch({ type: REPORT.REPORT_LOAD_KEY_STATS, payload: response.data.key_stats });
			dispatch({ type: REPORT.REPORT_LOAD_DEALS_BY_TYPE, payload: response.data.deals_by_type });
			dispatch({ type: REPORT.REPORT_LOAD_DEALS_BY_STAGE, payload: response.data.deals_by_stage });
			dispatch({ type: REPORT.REPORT_LOAD_DEALS_REVENUE_BY_MONTH, payload: response.data.deals_revenue_by_month });
			dispatch({ type: REPORT.REPORT_LOAD_NEW_DEALS_BY_TYPE, payload: response.data.new_deals_by_type });
			dispatch({ type: REPORT.REPORT_LOAD_WON_DEALS_BY_TYPE, payload: response.data.won_deals_by_type });
		}
		catch(error) {
			console.error(error.response);
		}
	}
}

export function loadDealsByMonth(venue_id = '') {
	return async function(dispatch, getState) {

		if(getState().Auth.user.role === 'venue') {
			venue_id = getState().Auth.user.venue_id;
		}
		let request = `?venue_id=${venue_id}&year=${getState().Report.year}`;

		try {
			let response = await global.axios({
				method: "get",
				url: '/reports/deals_by_month' +  request,
			});
			dispatch({ type: REPORT.REPORT_LOAD_DEALS_BY_MONTH, payload: response.data });
		}
		catch(error) {
			console.error(error.response);
		}
	}
}
