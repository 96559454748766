import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Popover,
} from '@material-ui/core';
import { MoreVert, Event, PermContactCalendar } from '@material-ui/icons';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Enums from '../enums';

const useStyles = makeStyles((theme) => ({
  action: {
    cursor: 'pointer',
    height: '30px',
    paddingTop: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  BoardCard: {
    marginBottom: '7px',
    border: '1px solid #f1f1f1',
  },
  cardTitle: {
    color: '#00acc1',
    lineHeight: 1.3,
    fontWeight: 500,
    fontSize: '15px',
    '&:hover': {
      color: '#00ACC1',
    },
  },
  cardHeader: {
    padding: '5px 10px 5px 15px',
  },
  CardInfo1: {
    fontWeight: 600,
  },
  Link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const DealBoardCard = (props) => {
  const classes = useStyles();

  const handleEdit = (popupState, id) => {
    popupState.setOpen(false);
    props.openEditorModal(id);
  };

  const handleDelete = (popupState, id) => {
    popupState.setOpen(false);
    props.handleDelete(props.data);
  };

  return (
    <Card className={classes.BoardCard}>
      <CardHeader
        className={classes.cardHeader}
        action={
          <PopupState variant="popover">
            {(popupState) => (
              <div>
                <IconButton
                  className="mt-2"
                  aria-label="more"
                  aria-haspopup="true"
                  {...bindTrigger(popupState)}
                >
                  <MoreVert />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Box className="p-1" style={{ width: '130px' }}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      className={classes.action}
                      onClick={() => handleEdit(popupState, props.data.id)}
                    >
                      Edit
                    </Typography>
                    {(() => {
                      if (typeof props.onRemoveTransfer === 'function') {
                        return (
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={classes.action}
                            onClick={() => props.onRemoveTransfer(popupState, props.data)}
                          >
                            Delete
                          </Typography>
                        );
                      }

                      return (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          className={classes.action}
                          onClick={() => handleDelete(popupState, props.data.id)}
                        >
                          Delete
                        </Typography>
                      );
                    })()}
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        }
        title={
          <NavLink to={'/deals/view/' + props.data.id} className={classes.Link}>
            <Typography variant="subtitle1" component="span" className={classes.cardTitle}>
              {props.data.title}
            </Typography>
          </NavLink>
        }
      />
      <CardContent className="board-card-content pt-0">
        <Grid container direction="row" justify="space-between" spacing={2}>
          <Grid item xs={8}>
            <Typography variant="caption" component="div">
              Deal Type:
            </Typography>
            <Typography variant="body1" component="div" className={classes.CardInfo1}>
              {props.data.type &&
                props.data.type.charAt(0).toUpperCase() + props.data.type.slice(1)}
            </Typography>
          </Grid>
          {(() => {
            if ([Enums.Deal.TYPE.LEND, Enums.Deal.TYPE.INVEST].includes(props.data.type)) {
              return (
                <>
                  <Grid item xs={4}>
                    <Typography variant="caption" component="div">
                      Balance:
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.CardInfo1}
                      style={{ color: 'limegreen' }}
                    >
                      $
                      {props.data.balance
                        ? Number(props.data.balance).toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : '0.00'}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" component="div">
                      Borrowed:
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.CardInfo1}
                      style={{ color: 'limegreen' }}
                    >
                      $
                      {props.data.lended
                        ? Number(props.data.lended).toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : '0.00'}
                    </Typography>
                  </Grid>
                </>
              );
            }

            return (
              <Grid item xs={4}>
                <Typography variant="caption" component="div">
                  Amount:
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.CardInfo1}
                  style={{ color: 'limegreen' }}
                >
                  $
                  {props.data.value
                    ? Number(props.data.value).toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : '0.00'}
                </Typography>
              </Grid>
            );
          })()}
          <Grid item xs={12}>
            {props.data.date && (
              <Typography variant="body2" component="p">
                <Event className="mr-2" style={{ fontSize: '15px' }} />
                {props.data.date}
              </Typography>
            )}
            <Typography variant="body2" component="p">
              <PermContactCalendar className="mr-2" style={{ fontSize: '15px' }} />
              {props.data.contact_name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

DealBoardCard.propTypes = {
  onRemoveTransfer: PropTypes.func,
};
export default DealBoardCard;
