import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Materials
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// Components
import Dialog from '../components/Dialog'
import Inputs from '../components/Inputs'
// Validator
import Localization from '../localization'
import { useFormik } from 'formik'
import * as yup from 'yup'


const DEFAULT_VALUES = {
  columnName: '',
  insertAfter: ''
}

function DealBoardCreateColumnDialog ({
  errors,
  stages,
  reset,
  open,
  onCancel,
  onSave
}) {
  const formik = useFormik({
    initialValues: {...DEFAULT_VALUES},
    validationSchema: yup.object({
      columnName: yup.string()
        .required(Localization.formatString(Localization.formik.required, 'column name')),
    }),
    onSubmit(values) {
      // console.log(values)
      onSave(values)
    }
  })

  const handleOnClose = () => {
    formik.resetForm({
      values: {...DEFAULT_VALUES}
    })
    onCancel()
  }

  useEffect(() => {
    const fields = Object.keys(errors)
    if(fields.length > 0) {
      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        formik.setFieldError(field, errors[field])
      }
    }
  }, [errors]);
  return (
    <Dialog
      title="Create New Board Column"
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button color="secondary" onClick={handleOnClose}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={formik.handleSubmit}>Save</Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Inputs.Text
            label="Column name"
            name="columnName"
            value={formik.values.columnName}
            onChange={formik.handleChange}
            error={formik.touched.columnName && Boolean(formik.errors.columnName)}
            helperText={formik.touched.columnName && Boolean(formik.errors.columnName) && formik.errors.columnName}
          />
        </Grid>
        <Grid item xs={12}>
          <Inputs.Select
            label="Insert after column"
            options={stages}
            name="insertAfter"
            value={formik.values.insertAfter}
            onChange={formik.handleChange}
            error={formik.touched.insertAfter && Boolean(formik.errors.insertAfter)}
            helperText={formik.touched.insertAfter && Boolean(formik.errors.insertAfter) && formik.errors.insertAfter}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

DealBoardCreateColumnDialog.propTypes = {
  errors: PropTypes.object,
  stages: PropTypes.array,
  reset: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
DealBoardCreateColumnDialog.defaultValues = {
  stages: []
}

export default DealBoardCreateColumnDialog;