import {SETTING} from '../constants/setting';

const initialState = {
	errors: {},
  profile: {
		id: '',
    user_id: '',
    lastname: '',
    firstname: '',
    name: '',
    address: '',
    email: '',
		password: '',
		password_confirmation: '',
		avatar: '',
		role: '',
	},
};

const Setting = (state = initialState, action) => {

	let newState = JSON.parse(JSON.stringify(state));

	switch(action.type) {

    case SETTING.SETTING_PROFILE_LOAD : {

			let data = action.payload;

			newState.profile = data;
			newState.profile.password = "";
			newState.profile.password_confirmation = "";

			if(data.role === "venue") {
				newState.profile.name = data.venue.name;
				newState.profile.address = data.venue.address;
			}

			return newState;
    }

		case SETTING.SETTING_PROFILE_SAVE_SUCCESS : {
			newState.errors = {};
			newState.profile.password = "";
			newState.profile.password_confirmation = "";
			return newState;
		}

		case SETTING.SETTING_PROFILE_SAVE_ERROR : {
			newState.profile.password = "";
			newState.profile.password_confirmation = "";
			newState.errors = action.payload;
			return newState;
		}

		case SETTING.SETTING_PROFILE_TYPING : {
			let key = Object.keys(action.payload)[0];
			newState.profile[key] = action.payload[key];

			return newState;
		}

		case SETTING.SETTING_SAVE_AVATAR_SUCCESS : {
			newState.profile.avatar = action.payload.avatar;
			return newState;
		}

    default: return newState;
  }

}

export default Setting;
