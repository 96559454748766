import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import Link from '@material-ui/core/Link';
//
// Icons
import AddIcon from '@material-ui/icons/Add';
// Components
import Dialog from '../components/Dialog';
import Inputs from '../components/Inputs';
// Validator
import Localization from '../localization';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Enums from '../enums';
import enums from '../enums';

const DEFAULT_VALUES = {
  contact: '',
  title: '',
  date: '',
  value: '',
  type: '',
  lendfrom: '',
  description: '',
  investmentOption: '',
  bankaccountOption: '',
  ebxBankAccount: '',
};

function DealBoardUpdateDialog({
  errors,
  data,
  contacts,
  lenders,
  open,
  reset,
  onSave,
  onCancel,
  onCreateContact,
  submitting,
}) {
  const lendOrInvestment = [Enums.Deal.TYPE.LEND, Enums.Deal.TYPE.INVEST];
  const formik = useFormik({
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: yup.object({
      contact: yup.object({
        value: yup
          .number()
          .required(Localization.formatString(Localization.formik.required, 'contact')),
      }),
      inputRef: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'input reference')),
      title: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'title')),
      date: yup
        .mixed()
        .test(
          'non-string',
          Localization.formatString(Localization.formik.notValiDate, 'date'),
          (text) => (text) => moment(text).isValid()
        )
        .required(Localization.formatString(Localization.formik.required, 'date')),
      value: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'value')),
      type: yup.string().required(Localization.formatString(Localization.formik.required, 'type')),
      // lendfrom: yup.string().when('type', {
      //   is: Enums.Deal.TYPE.LOAN,
      //   then: (schema) =>
      //     schema.required(Localization.formatString(Localization.formik.required, 'invest from')),
      // }),
      investmentOption: yup
        .string()
        .nullable()
        .when('type', {
          is: (type) => lendOrInvestment.includes(type),
          then: (schema) =>
            schema.required(
              Localization.formatString(Localization.formik.required, 'investment option')
            ),
        }),
      bankaccountOption: yup
        .string()
        .nullable()
        .when('type', {
          is: (type) => lendOrInvestment.includes(type),
          then: (schema) =>
            schema.required(
              Localization.formatString(Localization.formik.required, 'bank account option')
            ),
        }),
      ebxBankAccount: yup
        .string()
        .nullable()
        .when('type', {
          is: Enums.Deal.TYPE.LOAN,
          then: (schema) =>
            schema.required(
              Localization.formatString(Localization.formik.required, 'EBX bank account option')
            ),
        }),
      description: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'description')),
    }),
    onSubmit(values) {
      formik.setSubmitting(false);
      onSave({ ...values, value: values.value.replaceAll(',', '') });
    },
  });
  const handleOnClose = () => {
    formik.resetForm({
      values: { ...DEFAULT_VALUES },
    });
    onCancel();
  };
  useEffect(() => {
    if (reset) {
      formik.resetForm({
        values: { ...DEFAULT_VALUES },
      });
    }
  }, [reset]);
  useEffect(() => {
    const fields = Object.keys(data);
    if (fields.length > 0) {
      formik.resetForm({
        values: {
          contact: {
            value: data.contact.id,
            label: `${data.contact.firstname} ${data.contact.lastname || ''}`,
          },
          title: data.title,
          inputRef: data.input_ref,
          date: new Date(data.date),
          value: data.value,
          type: data.type === Enums.Deal.TYPE.TRANSFER ? Enums.Deal.TYPE.INVEST : data.type,
          investmentOption: data.investment_option,
          bankaccountOption: data.bankacc_option,
          ebxBankAccount: data.ebx_bankacc,
          lendfrom: data.lender.length > 0 ? data.lender[0].id : '',
          description: data.notes,
        },
      });
    }
  }, [data]);
  useEffect(() => {
    const fields = Object.keys(errors);
    if (fields.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        formik.setFieldError(field, errors[field][0]);
      }
    }
  }, [errors]);
  return (
    <Dialog
      title={
        [Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER].includes(formik.values.type)
          ? 'Update New BWHF Deal'
          : 'Deals EBX'
      }
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button disabled={submitting} color="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            disabled={submitting}
            color="primary"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Inputs.AutoComplete
            label="Contact"
            options={contacts.map((item) => ({
              value: item.id,
              label: `${item.firstname} ${item.lastname || ''}`,
            }))}
            name="contact"
            value={formik.values.contact}
            onChange={formik.handleChange}
            error={formik.touched.contact && Boolean((formik.errors.contact || {}).value)}
            helperText={
              formik.touched.contact &&
              Boolean((formik.errors.contact || {}).value) &&
              (formik.errors.contact || {}).value
            }
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
          <Tooltip title="Create contact">
            <Button variant="contained" color="primary" onClick={onCreateContact}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Inputs.Text
            label="Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && Boolean(formik.errors.title) && formik.errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Inputs.Select
            disabled={true}
            label="Transaction type"
            options={[
              { label: 'Select', value: '' },
              { label: 'Investment', value: Enums.Deal.TYPE.INVEST },
              { label: 'Loan to Borrow', value: Enums.Deal.TYPE.LOAN },
              Enums.Deal.TYPE.TRANSFER,
            ]}
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && Boolean(formik.errors.type) && formik.errors.type}
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.TextDate
            label="Date"
            name="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && Boolean(formik.errors.date) && formik.errors.date}
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.TextDollar
            label={lendOrInvestment.includes(formik.values.type) ? 'Investment Amount' : 'Amount'}
            name="value"
            value={formik.values.value}
            onChange={formik.handleChange}
            error={formik.touched.value && Boolean(formik.errors.value)}
            helperText={formik.touched.value && Boolean(formik.errors.value) && formik.errors.value}
          />
        </Grid>
        <Grid item xs={12} sm={formik.values.type == enums.Deal.TYPE.LOAN ? 6 : 12}>
          <Inputs.Text
            disabled={false}
            label="Input Ref #"
            name="inputRef"
            value={formik.values.inputRef}
            onChange={formik.handleChange}
            error={formik.touched.inputRef && Boolean(formik.errors.inputRef)}
            helperText={
              formik.touched.inputRef && Boolean(formik.errors.inputRef) && formik.errors.inputRef
            }
          />
        </Grid>
        {(() => {
          if (formik.values.type === Enums.Deal.TYPE.INVEST) {
            return (
              <Fragment>
                <Grid item xs={12} sm={6}>
                  <Inputs.Select
                    disabled={true}
                    label="Investment Option"
                    options={[
                      { label: 'Select', value: '' },
                      ...Object.values(Enums.Deal.INVESTMENT_OPTION_TYPE),
                    ]}
                    name="investmentOption"
                    value={formik.values.investmentOption}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.investmentOption && Boolean(formik.errors.investmentOption)
                    }
                    helperText={
                      formik.touched.investmentOption &&
                      Boolean(formik.errors.investmentOption) &&
                      formik.errors.investmentOption
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Inputs.Select
                    disabled={true}
                    label={
                      'Bank Account Option' +
                      (Object.prototype.hasOwnProperty.call(
                        Enums.Deal.BACKACCOUNT_TO_EBX,
                        formik.values.bankaccountOption
                      )
                        ? ' (' +
                          Enums.Deal.BACKACCOUNT_TO_EBX[formik.values.bankaccountOption] +
                          ')'
                        : '')
                    }
                    options={['', ...Object.values(Enums.Deal.BANKACCOUNT_OPTION_TYPE)]}
                    name="bankaccountOption"
                    value={formik.values.bankaccountOption}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankaccountOption && Boolean(formik.errors.bankaccountOption)
                    }
                    helperText={
                      formik.touched.bankaccountOption &&
                      Boolean(formik.errors.bankaccountOption) &&
                      formik.errors.bankaccountOption
                    }
                  />
                </Grid>
              </Fragment>
            );
          }
          const selectedContact = formik.values.contact;
          let ebxOptions = [];
          if (selectedContact) {
            if (selectedContact.hasSmsf) {
              ebxOptions = [Enums.Deal.EBX.EBX_1];
            } else {
              ebxOptions = Object.values(Enums.Deal.EBX);
            }
          }
          const loanFrom = formik.values.lendfrom;
          // let investor = null;
          if (loanFrom) {
            // investor = lenders.find((o) => o.id == loanFrom);
          }
          return (
            <Fragment>
              {/* <Grid item container xs={12} sm={6}> */}
              {/*   <Grid item xs={12}> */}
              {/*     <Inputs.Select */}
              {/*       disabled={true} */}
              {/*       label={ */}
              {/*         'Loan From ' + */}
              {/*         (loanFrom ? '(' + (investor || { balance: 0 }).balance + ')' : '') */}
              {/*       } */}
              {/*       options={[ */}
              {/*         '', */}
              {/*         ...lenders.map((item) => ({ value: item.id, label: item.title })), */}
              {/*       ]} */}
              {/*       name="lendfrom" */}
              {/*       value={formik.values.lendfrom} */}
              {/*       onChange={formik.handleChange} */}
              {/*       error={formik.touched.lendfrom && Boolean(formik.errors.lendfrom)} */}
              {/*       helperText={ */}
              {/*         formik.touched.lendfrom && */}
              {/*         Boolean(formik.errors.lendfrom) && */}
              {/*         formik.errors.lendfrom */}
              {/*       } */}
              {/*     /> */}
              {/*   </Grid> */}
              {/*   <Grid item xs={12}> */}
              {/*     <Link href="#">Excluded investments</Link> */}
              {/*   </Grid> */}
              {/* </Grid> */}
              <Grid item xs={6}>
                <Inputs.Select
                  disabled={true}
                  label="EBX Bank Account"
                  options={['', ...ebxOptions]}
                  name="ebxBankAccount"
                  value={formik.values.ebxBankAccount}
                  onChange={formik.handleChange}
                  error={formik.touched.ebxBankAccount && Boolean(formik.errors.ebxBankAccount)}
                  helperText={
                    formik.touched.ebxBankAccount &&
                    Boolean(formik.errors.ebxBankAccount) &&
                    formik.errors.ebxBankAccount
                  }
                />
              </Grid>
            </Fragment>
          );
        })()}
        <Grid item xs={12}>
          <Inputs.TextArea
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={
              formik.touched.description &&
              Boolean(formik.errors.description) &&
              formik.errors.description
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

DealBoardUpdateDialog.propTypes = {
  errors: PropTypes.object,
  data: PropTypes.object,
  contacts: PropTypes.array,
  lenders: PropTypes.array,
  open: PropTypes.bool,
  reset: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
};
DealBoardUpdateDialog.defaultProps = {
  errors: {},
  data: {},
  contacts: [],
  lenders: [],
  submitting: false,
};

export default DealBoardUpdateDialog;
