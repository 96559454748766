/* global global, Promise */
import { createMachine, assign } from 'xstate';

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'Setting',
    initial: 'idle',
    on: {
      IDLE: 'idle',
      SET_PROFILE: {
        target: 'idle',
        actions: ['setProfile'],
      },
      SET_AVATAR: {
        // target: 'avatar.edit',
        actions: ['setAvatarFile'],
      },
      SAVE_AVATAR: 'avatar.edit.save.process',
      SAVE_PROFILE: 'profile.edit.save.process',
    },
    context: {
      profile: null,
      response: null,
      failedResponse: null,
    },
    states: {
      idle: {},
      avatar: {
        states: {
          edit: {
            states: {
              save: {
                states: {
                  process: {
                    invoke: {
                      src: 'savingAvatar',
                      onDone: 'success',
                      onError: 'failure',
                    },
                  },
                  success: {},
                  failure: {},
                },
              },
            },
          },
        },
      },
      profile: {
        states: {
          edit: {
            states: {
              save: {
                states: {
                  process: {
                    invoke: {
                      src: 'savingProfile',
                      onDone: {
                        target: 'success',
                        actions: ['updateProfile', 'setResponse'],
                      },
                      onError: {
                        target: 'failure',
                        actions: ['setFaileResponse'],
                      },
                    },
                  },
                  success: {},
                  failure: {},
                },
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      setFaileResponse: assign({
        failedResponse: (ctx, event) => event.data.response,
      }),
      setResponse: assign({
        response: (ctx, event) => {
          return { ...event.data.data, avatar: ctx.profile.avatar };
        },
      }),
      updateProfile: assign({
        profile: (ctx, event) => {
          const profile = event.data.data.user;
          return { ...profile, avatar: ctx.profile.avatar };
        },
      }),
      setProfile: assign({
        profile: (ctx, event) => event.data,
      }),
      setAvatarFile: assign({
        editing: true,
        profile: (ctx, event) => {
          const profile = { ...ctx.profile };
          profile.avatarFile = event.data.file;
          profile.avatar = URL.createObjectURL(profile.avatarFile);
          return profile;
        },
      }),
    },
    services: {
      savingProfile(ctx, event) {
        return global.axios.post('/save_profile', event.data);
      },
      savingAvatar(ctx, event) {
        const profile = ctx.profile;
        if (!profile.avatarFile) {
          return Promise.resolve(true);
        }
        const formData = new FormData();
        formData.append('id', profile.id);
        formData.append('avatar', profile.avatarFile);
        return global.axios.post('/save_avatar', formData);
      },
    },
  }
);
