/* global global */
import { createMachine, assign } from 'xstate';

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'Contact',
    initial: 'idle',
    context: {
      response: null,
      list: [],
    },
    states: {
      idle: {
        on: {
          CREATE: 'create.show',
          EDIT: 'edit.show',
          FETCH: 'fetch.process',
        },
      },
      fetch: {
        states: {
          process: {
            invoke: {
              src: 'fetching',
              onDone: {
                target: 'success',
                actions: ['setList'],
              },
              onError: 'failure',
            },
          },
          success: {
            on: {
              CLOSE: '#Contact.idle',
            },
          },
          failure: {
            on: {
              CLOSE: '#Contact.idle',
            },
          },
        },
      },
      create: {
        states: {
          show: {
            on: {
              SAVE: 'process',
              CANCEL: '#Contact.idle',
            },
          },
          process: {
            invoke: {
              src: 'creating',
              onDone: {
                target: 'success',
                actions: ['addToList'],
              },
              onError: [
                {
                  target: 'invalid',
                  cond: 'isValidationError',
                  actions: ['setResponse'],
                },
                { target: 'failure' },
              ],
            },
          },
          success: {
            on: {
              CLOSE: '#Contact.idle',
            },
          },
          failure: {
            on: {
              CLOSE: '#Contact.idle',
            },
          },
          invalid: {
            on: {
              CLOSE: 'show',
            },
          },
        },
      },
      edit: {
        states: {
          show: {
            on: {
              SAVE: 'process',
              CANCEL: '#Contact.idle',
            },
          },
          process: {
            invoke: {
              src: 'updating',
              onDone: 'success',
              onError: [
                {
                  target: 'invalid',
                  cond: 'isValidationError',
                  actions: ['setResponse'],
                },
                { target: 'failure' },
              ],
            },
          },
          success: {
            on: {
              CLOSE: '#Contact.idle',
            },
          },
          failure: {
            on: {
              CLOSE: 'show',
            },
          },
          invalid: {
            on: {
              CLOSE: 'show',
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isValidationError(ctx, event) {
        return event.data.response.status == 422;
      },
    },
    actions: {
      setList: assign({
        list: (ctx, event) => event.data.data,
      }),
      addToList: assign({
        list: (ctx, event) => [event.data.data, ...ctx.list],
      }),
      setResponse: assign({
        response: (ctx, event) => event.data.response,
      }),
    },
    services: {
      fetching(ctx, event) {
        return global.axios.get('/v2/contacts');
      },
      creating(ctx, event) {
        return global.axios.post('/v2/contacts/create', event.data);
      },
      updating(ctx, event) {
        return global.axios.post('/v2/contacts/update', event.data);
      },
    },
  }
);
