import React from 'react';
import { connect } from 'react-redux';
import { save, removeFile } from '../actions/contact_comment';

import {Grid, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Box, IconButton} from '@material-ui/core';
import {AttachFile as AttachFileIcon, Close as CloseIcon} from '@material-ui/icons';

import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ContactCommentUpdate = (props) => {

	const handleFormSubmit = event => {
		event.preventDefault();
		props.save({
			comment: draftToHtml(convertToRaw(content.getCurrentContent())),
			files: files,
			deleteFiles: filesToDelete
		});
		setFiles([]);
		setFilesToDelete([]);
	}

	const [content, setContent] = React.useState(EditorState.createEmpty());
	const  onEditorStateChange = (editorState) => {
		setContent(editorState);
	};

	const [init, setInit] = React.useState(false);
	if(Object.keys(props.comment).length > 0 && !init) {
		setContent(
			EditorState.createWithContent(
				ContentState.createFromBlockArray(
					convertFromHTML(props.comment.comment)
				)
			)
		);
		setInit(true);
	}

	if(props.success && props.open) {
		props.closeEditorModal();
	}

	const [files, setFiles] = React.useState([]);
	const handleFileUpload = (event) => {
		if (event.target.files && event.target.files[0]) {
			let newFiles = files.concat(Array.from(event.target.files));
			setFiles(newFiles);
			document.getElementById('file-upload-update').value = null;
		}
	}

	const handleRemoveFile = (index) => {
		let newFiles = files.filter(item => item !== files[index]);
		setFiles(newFiles);
	}

	const [filesToDelete, setFilesToDelete] = React.useState([]);
	const handleDeleteFiles = (id) => {

		props.removeFile({ id: id });

		let newFilesToDelete = filesToDelete;
		newFilesToDelete.push(id);
		setFilesToDelete(newFilesToDelete);
	}

	const handleCancel = () => {
		setInit(false);
		setContent(EditorState.createEmpty())
		props.closeEditorModal();
	}

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
				onClose={() => props.closeEditorModal()}
				maxWidth="sm"
				fullWidth
      >
        <form autoComplete="off" onSubmit={handleFormSubmit}>
					<DialogTitle
						className="modal-header"
					>
						Update Comment
					</DialogTitle>
					<DialogContent className="pt-4">
						<Paper className="custom-card-border">
							<Editor
								editorState={content}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								onEditorStateChange={onEditorStateChange}
							/>
						</Paper>
						<Grid item xs={12}>
							<FileUploadList
								files={props.comment.files}
								handleRemoveFile={(id)=>handleDeleteFiles(id)}
							/>
							<FileUploadList
								files={files}
								handleRemoveFile={(index)=>handleRemoveFile(index)}
							/>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleCancel()} color="primary" className="mr-1 mb-2" size="small">
							Cancel
						</Button>
						<input
							accept="image/*"
							className="d-none"
							id="file-upload-update"
							multiple
							type="file"
							onChange={(event) => handleFileUpload(event)}
						/>
						<label htmlFor="file-upload-update" className="mr-1">
							<Button variant="outlined" component="span" size="small">
								Upload
							</Button>
						</label>
						<Button type="submit" variant="contained" size="small" color="secondary" className="mr-3 mb-2" disabled={content.getCurrentContent().hasText() ? false : true}>
							Save Note
						</Button>
					</DialogActions>
				</form>
      </Dialog>
    </div>
  );
}

const FileUploadList = (props) => {
	return(
		<div
			style={{ width: '100%' }} className="mt-1">
			{
				props.files && props.files.map((row, index) => {
					return (
						<Box
							display="flex"
							p={1}
							bgcolor="background.paper"
							key={index}
							className="p-0 pt-1 pl-1"
						>
							<Box flexGrow={1} bgcolor="grey.100" className="upload-file-list-title" >
								<AttachFileIcon className="ml-1 mr-1" style={{ fontSize:'10px' }} />
								{
									(() => {
										/* eslint-disable no-useless-escape */
										return row.file ? row.file.split(/[\\\/]/).pop() : row.name
										/* eslint-enable no-useless-escape */
									})()
								}
							</Box>
							<Box bgcolor="grey.100" className="upload-file-list-action">
								<label htmlFor="icon-button-file" className="m-0" title="remove">
									<IconButton
										color="primary"
										aria-label="upload picture"
										component="span"
										size="small"
										onClick={() => props.handleRemoveFile(row.id ? row.id : index)}
									>
										<CloseIcon style={{ fontSize:'10px' }}/>
									</IconButton>
								</label>
							</Box>
						</Box>
					)
				})
			}
    </div>
	)
}

const mapStateToProps = state => ({
	success: state.ContactComment.success,
	comment: state.ContactComment.comment,
});

const mapDispatchToProps = {
	save: (payload) => save(payload),
	removeFile: (payload) => removeFile(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactCommentUpdate);
