import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import {
  DashboardOutlined,
  PeopleOutlineRounded,
  LocalOfferOutlined,
  SettingsOutlined,
  // LocationCityOutlined,
  EqualizerRounded,
} from '@material-ui/icons';
import GroupIcon from '@material-ui/icons/Group';

const menuItems = [
  {
    itemLink: '',
    itemLabel: 'Dashboard',
    restrict: '',
    itemIcon: <DashboardOutlined />,
  },
  {
    itemLink: 'users',
    itemLabel: 'Users',
    restrict: 'god_admin',
    itemIcon: <GroupIcon />,
  },
  {
    itemLink: 'contacts',
    itemLabel: 'Contacts',
    restrict: '',
    itemIcon: <PeopleOutlineRounded />,
  },
  {
    itemLink: 'deals',
    itemLabel: 'Deals',
    restrict: '',
    itemIcon: <LocalOfferOutlined />,
  },
  {
    itemLink: 'reports',
    itemLabel: 'Reports',
    restrict: '',
    itemIcon: <EqualizerRounded />,
  },
  {
    itemLink: 'settings',
    itemLabel: 'Settings',
    restrict: '',
    itemIcon: <SettingsOutlined />,
  },
];

const Sidebar = (props) => {
  const handleMenuSelect = (link) => {
    props.history.push('/' + link);
  };

  return (
    <List className="sidebar" style={{ marginTop: '75px' }}>
      <div
        className={props.open ? 'row mb-3 ml-1 d-block' : 'd-none'}
        style={{ fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}
      >
        <div className="col col-md-12">NAVIGATION</div>
      </div>
      {menuItems.map((row, index) => {
        if (row.restrict && row.restrict !== props.user.role) {
          return null;
        }

        let menuItem = (
          <ListItem
            button
            key={index}
            className={
              row.itemLink === props.history.location.pathname.replace(/^\//, '')
                ? 'menu-item-selected'
                : ''
            }
            onClick={() => handleMenuSelect(row.itemLink)}
          >
            <ListItemIcon>{row.itemIcon}</ListItemIcon>
            <ListItemText primary={row.itemLabel} />
          </ListItem>
        );

        if (props.open) {
          return menuItem;
        } else {
          return (
            <Tooltip key={index} title={row.itemLabel} placement="right">
              {menuItem}
            </Tooltip>
          );
        }
      })}
    </List>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

export default connect(mapStateToProps)(withRouter(Sidebar));
