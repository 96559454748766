import React, { useEffect, useState } from 'react';
import Enums from '../../enums';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TablePagination,
  TableContainer,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import TransformIcon from '@material-ui/icons/Transform';
import { VisibilityRounded, EditRounded, Delete, AssignmentRounded } from '@material-ui/icons';
import { TableStyles } from './TableStyles';
import TableHeader from './TableHeader';
import TableSearchRow from './TableSearchRow';
import { stableSort, getSorting, search } from './TableFunctions';
import { formatNumber } from '../../helpers/GeneralFunctions.js';
import { DateToString } from '../../helpers/DateFunctions';

import ArchiveIcon from '@material-ui/icons/Archive';

const TableComponent = (props) => {
  const {
    user,
    data,
    headCells,
    handleView,
    handleEdit,
    handleDelete,
    handleAssign,
    handleArchived,
    handleTransfer,
    handleRemoveTransfer,
    onUpdateTransfer,
  } = props;

  useEffect(() => {
    getData();
  }, [data]);

  const classes = TableStyles();
  const [listData, setListData] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, listData.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = async () => {
    if (data && headCells) {
      let newData = await search(data, searchKeys, headCells);
      setListData(newData);
    }
  };

  const [searchKeys, setSearchKeys] = useState({});
  useEffect(() => {
    getData();
  }, [searchKeys]);

  const handleTextSearch = (event) => {
    setPage(0);

    let field = event.target.name;
    let value = event.target.value;

    setSearchKeys({
      ...searchKeys,
      [field]: value,
    });
  };

  const handleDateSearch = (dateStr, date) => {
    setPage(0);

    let arr = date.split('|');
    let field = arr[1];
    let value = arr[0] !== 'null' ? arr[0] : null;

    setSearchKeys({
      ...searchKeys,
      [field]: value,
    });
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          {headCells && (
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          )}
          <TableBody>
            {((headCells && !Object.prototype.hasOwnProperty.call(props, 'filter')) ||
              (Object.prototype.hasOwnProperty.call(props, 'filter') && props.filter)) && (
              <TableSearchRow
                user={user}
                headCells={headCells}
                searchKeys={searchKeys}
                handleDateSearch={handleDateSearch}
                handleTextSearch={handleTextSearch}
              />
            )}
            {stableSort(listData, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, i) => {
                let trClass = i % 2 ? classes.rowEven : classes.rowOdd;

                return (
                  <TableRow hover key={i} className={trClass}>
                    {headCells.map((cell, c) => {
                      let cellText = item[cell.id];
                      if (Object.prototype.hasOwnProperty.call(cell, 'render')) {
                        return (
                          <TableCell key={`${i}${c}`} align={cell.align}>
                            {cell.render(item, cellText)}
                          </TableCell>
                        );
                      }
                      if (cell.id) {
                        switch (cell.type) {
                          case 'date':
                            cellText = cellText ? DateToString(cellText) : '';
                            break;
                          case 'number':
                            cellText = cellText ? formatNumber(cellText) : '';
                            break;
                        }

                        if (
                          Object.prototype.hasOwnProperty.call(cell, 'restrict') &&
                          cell.restrict !== user.role
                        ) {
                          return null;
                        }

                        return (
                          <TableCell key={`${i}${c}`} align={cell.align}>
                            {cellText}
                          </TableCell>
                        );
                      }
                    })}

                    <TableCell align="right" style={{ width: '125px' }}>
                      {(() => {
                        if (typeof handleTransfer === 'function') {
                          if (
                            Object.prototype.hasOwnProperty.call(item, 'type') &&
                            item.type === Enums.Deal.TYPE.INVEST
                          ) {
                            return (
                              <Tooltip title="Transfer to Bank">
                                <IconButton size="small" onClick={() => handleTransfer(item)}>
                                  <TransformIcon fontSize="small" color="secondary" />
                                </IconButton>
                              </Tooltip>
                            );
                          }
                        }
                      })()}
                      {typeof handleView === 'function' && (
                        <Tooltip title="View">
                          <IconButton size="small" onClick={() => handleView(item)}>
                            <VisibilityRounded fontSize="small" style={{ color: '#46a54f' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(() => {
                        if (
                          Object.prototype.hasOwnProperty.call(item, 'type') &&
                          item.type === Enums.Deal.TYPE.TRANSFER
                        ) {
                          if (typeof onUpdateTransfer === 'function') {
                            return (
                              <Tooltip title="Edit Transfer">
                                <IconButton size="small" onClick={() => onUpdateTransfer(item)}>
                                  <EditRounded fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            );
                          }

                          return '';
                        }

                        if (typeof handleEdit === 'function') {
                          return (
                            <Tooltip title="Edit">
                              <IconButton size="small" onClick={() => handleEdit(item)}>
                                <EditRounded fontSize="small" color="primary" />
                              </IconButton>
                            </Tooltip>
                          );
                        }
                      })()}

                      {typeof handleAssign === 'function' && (
                        <Tooltip title="Assign">
                          <IconButton size="small" onClick={() => handleAssign(item)}>
                            <AssignmentRounded fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(() => {
                        if (
                          typeof handleRemoveTransfer === 'function' &&
                          Object.prototype.hasOwnProperty.call(item, 'type') &&
                          item.type === Enums.Deal.TYPE.TRANSFER
                        ) {
                          return (
                            <Tooltip title="Remove Transfer">
                              <IconButton size="small" onClick={() => handleRemoveTransfer(item)}>
                                <Delete fontSize="small" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          );
                        }
                        if (typeof handleDelete === 'function') {
                          return (
                            <Tooltip title="Delete">
                              <IconButton size="small" onClick={() => handleDelete(item)}>
                                <Delete fontSize="small" color="error" />
                              </IconButton>
                            </Tooltip>
                          );
                        }
                      })()}
                      {typeof handleArchived === 'function' && (
                        <Tooltip title="Archived">
                          <IconButton size="small" onClick={() => handleArchived(item)}>
                            <ArchiveIcon fontSize="small" color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
        count={listData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      sortable: PropTypes.bool,
      searchable: PropTypes.bool,
      width: PropTypes.number,
      render: PropTypes.func,
    })
  ).isRequired,
  filter: PropTypes.bool,
  handleView: PropTypes.func || PropTypes.bool,
  handleEdit: PropTypes.func || PropTypes.bool,
  handleDelete: PropTypes.func || PropTypes.bool,
  handleAssign: PropTypes.func || PropTypes.bool,
  handleArchived: PropTypes.func || PropTypes.bool,
  handleTransfer: PropTypes.func || PropTypes.bool,
  handleRemoveTransfer: PropTypes.func || PropTypes.bool,
  onUpdateTransfer: PropTypes.func || PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

export default connect(mapStateToProps)(TableComponent);
