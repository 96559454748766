/* global global */
import React from 'react';
import {connect} from 'react-redux';
import {save} from '../actions/deal';
import {Button,Grid,TextField,Typography} from '@material-ui/core';

const TestInquiryToDev = (props) => {

	const [state, setState] = React.useState({
		title: 'Test Event2',
		notes: 'test event from form2',
	});

	const handleChange = (event, name) => {
		setState({
			...state,
			[name]: event.target.value,
		});
	}

	const sendRequest = () => {
		global.axios({
      method: 'post',
      url: 'https://eventpilot-api.dev.fld3.xyz/api/save_deal',
      data: {
				venue_id: 10,
				contact_id: 1,
				title: state.title,
				type: 'Birthday',
				date: '2020-12-25',
				notes: state.notes,
		}
    })
    .then( ({data}) => {
			// console.log(data);
			alert("Success!");
			setState({title:'',notes:'',});
    })
    .catch( function(error) {
			console.error(error);
    });
	}

  return (
    <div style={{width:'100%',paddingTop:'100px'}}>
			<Grid container spacing={2} style={{width:'600px', margin:'auto'}}>
				<Grid item xs={12} className="text-center font-weight-bold">
					<Typography variant="h4" component="h4" style={{fontWeight:800}}>
						Inquiry Form2
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant="outlined"
						label="Title"
						value={state.title}
						onChange={(event)=>handleChange(event, 'title')}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant="outlined"
						label="Description"
						value={state.notes}
						onChange={(event)=>handleChange(event, 'notes')}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						size="large"
						variant="contained"
						color="primary"
						onClick={() => sendRequest()}
					>
						Submit Inquiry
					</Button>
				</Grid>
			</Grid>
		</div>
  );
}

const mapStateToProps = state => ({
  // deal: state.Deal.deal,
});

const mapDispatchToProps = {
	save: () => save(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestInquiryToDev);
