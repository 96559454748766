import React from 'react';
import { connect } from 'react-redux';
import {Bar} from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  paper: {
		padding: '20px',
		border: '1px solid #e2e0e0',
	},
});

const legendOpts = {
  display: false,
  position: 'bottom',
  fullWidth: true,
  reverse: false,
  labels: {
    fontColor: 'rgb(77, 77, 77)'
	}
};

const options = {
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero: true
			}
		}],
		xAxes: [{
			gridLines: {
					offsetGridLines: true
			}
		}]
	},
	plugins: {
		datalabels: {
			display: true,
			color: '#30C3A6',
			anchor: 'end',
			font: {
				size: 9,
				weight: 700,
			},
			formatter: function(value, context) {
				return value > 0 ? `${value}\n` : '';
			}
		}
	}
};

const labels = ['Birthday', 'Corporate', 'Engagement', 'Wedding', 'Other'];

const ReportNewDealsByTypeChart = (props) => {

	const classes = useStyles();

	if(props.new_deals_by_type.length === 0) {
		return null;
	}

	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Count',
				maxBarThickness: 60,
				backgroundColor: 'rgb(26, 188, 156, 0.9)',
				borderColor: 'rgb(26, 188, 156)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(26, 188, 156,0.7)',
				hoverBorderColor: 'rgba(26, 188, 156)',
				data: getData(props.new_deals_by_type),
			}
		]
	};

	return (
			<Paper className={classes.paper}>
			<Grid container spacing={2} id="bar_chart_new_deal">
				<Grid item xs={12}>
					<Typography variant="h6">
						New Deals By Type
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Bar data={data} legend={legendOpts} options={options} redraw/>
				</Grid>
			</Grid>
		</Paper>
	)
}

const getData = (stat) => {

	let data = [];
	let max_cnt = 0;

	for(let l in labels) {
		let cnt = 0;
		for(let i in stat) {
			if(labels[l] === stat[i].type) {
				cnt = stat[i].cnt;
				max_cnt = max_cnt > stat[i].cnt ? max_cnt : stat[i].cnt;
				break;
			}
		}
		data.push(cnt);
	}

	options.scales.yAxes[0].ticks.max = max_cnt + (max_cnt*0.1);

	return data;
}

const mapStateToProps = state => ({
	new_deals_by_type: state.Report.new_deals_by_type,
});

export default connect(
  mapStateToProps
)(ReportNewDealsByTypeChart);
