import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { DateToString } from "../helpers/DateFunctions";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
	Paper,
	IconButton,
	CircularProgress,
	Box,
} from "@material-ui/core";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import TableHeader from "../components/table/TableHeader";
import { stableSort, getSorting } from "../components/table/TableFunctions";

const headCells = [
	{ id: "venue_name", align: "left", label: "Venue", restrict: "god_admin" },
	{ id: "type", align: "left", label: "Type" },
	{ id: "contact_name", align: "left", label: "Contact" },
	{ id: "date", align: "left", label: "Date" },
	{ id: "created_at", align: "left", label: "Date Created" },
	{ id: "", align: "right", label: "Action", disableSort: true },
];

TableHeader.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	title: {
		flex: "1 1 100%",
	},
}));

const EnhancedTableToolbar = ({ dateFilter }) => {
	const classes = useToolbarStyles();
	return (
		<Toolbar className={classes.root}>
			<Typography className={classes.title} variant="h6" id="tableTitle">
				{dateFilter.date_from !== null && dateFilter.date_to !== null
					? "Deals"
					: "Latest Deals"}
			</Typography>
			{/* <Tooltip title="Filter list">
				<IconButton aria-label="filter list">
					<FilterListIcon />
				</IconButton>
			</Tooltip> */}
		</Toolbar>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
	},
	table: {
		width: "100%",
	},
}));

const LatestDeals = (props) => {
	const classes = useStyles();
	const [order, setOrder] = React.useState("desc");
	const [orderBy, setOrderBy] = React.useState("created_at");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage -
		Math.min(
			rowsPerPage,
			props.statsData !== null
				? props.statsData.latest_deals.length
				: 0 - page * rowsPerPage
		);

	// const tblBody = () => {
	// 	stableSort(
	// 		props.statsData !== null ? props.statsData.latest_deals : [],
	// 		getSorting(order, orderBy)
	// 	)
	// 		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
	// 		.map((item, index) => {
	// 			return (
	// 				<TableRow hover role="checkbox" tabIndex={-1} key={index}>
	// 					{props.user.role === "god_admin" ? (
	// 						<TableCell>{item.venue_name} </TableCell>
	// 					) : null}

	// 					<TableCell> {item.type} </TableCell>
	// 					<TableCell>{item.contact_name}</TableCell>
	// 					<TableCell>{item.date ? DateToString(item.date) : ""}</TableCell>
	// 					<TableCell>{DateToString(item.created_at)}</TableCell>
	// 					<TableCell align="right">
	// 						<NavLink to={"/deals/view/" + item.id} title="View deal">
	// 							<IconButton edge="end" aria-label="view">
	// 								<VisibilityIcon
	// 									fontSize="small"
	// 									style={{ color: "#46a54f" }}
	// 								/>
	// 							</IconButton>
	// 						</NavLink>
	// 					</TableCell>
	// 				</TableRow>
	// 			);
	// 		});

	// emptyRows > 0 && (
	// 	<TableRow style={{ height: 53 * emptyRows }}>
	// 		<TableCell colSpan={6} />
	// 	</TableRow>
	// );
	// };

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar dateFilter={props.dateFilter} />
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size="small"
						aria-label="enhanced table"
					>
						<TableHeader
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>

						<TableBody>
							{props.state[0].matches("fetchReport.process") ||
							props.state[0].matches("fetchStats.process") ? (
								<TableRow style={{ height: "100%" }}>
									<TableCell colSpan={6}>
										<Box
											style={{
												width: "100%",
												height: "100%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<CircularProgress size={40} />
										</Box>
									</TableCell>
								</TableRow>
							) : (
								stableSort(
									props.statsData !== null ? props.statsData.latest_deals : [],
									getSorting(order, orderBy)
								)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((item, index) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={index}>
												{props.user.role === "god_admin" ? (
													<TableCell>{item.venue_name} </TableCell>
												) : null}

												<TableCell> {item.type} </TableCell>
												<TableCell>{item.contact_name}</TableCell>
												<TableCell>
													{item.date ? DateToString(item.date) : ""}
												</TableCell>
												<TableCell>{DateToString(item.created_at)}</TableCell>
												<TableCell align="right">
													<NavLink
														to={"/deals/view/" + item.id}
														title="View deal"
													>
														<IconButton edge="end" aria-label="view">
															<VisibilityIcon
																fontSize="small"
																style={{ color: "#46a54f" }}
															/>
														</IconButton>
													</NavLink>
												</TableCell>
											</TableRow>
										);
									})
							)}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 15]}
					component="div"
					count={
						props.statsData !== null ? props.statsData.latest_deals.length : 0
					}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.Auth.user,
	latest_deals: state.Deal.stats.latest_deals,
});

export default connect(mapStateToProps)(LatestDeals);
