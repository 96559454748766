/* global global */
import {AUTH} from '../constants/auth';

export function typing(payload){
  return {type: AUTH.TYPING, payload: payload}
}

export function login(payload) {

    return function(dispatch) {

        dispatch({ type: AUTH.LOGIN_REQUEST });

        return global.axios.post('/login', {
            'email': payload.email,
            'password': payload.password
        })
        .then( ({ data }) => {
            dispatch({ type:AUTH.LOGIN_SUCCESS, payload:data });
        })
        .catch( function(error) {
            dispatch({ type:AUTH.LOGIN_ERROR, payload:error.response.data });
        });
    }
}

export function logout(history) {
    return function(dispatch) {
        dispatch({type: AUTH.LOGOUT});
        history.push('/');
    }
}

