import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {loadUnassigned, assignDealToVenue} from '../actions/deal';
import {Grid} from '@material-ui/core';
import {LocalOfferOutlined} from '@material-ui/icons';
import PageHeader from '../components/PageHeader.js';
import TableComponent from '../components/table/TableComponent';
import AssignDealDialog from './AssignDealDialog';
import {SuccessSnackbar} from '../components/Snackbars.js';

const headCells = [
	{ id: 'venue_email', label: 'Venue Email'},
	{ id: 'contact_email', label: 'Contact Email'},
	{ id: 'contact_name', label: 'Contact Name'},
	{ id: 'title', label: 'Title'},
  { id: 'notes', label: 'Notes'},
	{ id: 'created_at', label: 'Date Created', align: 'center', type: 'date'},
  { id: '', label: 'Action', align: 'right', searchable: false, sortable:false},
];

const UnassignedDeals = (props) => {

	const {unassigned_deals, loadUnassigned, assignDealToVenue} = props;

	useEffect(() => {
		async function loadData() {
			await loadUnassigned();
		}
		loadData();
	}, []);

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const toggleSnackbar = () => {
		setSnackbarOpen(!snackbarOpen);
	}

	const [deal, setDeal] = useState({});
	const [openAssign, setOpenAssign] = useState(false);
	const toggleModal = () => {
		if(!openAssign) {
			loadUnassigned();
		}
		setOpenAssign(!openAssign);
	}

	const handleAssign = (deal) => {
		setDeal(deal);
		toggleModal();
	}

	const assignDeal = async (data) => {
		let success = await assignDealToVenue(data);
		if(success) {
			setSnackbarOpen(true);
			loadUnassigned();
			toggleModal();
		}
	}

  return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PageHeader headerTitle="Deals" subTitle="Unassigned" icon={<LocalOfferOutlined />} />
			</Grid>
			<Grid item xs={12}>
				{openAssign && (
					<AssignDealDialog
						deal={deal}
						toggleModal={() => toggleModal()}
						assignDeal={(data) => assignDeal(data)}
					/>
				)}
				{snackbarOpen && (
							<SuccessSnackbar
							open={snackbarOpen}
							toggleSnackbar={() => toggleSnackbar()}
							message="Deal successfully assigned."
						/>
						)}
				<TableComponent
					data={unassigned_deals}
					headCells={headCells}
					handleAssign={(deal) => handleAssign(deal)}
				/>
			</Grid>
		</Grid>
  );
}

const mapStateToProps = state => ({
	unassigned_deals: state.Deal.unassigned_deals,
});

const mapDispatchToProps = {
	loadUnassigned: () => loadUnassigned(),
	assignDealToVenue: (payload) => assignDealToVenue(payload),
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UnassignedDeals);
