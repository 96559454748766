import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AlertFormError = (props) => {
  const { errors } = props;

  const classes = useStyles();

  let message = [];
  let key = 0;

  for (let e in errors) {
    errors[e].map((item) => {
      message.push(<li key={key++}>{item}</li>);
    });
  }

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <ul>{message}</ul>
      </Alert>
    </div>
  );
};
AlertFormError.propTypes = {
  errors: PropTypes.object,
};

export default AlertFormError;
