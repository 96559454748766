import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Enums from '../enums';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// Components
import Dialog from './Dialog';
import Inputs from './Inputs';
// Validator
import Localization from '../localization';
import { useFormik } from 'formik';
import * as yup from 'yup';

let BANK_OPTIONS = Object.keys(Enums.Deal.BACKACCOUNT_TO_EBX).map((item) => {
  return {
    label: item,
    value: Enums.Deal.BACKACCOUNT_TO_EBX[item],
  };
});
BANK_OPTIONS = [{ label: '', value: '' }, ...BANK_OPTIONS];

const DEFAULT_VALUES = {
  contactName: '',
  referenceNumber: '',
  transactionDate: '',
  amountTransfer: '',
  bankAccountOpt: '',
  description: '',
};

const DealTransferEditDialog = ({
  isSmsf,
  open,
  onClose,
  contact,
  onSubmit,
  loading,
  errors,
  data,
}) => {
  const formik = useFormik({
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: yup.object({
      contact: yup.string(),
      referenceNumber: yup
        .string()
        .required(
          Localization.formatString(Localization.formik.required, 'input reference number')
        ),
      transactionDate: yup
        .mixed()
        .test(
          'non-string',
          Localization.formatString(Localization.formik.notValiDate, 'transaction date'),
          (text) => {
            return typeof text === 'string' || text instanceof Date;
          }
        )
        .required(Localization.formatString(Localization.formik.required, 'transaction date')),
      amountTransfer: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'amount transfer')),
      bankAccountOpt: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'bank account option')),
      // description: yup.string().required(Localization.formatString(Localization.formik.required, 'description')),
    }),
    onSubmit(values) {
      onSubmit({
        id: data.id,
        ...values,
        amountTransfer: values.amountTransfer.replaceAll(',', ''),
      });
    },
  });
  let bankOptions = [...BANK_OPTIONS];
  if (isSmsf) {
    bankOptions = [
      BANK_OPTIONS[0],
      {
        label: Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_2,
        value: Enums.Deal.BACKACCOUNT_TO_EBX[Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_2],
      },
    ];
  }
  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    formik.setFieldValue('referenceNumber', data.reference);
    formik.setFieldValue('transactionDate', data.transaction_date);
    formik.setFieldValue('amountTransfer', data.amount);
    formik.setFieldValue('bankAccountOpt', data.bank_account);
    formik.setFieldValue('description', data.description);
  }, [data]);
  useEffect(() => {
    const hasErrors = Boolean(errors);
    if (hasErrors) {
      const map = {
        dealId: 'contact',
        inputRef: 'referenceNumber',
        ebxBankAccount: 'bankAccountOpt',
        amount: 'amountTransfer',
      };
      Object.keys(errors).forEach((fieldName) => {
        if (Object.prototype.hasOwnProperty.call(map, fieldName)) {
          formik.setFieldError(map[fieldName], errors[fieldName][0]);
        } else {
          formik.setFieldError(fieldName, errors[fieldName][0]);
        }
      });
    }
  }, [errors]);
  return (
    <Dialog
      title="Edit Transfer to Bank"
      open={open}
      onClose={onClose}
      footer={
        <>
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Transfer
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Inputs.Text
            label="Contact"
            name="contactName"
            disabled={true}
            value={contact.firstname + ' ' + (contact.lastname == null ? '' : contact.lastname)}
            error={Boolean(formik.errors.contact)}
            helperText={Boolean(formik.errors.contact) && formik.errors.contact}
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.Text
            label="Email Address"
            name="contactEmail"
            disabled={true}
            value={contact.email}
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.Text label="Phone" name="contactPhone" disabled={true} value={contact.phone} />
        </Grid>
        <Grid item xs={6}>
          <Inputs.Text
            label="Input Reference #"
            name="referenceNumber"
            value={formik.values.referenceNumber}
            onChange={formik.handleChange}
            error={formik.touched.referenceNumber && Boolean(formik.errors.referenceNumber)}
            helperText={
              formik.touched.referenceNumber &&
              Boolean(formik.errors.referenceNumber) &&
              formik.errors.referenceNumber
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.TextDate
            label="Transaction Date"
            name="transactionDate"
            value={formik.values.transactionDate}
            onChange={formik.handleChange}
            error={formik.touched.transactionDate && Boolean(formik.errors.transactionDate)}
            helperText={
              formik.touched.transactionDate &&
              Boolean(formik.errors.transactionDate) &&
              formik.errors.transactionDate
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.TextDollar
            label="Amount Transfer"
            name="amountTransfer"
            value={formik.values.amountTransfer}
            onChange={formik.handleChange}
            error={formik.touched.amountTransfer && Boolean(formik.errors.amountTransfer)}
            helperText={
              formik.touched.amountTransfer &&
              Boolean(formik.errors.amountTransfer) &&
              formik.errors.amountTransfer
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.Select
            label="Bank Account Option"
            name="bankAccountOpt"
            value={formik.values.bankAccountOpt}
            onChange={formik.handleChange}
            options={bankOptions}
            error={formik.touched.bankAccountOpt && Boolean(formik.errors.bankAccountOpt)}
            helperText={
              formik.touched.bankAccountOpt &&
              Boolean(formik.errors.bankAccountOpt) &&
              formik.errors.bankAccountOpt
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Inputs.TextArea
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={
              formik.touched.description &&
              Boolean(formik.errors.description) &&
              formik.errors.description
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
DealTransferEditDialog.propTypes = {
  isSmsf: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    amount: PropTypes.string,
    bank_account: PropTypes.string,
    created_at: PropTypes.string,
    deal_id: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.number,
    reference: PropTypes.string,
    transaction_date: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  contact: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};
DealTransferEditDialog.defaultProps = {
  isSmsf: false,
  open: false,
  loading: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default DealTransferEditDialog;
