import React from 'react';
import { connect } from 'react-redux';
import { typing, save } from '../actions/contact';
// import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  TextField,
} from '@material-ui/core';
import FormErrorMessage from '../components/FormErrorMessage';
import { hasSpecialCharacter } from '../helpers/GeneralFunctions.js';

// const useStyles = makeStyles(theme => ({
//   DialogTitle: {
//     color: '#fff',
// 		backgroundColor: '#323a46',
// 		borderRadius: '1px !important',
// 		padding: '12px 24px !important',
// 	},
// 	TextArea: {
// 		width: '100%',
// 		padding: '5px',
// 		borderRadius: '3px',
// 	}
// }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ContactEditDialog = (props) => {
  // const classes = useStyles();

  const handleFormChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    let pattern = ['phone', 'email'].indexOf(field) !== -1 ? field : 'standard';

    if (hasSpecialCharacter(value, pattern)) {
      return;
    }

    props.typing({ [event.target.name]: event.target.value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let response = await props.save();
    if (response) {
      props.handleSaveSuccess();
    }
  };

  if (props.success) {
    props.closeEditorModal();
  }

  if (!props.open) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.closeEditorModal()}
        maxWidth="sm"
        fullWidth
      >
        <form autoComplete="off" onSubmit={handleFormSubmit}>
          <DialogTitle className="modal-header">Update Contact Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormErrorMessage error={props.error} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="div" color="textSecondary" className="mb-1">
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="lastname"
                  defaultValue={props.contact.lastname}
                  onBlur={(event) => handleFormChange(event)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="div" color="textSecondary" className="mb-1">
                  First Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="firstname"
                  defaultValue={props.contact.firstname}
                  onBlur={(event) => handleFormChange(event)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="div" color="textSecondary" className="mb-1">
                  Email
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  name="email"
                  defaultValue={props.contact.email}
                  onBlur={(event) => handleFormChange(event)}
                  // required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="div" color="textSecondary" className="mb-1">
                  Phone
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                  type="number"
                  name="phone"
                  defaultValue={props.contact.phone}
                  onBlur={(event) => handleFormChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="div" color="textSecondary" className="mb-1">
                  Address
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  multiline={true}
                  rows={2}
                  rowsMax={4}
                  name="address"
                  defaultValue={props.contact.address}
                  onBlur={(event) => handleFormChange(event)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" className="mr-1 mb-2" onClick={props.closeEditorModal}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" className="mr-3 mb-2">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isProcessing: state.Contact.isProcessing,
  success: state.Contact.success,
  error: state.Contact.error,
  contact: state.Contact.contact,
});

const mapDispatchToProps = {
  typing: (payload) => typing(payload),
  save: () => save(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditDialog);
