import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from './actions/auth';
import { CONFIG } from './config.js';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Avatar,
  Backdrop,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import Sidebar from './components/Sidebar.js';
import Routes from './components/Routes';
// import logo from './pp-logo-white.png';
import logo from './compeer-no-bg.png';
// import logoIcon from './icon.png';
import logoIcon from './compeer-icon-only.png';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingLeft: 10,
    paddingRight: 24,
    backgroundColor: '#FFFF',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: '#F0EEFA',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(3),
    maxWidth: '100%',
    backgroundColor: '#f4f5f7',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(9),
    },
  },
  containerNoPadding: {
    padding: '0 !important',
    maxWidth: '99%',
    backgroundColor: '#f4f5f7',
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '50px !important',
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  avatar: {
    fontWeight: 959,
    paddingBottom: '2px',
    backgroundColor: '#82c34c !important',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));

const App = (props) => {
  useEffect(() => {
    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);
  }, []);

  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [backdrop, setBackDrop] = useState(false);
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (screenWidth <= 959) {
      setBackDrop(open);
    }
  }, [open, screenWidth]);

  useEffect(() => {
    setOpen(screenWidth >= 960);
  }, [screenWidth]);

  const handleScreenResize = () => {
    setScreenWidth(window.screen.width);
  };

  if (
    !props.isLoggedIn ||
    ['/reports/pdf', '/test-inquiry-to-stg', '/test-inquiry-to-dev', '/404'].indexOf(
      props.location.pathname
    ) !== -1
  ) {
    return <Routes />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <AppBarContent
          {...props}
          open={open}
          toggleDrawer={toggleDrawer}
          screenWidth={screenWidth}
        />
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
      >
        <div style={{ flexGrow: 1 }}>
          <Sidebar open={open} />
        </div>
        <Typography align="center">Build v1.6.8</Typography>
      </Drawer >
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          className={
            props.location.pathname === '/deals' ? classes.containerNoPadding : classes.container
          }
        >
          {backdrop && <Backdrop className={classes.backdrop} open={true}></Backdrop>}
          <Routes />
        </Container>
      </main>
    </div >
  );
};

const AppBarContent = (props) => {
  const classes = useStyles();
  const { user, open, screenWidth, toggleDrawer, logout, history } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <div>
        <img
          alt=""
          src={logo}
          width="190"
          className={open && screenWidth > 599 ? 'd-block mr-4' : 'd-none'}
        />
        <img
          style={{ width: 41, height: 43 }}
          alt=""
          src={logoIcon}
          width="40"
          className={!open || screenWidth < 599 ? 'd-block mr-4' : 'd-none'}
        />
      </div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        className={'nav-toggle text-dark ' + classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      ></Typography>
      {user.avatar ? (
        <Avatar alt="avatar" src={`${CONFIG.API_STORAGE_URL}${user.avatar}`} />
      ) : (
        <Avatar className={classes.avatar}>{user.firstname.charAt(0)}</Avatar>
      )}
      <div className="btn-group">
        <button
          type="button"
          className="btn header-user-menu dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="">{user.firstname}</span>
        </button>
        <div className="dropdown-menu dropdown-menu-right nav-user-dropdown border-0">
          <button className="dropdown-item" type="button" onClick={() => history.push('/settings')}>
            <i className="far fa-user mr-2"></i>
            My Account
          </button>
          <button className="dropdown-item" type="button" onClick={() => logout(props.history)}>
            <i className="fas fa-sign-out-alt mr-2"></i>
            Logout
          </button>
        </div>
      </div>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  logout: (history) => logout(history),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
