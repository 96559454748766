import React from 'react';
import { connect } from 'react-redux';
import {Bar} from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  paper: {
		padding: '20px',
		border: '1px solid #e2e0e0',
	},
});

const legendOpts = {
  display: false,
  position: 'bottom',
  fullWidth: true,
  reverse: false,
  labels: {
    fontColor: 'rgb(77, 77, 77)'
	}
};

const options = {
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero: true
			}
		}],
	},
	plugins: {
		datalabels: {
			display: true,
			color: '#3B6589',
			anchor: 'end',
			font: {
				size: 9,
				weight: 700,
			},
			formatter: function(value, context) {
				return value > 0 ? `${value}\n` : '';
			}
		}
	}
};

const ReportDealsByStageChart = (props) => {

	const classes = useStyles();

	if(props.deals_by_stage.length === 0) {
		return null;
	}

	const data = {
		labels: getLabels(props.stages),
		datasets: [
			{
				label: 'Count',
				maxBarThickness: 60,
				backgroundColor: 'rgb(38, 84, 124, 0.9)',
				borderColor: 'rgb(38, 84, 124)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(38, 84, 124,0.7)',
				hoverBorderColor: 'rgba(38, 84, 124)',
				data: getData(props.stages, props.deals_by_stage)
			}
		]
	};

	return (
			<Paper className={classes.paper}>
			<Grid container spacing={2} id="bar_chart_by_stage">
				<Grid item xs={12}>
					<Typography variant="h6">
						Deals By Stage
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Bar data={data} legend={legendOpts} options={options} redraw/>
				</Grid>
			</Grid>
		</Paper>
	)
}

const getLabels = (stages) => {

	const labels = [];

	for(let i in stages) {
		let name = stages[i].name ? stages[i].name : stages[i].stage.name;
		labels.push(name);
	}

	return labels;
}

const getData = (stages, stat) => {

	let data = [];
	let max_cnt = 0;

	for(let i in stages) {
		let temp = stat.filter(item => item.stage_id === stages[i].stage_id);
		let cnt = temp.length === 0 ? 0 : temp[0].cnt;
		data.push(cnt);
		max_cnt = max_cnt > cnt ? max_cnt : cnt;
	}

	options.scales.yAxes[0].ticks.max = max_cnt + (max_cnt*0.1);

	return data;

}

const mapStateToProps = state => ({
	deals_by_stage: state.Report.deals_by_stage,
	stages: state.Stage.stages
});

export default connect(
  mapStateToProps
)(ReportDealsByStageChart);
