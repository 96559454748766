/* global global */
import { createMachine, assign } from 'xstate';
import moment from 'moment';
// import Enums from '../enums';

const fetchState = {
  process: {
    invoke: {
      src: 'fetching',
      onDone: {
        target: 'success',
        actions: ['setList', 'makeBoard'],
      },
      onError: 'failure',
    },
  },
  success: {
    always: {
      target: '#Deal.idle',
    },
    on: {
      CLOSE: '#Deal.idle',
    },
  },
  failure: {
    always: {
      target: '#Deal.idle',
    },
    on: {
      CLOSE: '#Deal.idle',
    },
  },
};

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'Deal',
    initial: 'idle',
    context: {
      selectedCard: null,
      selectedData: null,
      response: null,
      list: [],
      stages: [],
      board: {
        lanes: [],
      },
      transfered: null,
      errors: null,
      transferToRemove: null,
      transferDetail: null,
    },
    on: {
      FILTER_CARDS: {
        target: 'fetchBg.process',
        actions: ['setStages'],
      },
      FETCH_BG: {
        target: 'fetchBg.process',
        actions: ['setStages'],
      },
      CHANGE_LANE: {
        target: 'board.changeLane.process',
        actions: ['changeBoardLane'],
      },
      CHANGE_CARD_POSITION: {
        target: 'board.changeCardPosition.process',
        actions: ['changeBoardCardPosition'],
      },
      REMOVE_LANE_POSITION: 'board.removeLane.process',
      REMOVE_CARD: {
        target: 'board.card.remove.show',
        actions: ['setCurrentCard'],
      },
      TRANSFER: 'transfer.show',
      TRANSFER_REMOVE: {
        target: 'transfer.remove',
        actions: ['setTransferToRemove'],
      },
      TRANSFER_EDIT: 'transfer.edit.fetch.process',
    },
    states: {
      idle: {
        on: {
          CREATE: 'create.show',
          FETCH: {
            target: 'fetch.process',
            actions: ['setStages'],
          },
          EDIT: 'edit.show',
        },
      },
      fetch: {
        states: fetchState,
      },
      fetchBg: {
        states: fetchState,
      },
      create: {
        states: {
          show: {
            on: {
              SAVE: 'process',
              CANCEL: '#Deal.idle',
            },
          },
          process: {
            invoke: {
              src: 'creating',
              onDone: {
                target: 'success',
                actions: ['reloadPage', 'addToList'],
              },
              onError: {
                target: 'failure',
                actions: ['setResponse'],
              },
            },
          },
          success: {
            on: {
              CLOSE: '#Deal.idle',
            },
          },
          failure: {
            on: {
              CLOSE: 'show',
            },
          },
        },
      },
      edit: {
        entry: ['setSelectedData'],
        states: {
          show: {
            on: {
              SAVE: 'process',
              CANCEL: '#Deal.idle',
            },
          },
          process: {
            invoke: {
              src: 'updating',
              onDone: {
                target: 'success',
                actions: ['updateList', 'reloadPage'],
              },
              onError: [
                { target: 'invalid', cond: 'isInvalid', actions: ['setResponse'] },
                { target: 'failure' },
              ],
            },
          },
          success: {
            on: {
              CLOSE: '#Deal.idle',
            },
          },
          failure: {
            on: {
              CLOSE: 'show',
            },
          },
          invalid: {
            on: {
              CLOSE: 'show',
            },
          },
        },
      },
      transfer: {
        states: {
          show: {
            on: {
              CANCEL: '#Deal.idle',
              CONFIRM: 'process',
            },
          },
          process: {
            invoke: {
              src: 'transfer',
              onDone: {
                target: 'success',
                actions: ['setTransfered'],
              },
              onError: {
                target: 'failure',
                actions: ['setFailure'],
              },
            },
          },
          success: {
            on: {
              CLOSE: {
                target: '#Deal.idle',
                actions: ['reloadPage'],
              },
            },
          },
          failure: {
            on: {
              CLOSE: 'show',
            },
          },

          remove: {
            initial: 'confirm',
            states: {
              confirm: {
                on: {
                  PROCEED: 'process',
                  CANCEL: {
                    target: '#Deal.idle',
                    actions: assign({
                      transferToRemove: null,
                    }),
                  },
                },
              },
              process: {
                invoke: {
                  src: 'removeTranfer',
                  onDone: 'success',
                  onError: 'failure',
                },
              },
              success: {
                on: {
                  CLOSE: {
                    target: '#Deal.idle',
                    actions: ['reloadPage'],
                  },
                },
              },
              failure: {
                on: {
                  RETRY: 'confirm',
                },
              },
            },
          },
          edit: {
            states: {
              fetch: {
                states: {
                  process: {
                    invoke: {
                      src: 'fetchingTransferDetail',
                      onDone: {
                        target: '#Deal.transfer.edit.show',
                        actions: ['setTransferDetail'],
                      },
                      onError: 'failure',
                    },
                  },
                  failure: {
                    on: {
                      CLOSE: '#Deal.idle',
                    },
                  },
                },
              },
              show: {
                on: {
                  CLOSE: '#Deal.idle',
                  SUBMIT: 'update.process',
                },
              },
              update: {
                states: {
                  process: {
                    invoke: {
                      src: 'transferUpdate',
                      onDone: 'success',
                      onError: {
                        target: 'failure',
                        actions: ['setFailure'],
                      },
                    },
                  },
                  success: {
                    on: {
                      CLOSE: {
                        target: '#Deal.idle',
                        actions: ['reloadPage'],
                      },
                    },
                  },
                  failure: {
                    on: {
                      CLOSE: '#Deal.transfer.edit.show',
                    },
                  },
                },
              },
            },
          },
        },
      },
      board: {
        states: {
          changeLane: {
            states: {
              process: {
                invoke: {
                  src: 'changingLane',
                  onDone: 'success',
                  onError: 'failure',
                },
              },
              success: {
                always: {
                  target: '#Deal.idle',
                },
              },
              failure: {},
            },
          },
          changeCardPosition: {
            states: {
              process: {
                invoke: {
                  src: 'changingCardPosition',
                  onDone: 'success',
                  onError: 'failure',
                },
              },
              success: {
                always: {
                  target: '#Deal.idle',
                },
              },
              failure: {},
            },
          },
          removeLane: {
            states: {
              process: {
                invoke: {
                  src: 'removingLane',
                  onDone: {
                    target: 'success',
                    actions: ['removeLane'],
                  },
                  onError: 'failure',
                },
              },
              success: {
                after: {
                  1000: {
                    target: '#Deal.idle',
                  },
                },
              },
              failure: {
                on: {
                  CLOSE: '#Deal.idle',
                },
              },
            },
          },
          card: {
            states: {
              remove: {
                states: {
                  show: {
                    on: {
                      CANCEL: '#Deal.idle',
                      CONFIRM: 'process',
                    },
                  },
                  process: {
                    invoke: {
                      src: 'cardRemoving',
                      onDone: 'success',
                      onError: 'failure',
                    },
                  },
                  success: {
                    after: {
                      100: {
                        target: '#Deal.idle',
                      },
                    },
                  },
                  failure: {
                    on: {
                      CLOSE: '#Deal.idle',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isInvalid(ctx, event) {
        const response = event.data.response;
        return response.status == 422;
      },
    },
    actions: {
      setTransferDetail: assign({
        transferDetail(ctx, event) {
          return event.data.data;
        },
      }),
      setTransferToRemove: assign({
        transferToRemove(ctx, event) {
          return event.data;
        },
      }),
      setFailure: assign((ctx, event) => {
        const response = event.data.response;
        return {
          ...ctx,
          errors: response.status === 422 ? response.data.errors : null,
        };
      }),
      setTransfered: assign({
        errors: null,
        transfered: (ctx, event) => {
          return event.data.data;
        },
      }),
      setCurrentCard: assign({
        selectedCard: (ctx, event) => event.data,
      }),
      removeLane: assign({
        board: (ctx, event) => {
          const board = { ...ctx.board };
          const lanes = [...board.lanes];
          const stageId = event.data;

          for (let i = 0; i < lanes.length; i++) {
            if (lanes[i].id == stageId) {
              lanes.splice(i, 1);
              break;
            }
          }
          return { ...board, lanes };
        },
      }),
      changeBoardCardPosition: assign({
        board: (ctx, event) => {
          const { source, destination } = event.data;
          const board = { ...ctx.board };
          const lanes = board.lanes;
          if (source.fromColumnId == destination.toColumnId) {
            // Swap position
            const laneId = source.fromColumnId;
            const lane = lanes.find((item) => item.id == laneId);
            const cards = [...lane.cards];
            const drag = { ...cards[source.fromPosition] };
            // const drop = {...cards[destination.toPosition]}
            cards.splice(source.fromPosition, 1);
            cards.splice(destination.toPosition, 0, drag);
            lane.cards = cards;
          } else {
            const sourseLane = lanes.find((item) => item.id == source.fromColumnId);
            const destinationLane = lanes.find((item) => item.id == destination.toColumnId);

            const sourceCard = sourseLane.cards.splice(source.fromPosition, 1);
            destinationLane.cards.splice(destination.toPosition, 0, {
              ...sourceCard[0],
              stage_id: destinationLane.id,
            });
          }
          board.lanes = lanes;
          return board;
        },
      }),
      changeBoardLane: assign({
        board: (ctx, event) => {
          const { source, destination } = event.data;
          const board = { ...ctx.board };
          const lanes = board.lanes;
          const sourceLane = { ...lanes[source.fromPosition] };
          // const toPosition = {...lanes[destination.toPosition]}

          if (destination.toPosition > source.fromPosition) {
            lanes.splice(destination.toPosition + 1, 0, { ...sourceLane });
            lanes.splice(source.fromPosition, 1);
          } else {
            lanes.splice(source.fromPosition, 1);
            lanes.splice(destination.toPosition, 0, { ...sourceLane });
          }
          board.lanes = lanes;
          return board;
        },
      }),
      setStages: assign({
        stages: (ctx, event) => event.data.stages,
      }),
      makeBoard: assign({
        board: (ctx, event) => {
          // console.log('ctx.stages', ctx.stages)
          const stages = [...ctx.stages];
          const deals = event.data.data;
          // console.log('deals', deals)
          const lanes = stages.map((item, index) => {
            // const { id, name, position, pivot } = item
            const { id, name, pivot } = item;
            const cards = deals
              .filter((item, index) => {
                return Boolean(item.stage) && item.stage.stage_id == id;
              })
              .map((item) => ({
                id: item.id,
                title: item.title,
                stage_id: item.stage.stage_id,
                contact_name: item.contact.firstname + ' ' + (item.contact.lastname || ''),
                contact_id: item.contact_id,
                type: item.type,
                value: item.value,
                balance: item.balance,
                lended: item.lended,
                date: moment(item.date).format('MMMM DD, YYYY'),
                position: item.stage.position,
              }));
            return {
              id,
              venue_stage_id: pivot.id,
              title: pivot.name || name,
              position: pivot.position || index + 1,
              color: null,
              cards,
            };
          });
          return {
            lanes,
          };
        },
      }),
      setSelectedData: assign({
        selectedData: (ctx, event) => {
          const list = [...ctx.list];
          let data = {};
          for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (event.data == item.id) {
              data = { ...item };
              break;
            }
          }
          return data;
        },
      }),
      updateList: assign({
        list: (ctx, event) => {
          const list = [...ctx.list];
          const data = event.data.data;
          for (let i = 0; i < list.length; i++) {
            if (list[i].id == data.id) {
              list[i] = { ...data };
              break;
            }
          }
          return list;
        },
      }),
      addToList: assign({
        list: (ctx, event) => [event.data.data, ...ctx.list],
      }),
      reloadPage: (ctx, event) => {
        window.location.reload(true);
      },
      setList: assign({
        list: (ctx, event) => {
          const stages = [...ctx.stages];
          let deals = event.data.data;

          deals = deals.map((item) => {
            const stage = stages.find((s) => s.id == item.stage.stage_id);
            const hasStage = Boolean(stage);
            return {
              ...item,
              contact_name: item.contact.firstname + ' ' + (item.contact.lastname || ''),
              stage_name: hasStage ? stage.pivot.name || stage.name : '(not assigned)',
            };
          });

          return deals;
        },
      }),
      setResponse: assign({
        response: (ctx, event) => event.data.response,
      }),
      // filterCard: assign({
      //   board(ctx, event) {
      //     const { data: type } = event;
      //     let lanes = [...ctx.board.lanes];
      //     if (type === Enums.Deal.BOARD_TYPE.INVEST) {
      //       lanes = lanes.map((column, index) => {
      //         let cards = [...column.cards];
      //         if (cards.length > 0) {
      //           cards = cards.filter((card) => card.type === Enums.Deal.TYPE.INVEST);
      //         }
      //         return {
      //           ...column,
      //           cards,
      //           updated: true,
      //         };
      //       });
      //     } else if (type === Enums.Deal.BOARD_TYPE.LOAN) {
      //       lanes = lanes.map((column, index) => {
      //         let cards = [...column.cards];
      //         if (cards.length > 0) {
      //           cards = cards.filter((card) => card.type === Enums.Deal.TYPE.BORROW);
      //         }
      //         return {
      //           ...column,
      //           cards,
      //           updated: true,
      //         };
      //       });
      //     }
      //     return {
      //       ...ctx.board,
      //       lanes,
      //     };
      //   },
      // }),
    },
    services: {
      removeTranfer(ctx, event) {
        return global.axios.get('/v2/deals/transfer/remove', { params: { id: event.data.id } });
      },
      cardRemoving(ctx, event) {
        return global.axios.post('/v2/deals/venue/remove-card', { dealId: ctx.selectedCard.id });
      },
      removingLane(ctx, event) {
        return global.axios
          .post('/v2/deals/venue/remove-lane', { stage: event.data })
          .then((response) => {
            return event.data;
          });
      },
      changingCardPosition(ctx, event) {
        const lanes = [...ctx.board.lanes];
        const { source, destination } = event.data;
        let sourceLane = null;
        let dealId = null;
        let stageId = null;
        let insertAfter = null;

        if (source.fromColumnId == destination.toColumnId) {
          const laneId = source.fromColumnId;
          sourceLane = lanes.find((item) => item.id == laneId);
        } else {
          sourceLane = lanes.find((item) => item.id == destination.toColumnId);
        }
        const cards = [...sourceLane.cards];
        const card = cards[destination.toPosition];
        const beforeCard = cards[destination.toPosition - 1] || {};

        dealId = card.id;
        stageId = card.stage_id;
        insertAfter = beforeCard.id || '';
        return global.axios.post('/v2/deals/venue/change-card-position', {
          dealId,
          stageId,
          insertAfter,
        });
      },
      changingLane(ctx, event) {
        const { destination } = event.data;
        const lanes = [...ctx.board.lanes];
        let venueStageId = null;
        let afterVenueStageId = null;

        venueStageId = lanes[destination.toPosition].venue_stage_id;
        afterVenueStageId = (lanes[destination.toPosition - 1] || { venue_stage_id: '' })
          .venue_stage_id;
        return global.axios.post('/v2/deals/venue/change-lanes', {
          venueStageId,
          afterVenueStageId,
        });
      },
      fetching(ctx, event) {
        const { board = '' } = event.data;
        const params = {};
        if (board) {
          params.board = board;
        }
        return global.axios.get('/v2/deals', { params });
      },
      creating(ctx, event) {
        const payload = event.data;
        return global.axios.post('/v2/deals/create', payload);
      },
      updating(ctx, event) {
        return global.axios.post('/v2/deals/update', { ...event.data, id: ctx.selectedData.id });
      },
      transfer(ctx, event) {
        return global.axios.post('/v2/deals/transfer', event.data);
      },
      fetchingTransferDetail(ctx, event) {
        return global.axios.get('/v2/deals/transfer/show', { params: { id: event.data } });
      },
      transferUpdate(ctx, event) {
        const data = event.data;
        const payload = data;
        return global.axios.post('/v2/deals/transfer/update?id=' + data.id, payload);
      },
    },
  }
);
