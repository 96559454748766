import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       // Some CSS
  //       border: '1px solid #6e768e',
  //       backgroundColor: '#f9f5f5',
  //       boxShadow: 'none',
  //       '&:hover': {
  //         boxShadow: 'none',
  //         backgroundColor: '#f0eefa',
  //       },
  //     },
  //     // Name of the rule
  //     label: {
  //       // color: 'red'
  //       fontFamily: 500
  //     },
  //     containedPrimary: {
  //       // color: ''
  //     }
  //   },
  // },
  typography: {
    color: '#323a46',
    htmlFontSize: 12,
    fontSize: 10,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    type: 'light',
    action: {
      main: '#98A6AD',
    },
    error: {
      main: '#dc004e',
    },
  },
});

export { theme };
