import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
  loadProfile,
  saveProfile,
  saveAvatar,
  typingProfile,
  setProfile,
  setErrors,
} from '../actions/setting';
import { CONFIG } from '../config.js';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Avatar, Button, TextField, InputLabel } from '@material-ui/core';
import {
  ErrorOutlineRounded as ErrorOutlineRoundedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  PhotoCamera,
} from '@material-ui/icons';
import PageHeader from '../components/PageHeader.js';
import AlertFormError from '../components/AlertFormError';
import SnackbarSuccess from '../components/SnackbarSuccess';
import { isObjectEmpty } from '../helpers/GeneralFunctions';
// Context
import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';
// Components
import Alerts from '../components/Alerts';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '35px',
    paddingBottom: '60px',
  },
  paper2: {
    padding: '36px',
    paddingTop: '50px;',
  },
  avatar: {
    padding: '65px',
    fontSize: '40px',
    fontWeight: 600,
    backgroundColor: '#82c34c',
  },
  avatarImg: {
    width: '140px',
    height: '140px',
  },
  textfield: {
    marginBottom: '10px',
  },
  ErrorText: {
    color: '#f50057',
    marginBottom: '10px',
  },
  ErrorIcon: {
    paddingBottom: '4px',
    marginRight: '3px',
  },
  InputError: {
    borderColor: '#f50057',
  },
  GroupLabel: {
    color: '#636669fa',
    fontWeight: 500,
  },
}));

const Settings = (props) => {
  const Services = useContext(GlobalState);
  const SettingState = useActor(Services.SettingState);
  const classes = useStyles();
  const [snackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const failedResponse = SettingState[0].context.failedResponse || {};

  useEffect(() => {
    props.loadProfile();
    props.setErrors({ data: { errors: {} } });
  }, []);

  useEffect(() => {
    SettingState[1]({ type: 'SET_PROFILE', data: props.profile });
  }, [props.profile.id]);

  //  const openSnackbar = () => {
  //    setSnackbarOpen(true);
  // }
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleFormChange = (event) => {
    props.typingProfile({ [event.target.name]: event.target.value });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    SettingState[1]('SAVE_AVATAR');
  };

  // const saveProfile = async () => {
  // 	let success = await props.saveProfile();
  // 	if(success) {
  // 		setSnackbarMessage("Successfully saved.");
  // 		openSnackbar();
  // 	}
  // }
  useEffect(() => {
    return () => {
      SettingState[1]('IDLE');
    };
  }, []);
  useEffect(() => {
    if (SettingState[0].matches('avatar.edit.save.success')) {
      // saveProfile()
      const { venue, avatar, ...profile } = props.profile;
      SettingState[1]({ type: 'SAVE_PROFILE', data: profile });
    }
    if (SettingState[0].matches('profile.edit.save.success')) {
      const profile = SettingState[0].context.profile;
      const response = SettingState[0].context.response;
      const user = { ...response.user, avatar: profile.avatar };
      props.setProfile({ ...response, user });
      props.setErrors({ data: { errors: {} } });
    }
    if (SettingState[0].matches('profile.edit.save.failure')) {
      props.setErrors(failedResponse);
    }
  }, [SettingState[0]]);

  return (
    <Grid container spacing={3}>
      <Alerts.Loading
        open={SettingState[0].matches('avatar.edit.save.process')}
        message={'Saving avatar...'}
      />
      <Alerts.Loading
        open={SettingState[0].matches('profile.edit.save.process')}
        message={'Saving profile...'}
      />
      <Alerts.Failure
        open={SettingState[0].matches('avatar.edit.save.failure')}
        message={'Failed to save avatar!'}
        onClose={() => SettingState[1]('IDLE')}
      />
      <Alerts.Failure
        open={SettingState[0].matches('profile.edit.save.failure')}
        message={'Failed to save profile!'}
        onClose={() => SettingState[1]('IDLE')}
      />
      <Alerts.Success
        open={SettingState[0].matches('profile.edit.save.success')}
        message={'Profile successfully save!'}
        onClose={() => SettingState[1]('IDLE')}
      />
      <Grid item xs={12}>
        <PageHeader headerTitle="Settings" icon={<SettingsOutlinedIcon />} />
      </Grid>
      <Grid item xl={6} lg={8} xs={12}>
        <Paper className={classes.paper}>
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" component="div" className={classes.GroupLabel}>
                  Update Account Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {!isObjectEmpty(props.errors) && <AlertFormError errors={props.errors} />}
                {snackbarOpen && (
                  <SnackbarSuccess message={snackbarMessage} handleClose={() => closeSnackbar()} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AvatarEditor {...props} state={SettingState} />
                </Grid>
                <Grid item xs={12}>
                  <VenueInformation
                    {...props}
                    handleFormChange={(event) => handleFormChange(event)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserInformation
                    {...props}
                    handleFormChange={(event) => handleFormChange(event)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword
                    {...props}
                    handleFormChange={(event) => handleFormChange(event)}
                  />
                </Grid>
                <Grid item xs={12} className="text-right">
                  <Button type="submit" variant="contained" color="primary">
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

const AvatarEditor = ({ state, ...props }) => {
  const classes = useStyles();
  const profile = state[0].context.profile || {};

  const handleFileUpload = (event) => {
    state[1]({
      type: 'SET_AVATAR',
      data: {
        file: event.target.files[0],
      },
    });
    // if (event.target.files && event.target.files[0]) {
    // 	props.saveAvatar(event.target.files[0]);
    // }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          Avatar
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item xs={6}>
            {(() => {
              if (profile.avatarFile) {
                return <Avatar alt="avatar" src={profile.avatar} className={classes.avatarImg} />;
              }
              return props.profile.avatar ? (
                <Avatar
                  alt="avatar"
                  src={`${CONFIG.API_STORAGE_URL}${props.profile.avatar}`}
                  className={classes.avatarImg}
                />
              ) : (
                <Avatar className={classes.avatar}>{props.profile.firstname.charAt(0)}</Avatar>
              );
            })()}
          </Grid>
          <Grid item xs={6}>
            <input
              accept="image/*"
              className="d-none"
              id="outlined-button-file"
              type="file"
              onChange={(event) => handleFileUpload(event)}
            />
            <label htmlFor="outlined-button-file" className="mr-1">
              <Button
                className="ml-3"
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<PhotoCamera />}
              >
                Upload
              </Button>
            </label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const UserInformation = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          {/* User Information */}
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        <InputLabel shrink>Email</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          name="email"
          value={props.profile.email}
          disabled
        />
        <InputLabel shrink>First Name</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          name="firstname"
          value={props.profile.firstname}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'firstname')}
        />
        <InputLabel shrink>Last Name</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          name="lastname"
          value={props.profile.lastname}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'lastname')}
        />
      </Grid>
    </Grid>
  );
};

const VenueInformation = (props) => {
  const classes = useStyles();

  if (props.profile.role !== 'venue') {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          User Information
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        <InputLabel shrink>Name</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          name="name"
          value={props.profile.name}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'name')}
        />
        <InputLabel shrink>Address</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          className={classes.textfield}
          name="address"
          value={props.profile.address}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'address')}
        />
      </Grid>
    </Grid>
  );
};

const ChangePassword = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" component="div" className={classes.GroupLabel}>
          Change your password
        </Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        {!props.error ? null : (
          <Typography variant="body2" component="div" className={classes.ErrorText}>
            <ErrorOutlineRoundedIcon color="secondary" className={classes.ErrorIcon} />
            Please make sure your password match.
          </Typography>
        )}

        <InputLabel shrink>New Password</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          type="password"
          className={classes.textfield}
          name="password"
          value={props.profile.password}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
        />
        <InputLabel shrink>Re-enter Your New Password</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          type="password"
          className={classes.textfield}
          name="password_confirmation"
          value={props.profile.password_confirmation}
          onChange={(event) => props.handleFormChange(event)}
          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Setting.profile,
  errors: state.Setting.errors,
});

const mapDispatchToProps = {
  loadProfile: () => loadProfile(),
  saveProfile: () => saveProfile(),
  setProfile: (payload) => setProfile(payload),
  saveAvatar: (payload) => saveAvatar(payload),
  setErrors: (payload) => setErrors(payload),
  typingProfile: (payload) => typingProfile(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
