import React from 'react';
import PropTypes from 'prop-types';
// Material
import { default as MUIDialog } from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    ['& .MuiPaper-root']: {
      maxWidth: 750,
    },
  },
  title: {
    backgroundColor: '#20324a',
    color: theme.palette.common.white,
  },
}));

function Dialog({ title, subHeading, open, footer, onClose, ...props }) {
  const classes = useStyles();
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      className={classes.dialog}
    >
      <DialogTitle className={classes.title} id="dialog-title">
        <Typography variant="h6">{title}</Typography>
        {Boolean(subHeading) && <Typography variant="caption">{subHeading}</Typography>}
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </MUIDialog>
  );
}

Dialog.propTypes = {
  title: PropTypes.string || PropTypes.object,
  subHeading: PropTypes.string,
  open: PropTypes.bool,
  footer: PropTypes.object,
  onClose: PropTypes.func,
};
Dialog.defaultProps = {
  subHeading: '',
};

export default Dialog;
