import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataIcon from '../assets/images/icons/undraw_no_data_re_kwbl.svg';
import ServerDownIcon from '../assets/images/icons/undraw_server_down_s-4-lk.svg';

const Table = ({
  hideHeader,
  loading,
  error,
  onTryAgain,
  rowHeight,
  emptyMessage,
  headers,
  rows,
  perPage,
  page,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [items, setItems] = useState(rows);
  const emptyRows = perPage - Math.min(perPage, rows.length - currentPage * perPage);
  const handlePageChange = (even, newPage) => {
    setCurrentPage(newPage - 1);
  };
  useEffect(() => {
    setCurrentPage(page - 1);
  }, [page]);
  useEffect(() => {
    setItems(rows);
    setCurrentPage(page - 1);
  }, [rows]);
  return (
    <Fragment>
      <MUITable>
        {(() => {
          if (hideHeader) {
            return '';
          }

          return (
            <TableHead>
              <TableRow>
                {headers.map((cell, index) => {
                  const style = {
                    ...cell.style,
                  };
                  if (cell.width) {
                    style.width = cell.width;
                  }
                  return (
                    <TableCell
                      key={'head-' + index}
                      align={cell.align}
                      className={cell.className}
                      style={style}
                    >
                      {cell.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          );
        })()}
        <TableBody>
          {(() => {
            if (loading) {
              return (
                <TableCell
                  align="center"
                  colSpan={headers.length}
                  style={{
                    height: rowHeight * perPage,
                  }}
                >
                  <Typography>
                    <CircularProgress />
                  </Typography>
                </TableCell>
              );
            }
            if (error) {
              return (
                <TableCell
                  align="center"
                  colSpan={headers.length}
                  style={{
                    height: rowHeight * perPage,
                  }}
                >
                  <Typography align="center">
                    <img style={{ width: 100 }} src={ServerDownIcon} />
                  </Typography>
                  <Typography align="center" color="secondary">
                    Failed to load.{' '}
                    <Button onClick={onTryAgain} color="secondary">
                      Try again
                    </Button>
                  </Typography>
                </TableCell>
              );
            }
            if (items.length === 0) {
              return (
                <TableCell
                  align="center"
                  colSpan={headers.length}
                  style={{
                    height: rowHeight * perPage,
                  }}
                >
                  <Typography align="center">
                    <img style={{ width: 100 }} src={NoDataIcon} />
                  </Typography>
                  <Typography>{emptyMessage}</Typography>
                </TableCell>
              );
            }
            return (
              <Fragment>
                {items
                  .slice(currentPage * perPage, currentPage * perPage + perPage)
                  .map((row, rowIndex) => {
                    return (
                      <TableRow key={'row-' + rowIndex}>
                        {headers.map((head, headIndex) => {
                          return (
                            <TableCell
                              align={head.align}
                              colSpan={row.span || ''}
                              key={'row-' + rowIndex + '-head-' + headIndex}
                            >
                              {(() => {
                                if (Object.prototype.hasOwnProperty.call(head, 'render')) {
                                  return head.render(row);
                                }

                                if (Object.prototype.hasOwnProperty.call(head, 'key')) {
                                  return row[head.key];
                                }

                                return row.value || '';
                              })()}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {(() => {
                  if (emptyRows > 0) {
                    return (
                      <TableRow style={{ height: rowHeight * emptyRows }}>
                        <TableCell colSpan={headers.length} />
                      </TableRow>
                    );
                  }
                })()}
              </Fragment>
            );
          })()}
        </TableBody>
        {(() => {
          if (rows.length > 0 && !error && !loading) {
            return (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={headers.length} align="right">
                    <Pagination
                      style={{ float: 'right' }}
                      color="primary"
                      count={Math.ceil(rows.length / perPage)}
                      page={currentPage + 1}
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            );
          }
        })()}
      </MUITable>
    </Fragment>
  );
};
Table.propTypes = {
  hideHeader: PropTypes.bool,
  error: PropTypes.bool,
  onTryAgain: PropTypes.func,
  loading: PropTypes.bool,
  rowHeight: PropTypes.number,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      align: PropTypes.oneOf(['left', 'right', 'center']),
      width: PropTypes.number,
      style: PropTypes.object,
      className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
      key: PropTypes.string,
      render: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      span: PropTypes.number,
    })
  ),
  perPage: PropTypes.number,
  page: PropTypes.number,
};
Table.defaultProps = {
  hideHeader: false,
  error: false,
  onTryAgain: () => {},
  loading: false,
  rowHeight: 32,
  emptyMessage: 'Empty rows.',
  rows: [],
  perPage: 10,
  page: 1,
};

export default Table;
