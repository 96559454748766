import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// Material
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import Link from '@material-ui/core/Link';
// import InputLabel from '@material-ui/core/InputLabel';
// import Typography from '@material-ui/core/Typography';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Icons
import AddIcon from '@material-ui/icons/Add';
// Components
import Dialog from '../components/Dialog';
import Inputs from '../components/Inputs';
// Validator
import Localization from '../localization';
import { useFormik } from 'formik';
import yup from '../helpers/yup';
// import * as yup from 'yup';

import Enums from '../enums';
// import { Input } from '@material-ui/core';

const DEFAULT_VALUES = {
  contact: '',
  title: '',
  date: '',
  value: '',
  type: '',
  lendfrom: '',
  investmentOption: '',
  bankaccountOption: '',
  ebxBankAccount: '',
  description: '',
  inputRef: '',
  deal_from: '',
};

// const INVESTMENT_TYPE = [Enums.Deal.TYPE.LOAN, Enums.Deal.TYPE.INVEST];
// const INVESTMENT_OPTION_TYPE = [
//   Enums.Deal.INVESTMENT_OPTION_TYPE.SMSF,
//   Enums.Deal.INVESTMENT_OPTION_TYPE.NON_SMSF,
// ];
const BANKACCOUNT_OPTION_TYPE = [
  Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_1,
  Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_2,
];
const EBX = {
  [BANKACCOUNT_OPTION_TYPE[0]]: Enums.Deal.EBX.EBX_1,
  [BANKACCOUNT_OPTION_TYPE[1]]: Enums.Deal.EBX.EBX_2,
};

function DealBoardCreateDialog({
  errors,
  contacts,
  deals,
  lenders,
  open,
  reset,
  onSave,
  onCancel,
  onCreateContact,
  submitting,
}) {
  // let [lendersOption, setLendersOption] = useState([]);
  let [transferDealOption, setTransferDealOption] = useState([]);
  let [excludedInvestments, setExcludedInvestments] = useState([]); // eslint-disable-line no-unused-vars
  const formik = useFormik({
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: yup.object({
      contact: yup.object({
        value: yup
          .number()
          .required(Localization.formatString(Localization.formik.required, 'contact')),
      }),
      title: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'title')),
      date: yup
        .mixed()
        .test(
          'non-string',
          Localization.formatString(Localization.formik.notValiDate, 'date'),
          (text) => moment(text).isValid()
        )
        .required(Localization.formatString(Localization.formik.required, 'date')),
      value: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'value'))
        .decimal(Localization.formatString(Localization.formik.decimal, 'value')),
      type: yup.string().required(Localization.formatString(Localization.formik.required, 'type')),
      // lendfrom: yup.string().when('type', {
      //   is: Enums.Deal.TYPE.LOAN,
      //   then: (schema) =>
      //     schema.required(Localization.formatString(Localization.formik.required, 'invest from')),
      // }),
      investmentOption: yup.string().when('type', {
        is: (type) => {
          return [Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER].includes(type);
        },
        then: (schema) =>
          schema.required(
            Localization.formatString(Localization.formik.required, 'investment option')
          ),
      }),
      inputRef: yup.string().when('type', {
        is: (type) => {
          return [Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER, Enums.Deal.TYPE.LOAN].includes(
            type
          );
        },
        then: (schema) =>
          schema.required(
            Localization.formatString(Localization.formik.required, 'input reference')
          ),
      }),
      bankaccountOption: yup.string().when('type', {
        is: (type) => {
          return [Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER].includes(type);
        },
        then: (schema) =>
          schema.required(
            Localization.formatString(Localization.formik.required, 'bank account option')
          ),
      }),
      ebxBankAccount: yup.string().when('type', {
        is: Enums.Deal.TYPE.LOAN,
        then: (schema) =>
          schema.required(
            Localization.formatString(Localization.formik.required, 'EBX bank account option')
          ),
      }),
      description: yup
        .string()
        .required(Localization.formatString(Localization.formik.required, 'description')),
      deal_from: yup.object({
        value: yup.number().when('$type', (_type, schema) => {
          return [Enums.Deal.TYPE.TRANSFER, Enums.Deal.TYPE.LOAN].includes(formik.values.type)
            ? schema.required(
                Localization.formatString(Localization.formik.required, 'source deal')
              )
            : schema;
        }),
      }),
    }),

    onSubmit(values) {
      formik.setSubmitting(false);
      onSave({ ...values, value: values.value.replaceAll(',', '') });
    },
  });
  const handleOnClose = () => {
    formik.resetForm({
      values: { ...DEFAULT_VALUES },
    });
    onCancel();
  };
  const handleValueChange = (e) => {
    const value = e.currentTarget.value;
    const formatted = Number(value.replaceAll(',', '')).toLocaleString();
    formik.setFieldValue('value', formatted === 'NaN' ? value : formatted);
  };
  const handleContactChange = (event) => {
    formik.handleChange(event);
    const value = event.target.value;
    if (value) {
      // const grouped = lenders.reduce(
      //   (acc, curr) => {
      //     if (curr.contact_id != value.value && curr.transfered_amount > 0) {
      //       acc.lenders.push(curr);
      //     } else {
      //       acc.excluded.push(curr);
      //     }
      //     return acc;
      //   },
      //   {
      //     lenders: [],
      //     excluded: [],
      //   }
      // );
      // setLendersOption(grouped.lenders);
      // setExcludedInvestments(grouped.excluded);
      // console.log('grouped', grouped)x
      // setLendersOption(
      //   lenders.filter((item) => item.contact_id != value.value && item.transfered_amount > 0)
      // );
      formik.setFieldValue('lendfrom', '');
    }
  };
  const handleTypeChange = (event) => {
    formik.handleChange(event);
    // let _lenders = [];
    if ([Enums.Deal.TYPE.LOAN, Enums.Deal.TYPE.BORROW].includes(event.target.value)) {
      const contact = formik.values.contact;
      if (contact) {
        // _lenders = lenders.filter((item) => {
        //   const notOwner = item.contact_id != formik.values.contact.value;
        //
        //   if (!notOwner) {
        //     return false;
        //   }
        //   if (contact.hasSmsf) {
        //     return !item.contact.has_smsf;
        //   }
        //
        //   return true;
        // });
      }
    }
    // setLendersOption(_lenders);
  };

  useEffect(() => {
    if (reset) {
      formik.resetForm({
        values: { ...DEFAULT_VALUES },
      });
    }
  }, [reset]);
  useEffect(() => {
    const fields = Object.keys(errors);
    if (fields.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        formik.setFieldError(field, errors[field][0]);
      }
    }
  }, [errors]);

  useEffect(() => {
    if ([Enums.Deal.TYPE.TRANSFER, Enums.Deal.TYPE.LOAN].includes(formik.values.type)) {
      let td = lenders.filter((deal) => deal.contact.id === formik.values.contact?.value);
      formik.setFieldValue('deal_from', '');
      setTransferDealOption(td);
    }
  }, [formik.values.type, formik.values.contact]);
  useEffect(() => {
    if (formik.values.type === Enums.Deal.TYPE.TRANSFER) {
      const dealFrom = formik.values.deal_from;
      const hasDeal = Boolean(dealFrom);
      if (hasDeal) {
        formik.setFieldValue('investmentOption', dealFrom.investment_option);
      }
    }
  }, [formik.values.deal_from]);
  return (
    <Dialog
      title={
        [Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER].includes(formik.values.type)
          ? 'Create New BWHF Deal'
          : 'Deals EBX'
      }
      open={open}
      onClose={onCancel}
      footer={
        <>
          <Button disabled={submitting} color="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            disabled={submitting}
            color="primary"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Inputs.AutoComplete
            label="Contact"
            options={contacts.map((item) => ({
              value: item.id,
              hasSmsf: item.has_smsf,
              label: `${item.firstname} ${item.lastname || ''}`,
            }))}
            name="contact"
            value={formik.values.contact}
            onChange={handleContactChange}
            error={formik.touched.contact && Boolean((formik.errors.contact || {}).value)}
            helperText={
              formik.touched.contact &&
              Boolean((formik.errors.contact || {}).value) &&
              (formik.errors.contact || {}).value
            }
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
          <Tooltip title="Create contact">
            <Button variant="contained" color="primary" onClick={onCreateContact}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          {/*<Inputs.Text
            label="Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && Boolean(formik.errors.title) && formik.errors.title}
          />*/}
          <Inputs.Select
            label="Title"
            options={[{ label: 'Select', value: '' }, 'Investor', 'Borrower']}
            name="title"
            value={formik.values.title}
            onChange={handleTypeChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && Boolean(formik.errors.title) && formik.errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <Inputs.Select
            label="Transaction type"
            options={[
              { label: 'Select', value: '' },
              { label: 'Investment', value: Enums.Deal.TYPE.INVEST },
              { label: 'Loan to borrower', value: Enums.Deal.TYPE.LOAN },
              Enums.Deal.TYPE.TRANSFER,
            ]}
            name="type"
            value={formik.values.type}
            onChange={handleTypeChange}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && Boolean(formik.errors.type) && formik.errors.type}
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.TextDate
            label="Date"
            name="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && Boolean(formik.errors.date) && formik.errors.date}
          />
        </Grid>
        <Grid item xs={6}>
          <Inputs.TextDollar
            label={
              formik.values.type === Enums.Deal.TYPE.INVEST
                ? 'Investment Amount'
                : formik.values.type === Enums.Deal.TYPE.TRANSFER
                ? 'Transfer to bank'
                : 'Loan Amount'
            }
            name="value"
            value={formik.values.value}
            onChange={handleValueChange}
            error={formik.touched.value && Boolean(formik.errors.value)}
            helperText={formik.touched.value && Boolean(formik.errors.value) && formik.errors.value}
          />
        </Grid>
        {formik.values.type === Enums.Deal.TYPE.LOAN && (
          <Grid item xs={12}>
            <Inputs.AutoComplete
              placeholder="Select"
              label="Loan from"
              options={lenders.map((item) => ({
                value: item.id,
                investment_option: item.investment_option,
                label:
                  item.contact.firstname +
                  ' ' +
                  (item.contact.lastname || '') +
                  ' (' +
                  Number(item.loanable_amount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  }) +
                  ')',
              }))}
              name="deal_from"
              value={formik.values.deal_from}
              onChange={(event) => formik.handleChange(event)}
              error={formik.touched.deal_from && Boolean((formik.errors.deal_from || {}).value)}
              helperText={
                formik.touched.deal_from &&
                Boolean((formik.errors.deal_from || {}).value) &&
                (formik.errors.deal_from || {}).value
              }
            />
          </Grid>
        )}
        {(() => {
          if (!formik.values.type) {
            return '';
          }
          let bankOption = [
            Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_1,
            Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_2,
          ];
          if (formik.values.investmentOption === Enums.Deal.INVESTMENT_OPTION_TYPE.SMSF) {
            bankOption = [Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_2];
          }
          if ([Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER].includes(formik.values.type)) {
            let bankOpt = [{ label: 'Select', value: '' }, ...bankOption];
            if (formik.values.type === Enums.Deal.TYPE.TRANSFER) {
              bankOpt = [
                { label: 'Select', value: '' },
                { label: Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_1, value: Enums.Deal.EBX.EBX_1 },
                { label: Enums.Deal.BANKACCOUNT_OPTION_TYPE.SPV_2, value: Enums.Deal.EBX.EBX_2 },
              ];
              if (formik.values.investmentOption === Enums.Deal.INVESTMENT_OPTION_TYPE.SMSF) {
                bankOpt = [bankOpt[0], bankOpt[2]];
              }
            }
            return (
              <Fragment>
                {formik.values.type === Enums.Deal.TYPE.TRANSFER && (
                  <Grid item xs={12}>
                    <Inputs.AutoComplete
                      placeholder="Select"
                      label="Deal from"
                      options={transferDealOption.map((item) => ({
                        value: item.id,
                        investment_option: item.investment_option,
                        label: `${item.title} $${Number(item.value).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })} (${item.input_ref})`,
                      }))}
                      name="deal_from"
                      value={formik.values.deal_from}
                      onChange={(event) => formik.handleChange(event)}
                      error={
                        formik.touched.deal_from && Boolean((formik.errors.deal_from || {}).value)
                      }
                      helperText={
                        formik.touched.deal_from &&
                        Boolean((formik.errors.deal_from || {}).value) &&
                        (formik.errors.deal_from || {}).value
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Inputs.Text
                    label="Input Ref #"
                    name="inputRef"
                    value={formik.values.inputRef}
                    onChange={formik.handleChange}
                    error={formik.touched.inputRef && Boolean(formik.errors.inputRef)}
                    helperText={
                      formik.touched.inputRef &&
                      Boolean(formik.errors.inputRef) &&
                      formik.errors.inputRef
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Inputs.Select
                    disabled={formik.values.type === Enums.Deal.TYPE.TRANSFER}
                    label="Investment Option"
                    options={[
                      { label: 'Select', value: '' },
                      Enums.Deal.INVESTMENT_OPTION_TYPE.SMSF,
                      Enums.Deal.INVESTMENT_OPTION_TYPE.NON_SMSF,
                    ]}
                    name="investmentOption"
                    value={formik.values.investmentOption}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.investmentOption && Boolean(formik.errors.investmentOption)
                    }
                    helperText={
                      formik.touched.investmentOption &&
                      Boolean(formik.errors.investmentOption) &&
                      formik.errors.investmentOption
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Inputs.Select
                    label={
                      'Bank Account Option' +
                      (Object.prototype.hasOwnProperty.call(EBX, formik.values.bankaccountOption)
                        ? ' (' + EBX[formik.values.bankaccountOption] + ')'
                        : '')
                    }
                    options={bankOpt}
                    name="bankaccountOption"
                    value={formik.values.bankaccountOption}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankaccountOption && Boolean(formik.errors.bankaccountOption)
                    }
                    helperText={
                      formik.touched.bankaccountOption &&
                      Boolean(formik.errors.bankaccountOption) &&
                      formik.errors.bankaccountOption
                    }
                  />
                </Grid>
              </Fragment>
            );
          }
          const selectedContact = formik.values.contact;
          let ebxOptions = [];
          if (selectedContact) {
            if (selectedContact.hasSmsf) {
              ebxOptions = [Enums.Deal.EBX.EBX_1];
            } else {
              ebxOptions = Object.values(Enums.Deal.EBX);
            }
          }

          const loanFrom = formik.values.lendfrom;
          // let investor = null;
          if (loanFrom) {
            // investor = lendersOption.find((o) => o.id == loanFrom);
          }
          return (
            <Fragment>
              <Grid item xs={6}>
                <Inputs.Text
                  label="Input Ref #"
                  name="inputRef"
                  value={formik.values.inputRef}
                  onChange={formik.handleChange}
                  error={formik.touched.inputRef && Boolean(formik.errors.inputRef)}
                  helperText={
                    formik.touched.inputRef &&
                    Boolean(formik.errors.inputRef) &&
                    formik.errors.inputRef
                  }
                />
              </Grid>
              {/* <Grid item container xs={6}> */}
              {/*   <Grid item xs={12}> */}
              {/*     <Inputs.Select */}
              {/*       label={'Loan From'} */}
              {/*       options={[ */}
              {/*         { label: 'Select', value: '' }, */}
              {/*         ...lendersOption.map((item) => ({ value: item.id, label: item.title })), */}
              {/*       ]} */}
              {/*       name="lendfrom" */}
              {/*       value={formik.values.lendfrom} */}
              {/*       onChange={formik.handleChange} */}
              {/*       error={formik.touched.lendfrom && Boolean(formik.errors.lendfrom)} */}
              {/*       helperText={ */}
              {/*         formik.touched.lendfrom && */}
              {/*         Boolean(formik.errors.lendfrom) && */}
              {/*         formik.errors.lendfrom */}
              {/*       } */}
              {/*     /> */}
              {/*   </Grid> */}
              {/*   <Grid item xs={12}> */}
              {/*     {(() => { */}
              {/*       const hasLoanFrom = Boolean(loanFrom); */}
              {/*       if (hasLoanFrom) { */}
              {/*         const _investor = investor || {}; */}
              {/*         const amount = _investor.transfered_amount || 0; */}
              {/*         const name = _investor.contact_name || ''; */}
              {/*         return ( */}
              {/*           <Typography> */}
              {/*             ({name} ${amount}) */}
              {/*           </Typography> */}
              {/*         ); */}
              {/*       } */}
              {/*     })()} */}
              {/*   </Grid> */}
              {/*   <Grid item xs={12}> */}
              {/*     <Link href="#">Excluded investments</Link> */}
              {/*   </Grid> */}
              {/* </Grid> */}
              <Grid item xs={6}>
                <Inputs.Select
                  label="EBX Bank Account"
                  options={[{ label: 'Select', value: '' }, ...ebxOptions]}
                  name="ebxBankAccount"
                  value={formik.values.ebxBankAccount}
                  onChange={formik.handleChange}
                  error={formik.touched.ebxBankAccount && Boolean(formik.errors.ebxBankAccount)}
                  helperText={
                    formik.touched.ebxBankAccount &&
                    Boolean(formik.errors.ebxBankAccount) &&
                    formik.errors.ebxBankAccount
                  }
                />
              </Grid>
            </Fragment>
          );
        })()}
        <Grid item xs={12}>
          <Inputs.TextArea
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={
              formik.touched.description &&
              Boolean(formik.errors.description) &&
              formik.errors.description
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

DealBoardCreateDialog.propTypes = {
  errors: PropTypes.object,
  contacts: PropTypes.array,
  lenders: PropTypes.array,
  open: PropTypes.bool,
  reset: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
};
DealBoardCreateDialog.defaultProps = {
  errors: {},
  contacts: [],
  lenders: [],
  submitting: false,
};

export default DealBoardCreateDialog;
