import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {loadAll as loadVenues, clearForm} from '../actions/venue';
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, TextField, Fab} from '@material-ui/core';
import {Autocomplete, Alert} from '@material-ui/lab';
import {Add} from '@material-ui/icons';
import {isObjectEmpty} from '../helpers/GeneralFunctions';
import VenueEditDialog from './VenueEditDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AssignDealDialog = (props) => {

	const {toggleModal, deal, loadVenues, assignDeal, clearForm} = props;

	useEffect(() => {
		async function loadData() {
			let response = await loadVenues();
			if(response.length > 0) {
				setVenues(response);
				let temp_venue = response.filter(item => item.email === deal.venue_email)[0];
				setVenue(temp_venue);
			}
		}
		loadData();
	}, []);

	const [erroMsg, setErrorMsg] = useState('');
	const [venue, setVenue] = useState(null);
	const [venues, setVenues] = useState(null);
	const changeVenue = (event, venue) => {
		setErrorMsg('');
		setVenue(venue);
	}

	const handleAssign = async () => {
		if(isObjectEmpty(venue)) {
			setErrorMsg('Please select venue.');
		} else {
			assignDeal({id: deal.id, venue_id: venue.id});
		}
	}

	const [openVenue, setOpenVenue] = useState(false);
	const toggleVenueModal = () => {
		setOpenVenue(!openVenue);
	}

	const handleVenueSave = async (venue) => {
		let response = await loadVenues();
		setVenues(response);
		setVenue(venue);
		clearForm();
		toggleVenueModal();
	}

	return (
		<Dialog
			open={true}
			TransitionComponent={Transition}
			keepMounted
			onClose={toggleModal}
			maxWidth="sm"
			fullWidth
			disableBackdropClick={true}
		>
			<DialogTitle className="modal-header">
				Assign Deal to Venue
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} className="pt-3">
					{erroMsg && (
						<Grid item xs={12}>
							<Alert severity="error">{erroMsg}</Alert>
						</Grid>
					)}
					{openVenue && (
						<VenueEditDialog
							venueEmail={deal.venue_email}
							toggleModal={() => toggleVenueModal()}
							handleSaveSuccess={(venue) => handleVenueSave(venue)}
						/>
					)}
					<Grid item xs={11}>
						<Autocomplete
							options={venues}
							getOptionLabel={option => `${option.name} (${option.email})`}
							value={venue}
							onChange={changeVenue}
							renderInput={params => (
								<TextField
									{...params}
									fullWidth
									margin="dense"
									variant="outlined"
									label="Select Venue"
									value={venue ? `${venue.name} (${venue.email})` : ''}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={1}>
						<Fab size="small" color="primary" onClick={() => toggleVenueModal()}>
							<Add />
						</Fab>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" component="span" style={{fontWeight:500}}>
							Inquiry Details
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
						<Grid item sm={4} xs={12}>
								<Typography variant="body2" component="span">
									Date Created:
								</Typography>
							</Grid>
							<Grid item sm={8} xs={12}>
								<Typography variant="body2" component="span">
									{deal.created_at}
								</Typography>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Typography variant="body2" component="span">
									Title:
								</Typography>
							</Grid>
							<Grid item sm={8} xs={12}>
								<Typography variant="body2" component="span">
									{deal.title}
								</Typography>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Typography variant="body2" component="span">
									Venue Email:
								</Typography>
							</Grid>
							<Grid item sm={8} xs={12}>
								<Typography variant="body2" component="span">
									{deal.venue_email}
								</Typography>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Typography variant="body2" component="span">
									Contact Name:
								</Typography>
							</Grid>
							<Grid item sm={8} xs={12}>
								<Typography variant="body2" component="span">
									{deal.contact_name}
								</Typography>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Typography variant="body2" component="span">
									Contact Email:
								</Typography>
							</Grid>
							<Grid item sm={8} xs={12}>
								<Typography variant="body2" component="span">
									{deal.contact_email}
								</Typography>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Typography variant="body2" component="span">
									Notes:
								</Typography>
							</Grid>
							<Grid item sm={8} xs={12}>
								<Typography variant="body2" component="span">
									{deal.notes}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" size="medium" onClick={() => toggleModal()}>
					Close
				</Button>
				<Button
					color="primary"
					variant="contained"
					size="medium"
					onClick={() => handleAssign()}
				>
					Assign
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const mapStateToProps = state => ({
	venues: state.Venue.venues,
});
const mapDispatchToProps = {
	loadVenues: () => loadVenues(),
	clearForm: () => clearForm(),
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AssignDealDialog);
