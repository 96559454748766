import React, { useContext, useEffect, useState } from 'react';

// import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

import GroupIcon from '@material-ui/icons/Group';

import PageHeader from '../components/PageHeader.js';
import VenueDeletedTable from './VenueDeleted.Table';
import ConfirmModal from '../components/ConfirmModal';

// import Dialog from '../components/Dialog';
import Alerts from '../components/Alerts';

import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';

const VenueDeleted = () => {
  // const theme = useTheme();
  const Services = useContext(GlobalState);
  const UserMachine = useActor(Services.UserState);
  const users = UserMachine[0].context.deleted;
  const [currentRow, setCurrentRow] = useState(null);

  const handleRevive = (row) => {
    setCurrentRow(row);
    UserMachine[1]('DELETE_REVIVE');
  };

  useEffect(() => {
    UserMachine[1]('DELETE_FETCH');
  }, []);
  return (
    <Grid container spacing={2}>
      <ConfirmModal
        title=""
        color="action"
        open={UserMachine[0].matches('deleted.revive.confirm')}
        toggleModal={() => UserMachine[1]('DELETE_REVIVE_NO')}
        handleYes={() => UserMachine[1]({ type: 'DELETE_REVIVE_YES', data: currentRow.id })}
        DialogTitle="Are you sure?"
        ContentText="Are you sure you want to restore user."
        cancelText="STAY ARCHIVED"
        yesText="RESTORE"
      />
      <Alerts.Loading
        open={UserMachine[0].matches('deleted.revive.process')}
        message="Unarchiving user. . ."
      />
      <Alerts.Success
        open={UserMachine[0].matches('deleted.revive.success')}
        message="User has been restored successfully."
        onClose={() => UserMachine[1]('CLOSE')}
      />
      <Alerts.Failure
        open={UserMachine[0].matches('deleted.revive.failure')}
        message="Failed to restore."
        onClose={() => UserMachine[1]('CLOSE')}
      />
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between">
          <Grid item sm={6} xs={12}>
            <PageHeader headerTitle="Archived Users" icon={<GroupIcon />} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <VenueDeletedTable
            loading={UserMachine[0].matches('deleted.fetch.process')}
            failed={UserMachine[0].matches('deleted.fetch.failure')}
            users={users}
            onRevive={handleRevive}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default VenueDeleted;
