import React, { useEffect, useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  loadAll as loadStages,
  clearForm as clearFormStage,
  load as loadStage,
  remove as removeStage,
  save as saveStage,
} from '../actions/stage';
import {
  clearForm,
  load,
  loadAll,
  save,
  remove,
  updateBoardStage,
  swapBoardColumn,
  changeView,
} from '../actions/deal';
import { loadAll as loadContacts } from '../actions/contact';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  AddCircle as AddCircleIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from '@material-ui/icons';
// import Board from '@lourenci/react-kanban';
import Board from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import './DealBoardV2.css';
import PageHeader from '../components/PageHeader.js';
// import { SuccessSnackbar2 } from '../components/Snackbars.js';
// import DealEditDialog from './DealEditDialog';
import DealList from './DealListv2';
// import BoardColumnEditDialog from './BoardColumnEditDialog';
// import ConfirmModal from '../components/ConfirmModal';
import DealBoardCard from './DealBoardCard';
import DealBoardColumnHeader from './DealBoardColumnHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// Context
import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';
// Components
import Localization from '../localization';
import Alerts from '../components/Alerts';
import DealBoardCreateDialog from './DealBoard.create.dialog';
import DealBoardUpdateDialog from './DealBoard.update.dialog';
import DealBoardCreateColumnDialog from './DealBoard.create.column.dialog';
import DealBoardEditColumnDialog from './DealBoard.edit.column.dialog';
import ContactsCreateDialog from './Contacts.create.dialog';
import DealBoardCardConfirmDeleteDialog from './DealBoard.card.confirm.delete.dialog';
import Overlay from '../components/Overlay';

import Enums from '../enums';

const useStyles = makeStyles((theme) => ({
  defaultBtn: {
    color: '#6e768e',
    border: '1px solid #6e768e',
    backgroundColor: '#f9f5f5',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#f0eefa',
    },
  },
  addDealBtn: {
    color: '#ffffff',
    border: '1px solid #82c34c',
    backgroundColor: 'rgb(109,180,78)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgb(109,180,78, 0.7)',
      boxShadow: 'none',
    },
  },
  ButtonGroup: {
    '& button': {
      padding: '4px 15px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: 'inherit',
      },
      '& button:last-child': {
        marginRight: '4px',
      },
    },
  },
  activeBtn: {
    color: '#fff',
    fontWeight: '700',
    border: '1px solid #0eb0c4',
    backgroundColor: '#0eb0c4',
    '&:hover': {
      backgroundColor: 'rgb(14, 176, 196, 0.7)',
    },
  },
  inactiveListBtn: {
    color: '#0eb0c4',
    border: '1px solid #0eb0c4',
    backgroundColor: '#f9f5f5',
    borderLeft: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#f0eefa',
    },
  },
  inactiveBoardBtn: {
    color: '#0eb0c4',
    border: '1px solid #0eb0c4',
    backgroundColor: '#f9f5f5',
    borderRight: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#f0eefa',
    },
  },
  HeaderBtns: {
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      '& button': {
        display: 'block !important',
        marginBottom: '3px',
      },
    },
  },
}));

const BOARDS = Object.values(Enums.Deal.BOARD_TYPE);

const DealBoard = (props) => {
  // const boardColumns = { board: {columns: props.board.lanes}}
  const anchorRef = useRef(null);
  const [showBoardOption, setShowBoardOption] = useState(false);
  const [currentBoardIndex, setCurrentBoardIndex] = useState(0);
  const Services = useContext(GlobalState);
  const DealState = useActor(Services.DealState);
  const ContactState = useActor(Services.ContactState);
  const VenueStageState = useActor(Services.VenueStageState);
  const stages = VenueStageState[0].context.list;
  const classes = useStyles();
  const contacts = ContactState[0].context.list;
  const deals = DealState[0].context.list;
  const lenders = deals.filter((item) =>
    [Enums.Deal.TYPE.LEND, Enums.Deal.TYPE.INVEST].includes(item.type)
  );
  const boards = {
    columns: DealState[0].context.board.lanes,
  };
  // const selectedCard = DealState[0].context.selectedCard

  let createFormErrors = {};
  if (DealState[0].matches('create')) {
    createFormErrors = ((DealState[0].context.response || {}).data || {}).errors || {};
  }
  let editFormErrors = {};
  if (DealState[0].matches('edit')) {
    editFormErrors = ((DealState[0].context.response || {}).data || {}).errors || {};
  }

  let columnCreateErrors = {};
  if (VenueStageState[0].matches('column.create')) {
    columnCreateErrors = ((VenueStageState[0].context.response || {}).data || {}).errors || {};
  }
  const handleRemoveTransfer = (deal) => {
    // console.log('handleRemoveTransfer', deal)
    // Handle remove event
  };

  useEffect(() => {
    VenueStageState[1]('FETCH');
  }, []);
  useEffect(() => {
    ContactState[1]('FETCH');
  }, []);

  useEffect(() => {
    if (['fetch.success', 'fetch.failure'].some(ContactState[0].matches)) {
      ContactState[1]('CLOSE');
    }
  }, [ContactState[0]]);

  useEffect(() => {
    if (VenueStageState[0].matches('fetch.success')) {
      DealState[1]({ type: 'FETCH', data: { stages } });
    }
    if (VenueStageState[0].matches('fetchBg.success')) {
      DealState[1]({ type: 'FETCH_BG', data: { stages } });
    }
  }, [VenueStageState[0]]);

  useEffect(() => {
    const triggerFetchBgStates = ['board.card.remove.success', 'board.removeLane.success'];
    if (triggerFetchBgStates.some(DealState[0].matches)) {
      VenueStageState[1]('FETCH_BG');
    }
  }, [DealState[0]]);

  useEffect(() => {
    // if(DealState[0].matches('fetch.success')) {
    //   console.log('Process board')
    //   DealState[1]('MAKE_BOARD')
    // }
  }, [DealState[0]]);

  const onCardDragEnd = (card, source, destination) => {
    DealState[1]({ type: 'CHANGE_CARD_POSITION', data: { source, destination } });
  };

  const onLaneDragEnd = (column, source, destination) => {
    DealState[1]({ type: 'CHANGE_LANE', data: { source, destination } });
  };

  const removeBoardColumn = (stage_id) => {
    DealState[1]({ type: 'REMOVE_LANE_POSITION', data: stage_id });
  };

  const handleCreateColumn = () => {
    VenueStageState[1]('CREATE_COLUMN');
  };
  const handleCardEdit = (deal) => {
    DealState[1]({ type: 'EDIT', data: deal.id });
  };
  const handleEditColumn = (stage) => {
    VenueStageState[1]({ type: 'EDIT_COLUMN', data: stage });
  };
  const handleCardConfirmDelete = (card) => {
    DealState[1]({ type: 'REMOVE_CARD', data: card });
  };
  const handleShowBoardMenu = () => {
    setShowBoardOption(true);
  };
  const handleBoardSelect = (event, index) => {
    setCurrentBoardIndex(index);
    setShowBoardOption(false);
  };
  const handleBoardClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setShowBoardOption(false);
  };
  useEffect(() => {
    if (boards.columns.length > 0) {
      DealState[1]({ type: 'FILTER_CARDS', data: { stages, board: BOARDS[currentBoardIndex] } });
    }
  }, [currentBoardIndex]);
  if (DealState[0].matches('fetch.process') || VenueStageState[0].matches('fetch.process')) {
    return (
      <div
        className="board-container"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
          <Typography align="center">{Localization.deal.initialize}</Typography>
        </div>
      </div>
    );
  }
  return (
    <div className="board-container">
      <Overlay
        open={DealState[0].matches('board.removeLane.process')}
        message={Localization.deal.board.removingColumn}
      />
      <Alerts.Failure
        open={DealState[0].matches('board.removeLane.failure')}
        message={Localization.deal.board.failedToRemove}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Overlay
        open={
          DealState[0].matches('fetchBg.process') || VenueStageState[0].matches('fetchBg.process')
        }
        message={Localization.deal.board.updatingBoard}
      />

      <DealBoardCreateDialog
        errors={createFormErrors}
        contacts={contacts}
        lenders={lenders}
        submitting={DealState[0].matches('create.process')}
        open={['create.show', 'create.process', 'create.invalid', 'create.failure'].some(
          DealState[0].matches
        )}
        reset={DealState[0].matches('create.success')}
        onSave={(values) => DealState[1]({ type: 'SAVE', data: values })}
        onCancel={() => DealState[1]('CANCEL')}
        onCreateContact={() => ContactState[1]('CREATE')}
      />
      <Alerts.Loading
        open={DealState[0].matches('create.process')}
        message={Localization.deal.create.process}
      />
      <Alerts.Success
        open={DealState[0].matches('create.success')}
        message={Localization.deal.create.success}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={DealState[0].matches('create.failure')}
        message={Localization.deal.create.failure}
        onClose={() => DealState[1]('CLOSE')}
      />

      <DealBoardUpdateDialog
        lenders={lenders}
        errors={editFormErrors}
        data={DealState[0].context.selectedData || {}}
        contacts={contacts}
        submitting={DealState[0].matches('edit.process')}
        open={['edit.show', 'edit.process', 'edit.invalid', 'edit.failure'].some(
          DealState[0].matches
        )}
        reset={DealState[0].matches('edit.success')}
        onSave={(values) => DealState[1]({ type: 'SAVE', data: values })}
        onCancel={() => DealState[1]('CANCEL')}
        onCreateContact={() => ContactState[1]('CREATE')}
      />
      <Alerts.Loading
        open={DealState[0].matches('edit.process')}
        message={Localization.deal.edit.process}
      />
      <Alerts.Success
        open={DealState[0].matches('edit.success')}
        message={Localization.deal.edit.success}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={DealState[0].matches('edit.invalid')}
        message={Localization.deal.edit.invalid}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={DealState[0].matches('edit.failure')}
        message={Localization.deal.edit.failure}
        onClose={() => DealState[1]('CLOSE')}
      />

      <ContactsCreateDialog
        errors={((ContactState[0].context.response || {}).data || {}).errors || {}}
        open={ContactState[0].matches('create.show')}
        reset={ContactState[0].matches('create.success')}
        onSave={(values) => ContactState[1]({ type: 'SAVE', data: values })}
        onCancel={() => ContactState[1]('CANCEL')}
      />
      <Alerts.Loading
        open={ContactState[0].matches('create.process')}
        message={Localization.deal.contact.create.process}
      />
      <Alerts.Success
        open={ContactState[0].matches('create.success')}
        message={Localization.deal.contact.create.success}
        onClose={() => ContactState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={ContactState[0].matches('create.failure')}
        message={Localization.deal.contact.create.failure}
        onClose={() => ContactState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={ContactState[0].matches('create.invalid')}
        message={Localization.deal.contact.create.invalid}
        onClose={() => ContactState[1]('CLOSE')}
      />

      <DealBoardCreateColumnDialog
        errors={columnCreateErrors}
        stages={[
          { value: '', label: 'First column' },
          ...stages.map((stage) => ({ value: stage.id, label: stage.name })),
        ]}
        reset={VenueStageState[0].matches('column.create.success')}
        open={VenueStageState[0].matches('column.create.show')}
        onSave={(values) => VenueStageState[1]({ type: 'SAVE', data: values })}
        onCancel={() => VenueStageState[1]('CANCEL')}
      />
      <Overlay
        open={VenueStageState[0].matches('column.create.process')}
        message={Localization.deal.board.columnCreating}
      />
      <Alerts.Failure
        open={VenueStageState[0].matches('column.create.failure')}
        message={Localization.deal.board.columnFailed}
        onClose={() => VenueStageState[1]('CLOSE')}
      />

      <DealBoardEditColumnDialog
        reset={false}
        open={VenueStageState[0].matches('column.edit.show')}
        data={VenueStageState[0].context.selected}
        onSave={(values) => VenueStageState[1]({ type: 'SAVE', data: values })}
        onCancel={() => VenueStageState[1]('CANCEL')}
      />
      <Overlay
        open={VenueStageState[0].matches('column.edit.process')}
        message={Localization.deal.board.columnUpdating}
      />
      <Alerts.Failure
        open={VenueStageState[0].matches('column.edit.failure')}
        message={Localization.deal.board.columnUpdateFailed}
        onClose={() => VenueStageState[1]('CLOSE')}
      />

      <DealBoardCardConfirmDeleteDialog
        open={DealState[0].matches('board.card.remove.show')}
        onConfirm={() => DealState[1]('CONFIRM')}
        onCancel={() => DealState[1]('CANCEL')}
      />
      <Overlay
        open={DealState[0].matches('board.card.remove.process')}
        message={Localization.deal.board.card.remove.process}
      />
      <Alerts.Failure
        open={DealState[0].matches('board.card.remove.failure')}
        message={Localization.deal.board.card.remove.failure}
        onClose={() => DealState[1]('CLOSE')}
      />

      <Grid container spacing={1} style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          className="mt-4 px-3"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex' }}>
            <PageHeader headerTitle="Deals" icon={<LocalOfferOutlinedIcon />} />
            <ButtonGroup
              size="small"
              className={`${classes.ButtonGroup} ml-1`}
              style={{ height: '31.66px' }}
              ref={anchorRef}
            >
              <Button className={`${classes.addDealBtn} ml-1`} size="small" style={{ width: 97 }}>
                {BOARDS[currentBoardIndex]}
              </Button>
              <Button
                className={`${classes.addDealBtn}`}
                size="small"
                onClick={handleShowBoardMenu}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={showBoardOption}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 1, width: 154 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleBoardClose}>
                      <MenuList id="split-button-menu">
                        {BOARDS.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === currentBoardIndex}
                            onClick={(event) => handleBoardSelect(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <div>
            {props.view === 'board' && (
              <Button
                size="small"
                variant="contained"
                className={`${classes.defaultBtn} ml-1`}
                onClick={() => handleCreateColumn()}
              >
                <AddCircleIcon style={{ fontSize: 15, marginRight: 4 }} />
                Add Column
              </Button>
            )}
            <Button
              disabled={ContactState[0].matches('fetch.process') || DealState[0].matches('fetch')}
              size="small"
              className={`${classes.addDealBtn} ml-1`}
              variant="contained"
              onClick={() => DealState[1]('CREATE')}
            >
              <AddCircleIcon style={{ fontSize: 15, marginRight: 4 }} />
              Add Deal
            </Button>
            {/*<ButtonGroup size="small" className={`${classes.ButtonGroup} ml-1`}>
              <Button
                onClick={() => props.changeView('board')}
                className={props.view === 'board' ? classes.activeBtn : classes.inactiveBoardBtn}
              >
                Board
              </Button>
              <Button
                className={props.view === 'list' ? classes.activeBtn : classes.inactiveListBtn}
                onClick={() => props.changeView('list')}
              >
                List
              </Button>
            </ButtonGroup>*/}
          </div>
        </Grid>
        <Grid item xs={12} className="px-3" style={{ height: 'calc(100% - 75px)' }}>
          {props.view === 'list' && (
            <DealList
              history={props.history}
              deals={deals}
              openEditorModal={(deal) => handleCardEdit(deal)}
              removeBoardColumn={(id) => removeBoardColumn(id)}
              confirmDelete={(card) => handleCardConfirmDelete(card)}
            />
          )}
          {props.view === 'board' && (
            <Board
              onColumnDragEnd={onLaneDragEnd}
              onCardDragEnd={onCardDragEnd}
              renderColumnHeader={(data) => (
                <DealBoardColumnHeader
                  {...props}
                  data={data}
                  openBoardEditorModal={(stage) => handleEditColumn(stage)}
                  removeBoardColumn={(id) => removeBoardColumn(id)}
                />
              )}
              renderCard={(data, { removeCard, dragging }) => (
                <DealBoardCard
                  data={data}
                  removeCard={removeCard}
                  dragging={dragging}
                  handleDelete={(card) => handleCardConfirmDelete(card)}
                  onRemoveTransfer={(deal) => handleRemoveTransfer(deal)}
                  openEditorModal={(id) => handleCardEdit({ id })}
                />
              )}
            >
              {boards}
            </Board>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  view: state.Deal.view,
  success: state.Deal.success,
  stageSuccess: state.Stage.success,
  board: state.Deal.board,
  contacts: state.Contact.contacts,
});

const mapDispatchToProps = {
  loadStage: (payload) => loadStage(payload),
  loadStages: () => loadStages(),
  clearFormStage: () => clearFormStage(),
  removeStage: (payload) => removeStage(payload),
  loadContacts: () => loadContacts(),
  loadAll: () => loadAll(),
  load: (payload) => load(payload),
  clearForm: () => clearForm(),
  remove: (payload) => remove(payload),
  updateBoardStage: (payload) => updateBoardStage(payload),
  swapBoardColumn: (payload) => swapBoardColumn(payload),
  changeView: (payload) => changeView(payload),
  save: () => save(),
  saveStage: () => saveStage(),
};

export default connect(mapStateToProps, mapDispatchToProps)(DealBoard);
