import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearForm, load } from '../actions/contact';
import { loadAll as loadComments, clearComments } from '../actions/contact_comment';
import { loadAll as loadStages } from '../actions/stage';
import { DateToString } from '../helpers/DateFunctions.js';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import {
  EventNote as EventNoteIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  PeopleOutlineRounded,
} from '@material-ui/icons';
import PageHeader from '../components/PageHeader.js';
import ContactComments from './ContactComments.js';
import ContactEditDialog from './ContactEditDialog.js';
import { SuccessSnackbar2 } from '../components/Snackbars.js';

// Components
import Alerts from '../components/Alerts';
import ContactsUpdateDialog from './Contacts.update.dialog';
// Context
import Localization from '../localization';
import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';
import enums from '../enums';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItem: {
    padding: 0,
  },
  stage: {
    color: '#fff',
    backgroundColor: '#00acc1',
    padding: '3px 5px',
    borderRadius: '6px',
  },
  ButtonIcon: {
    fontSize: '12px',
    marginRight: '5px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ContactOverview = (props) => {
  const Services = useContext(GlobalState);
  const ContactState = useActor(Services.ContactState);
  const contactServerError = ((ContactState[0].context.response || {}).data || {}).errors || {};

  const classes = useStyles();
  const [loadComplete, setLoadComplete] = React.useState(false);
  // const { lends, borrows } = ((props.contact || {}).deals || []).reduce(
  //   (acc, curr) => {
  //     if (curr.type == 'Lend') {
  //       acc.lends.push(curr);
  //     }
  //     if (curr.type == 'Borrow') {
  //       acc.borrows.push(curr);
  //     }
  //     return acc;
  //   },
  //   { lends: [], borrows: [] }
  // );
  useEffect(() => {
    props.clearForm();
    async function fetchData() {
      let loaded = await props.load({ id: props.match.params.id });
      setLoadComplete(loaded);
      if (loaded) {
        await props.loadStages();
        await props.clearComments();
        await props.loadComments({ id: props.match.params.id });
      } else {
        props.history.push('/404');
      }
    }
    fetchData();
  }, []);

  const [editorOpen, setEditorOpen] = React.useState(false);
  const openEditorModal = () => {
    setEditorOpen(true);
  };
  const closeEditorModal = () => {
    setEditorOpen(false);
    props.clearForm();
    props.load({ id: props.match.params.id });
  };

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (ContactState[0].matches('edit.success')) {
      window.location.reload(true);
    }
  }, [ContactState[0]]);
  const handleSaveSuccess = () => {
    setSnackbarMessage('Contact successfully saved.');
    openSnackbar();
  };

  if (!loadComplete) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <div>
        <ContactsUpdateDialog
          clearForm={false}
          data={props.contact}
          errors={contactServerError}
          open={ContactState[0].matches('edit.show')}
          reset={ContactState[0].matches('edit.success')}
          onSave={(values) => ContactState[1]({ type: 'SAVE', data: values })}
          onCancel={() => ContactState[1]('CANCEL')}
        />
        <Alerts.Loading
          open={ContactState[0].matches('edit.process')}
          message={Localization.contact.edit.process}
        />
        <Alerts.Success
          open={ContactState[0].matches('edit.success')}
          message={Localization.contact.edit.success}
          onClose={() => ContactState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={ContactState[0].matches('edit.failure')}
          message={Localization.contact.edit.failure}
          onClose={() => ContactState[1]('CLOSE')}
        />
        <Alerts.Failure
          open={ContactState[0].matches('edit.invalid')}
          message={Localization.contact.edit.invalid}
          onClose={() => ContactState[1]('CLOSE')}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={10} xs={12}>
                <PageHeader
                  icon={<PeopleOutlineRounded />}
                  headerTitle="Contacts"
                  subTitle="Contact Overview"
                />
              </Grid>
              <Grid item sm={2} xs={12} className={`text-right`}>
                {props.user.role === 'venue' && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => ContactState[1]('EDIT')}
                  >
                    <EditIcon className={classes.ButtonIcon} />
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {snackbarOpen && (
          <SuccessSnackbar2
            open={snackbarOpen}
            openSnackbar={() => openSnackbar()}
            closeSnackbar={() => closeSnackbar()}
            message={snackbarMessage}
          />
        )}
        {editorOpen && (
          <ContactEditDialog
            open={editorOpen}
            openEditorModal={() => openEditorModal()}
            closeEditorModal={() => closeEditorModal()}
            handleSaveSuccess={() => handleSaveSuccess()}
          />
        )}

        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Paper className="p-3">
								<FeaturedDeal {...props} />
							</Paper>
						</Grid>
					</Grid>
				</Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContactDetails {...props} />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Paper className="p-3">
                      <Typography variant="body1" component="div" className="font-weight-bold pb-1">
                        Active Investments
                      </Typography>
                      <Deals {...props} deals={props.contact.deals} />
                    </Paper>
                  </Grid> */}
                  {props.contact.contactType == enums.Contact.TYPE.INVESTOR.toLowerCase() && (
                    <>
                      <Grid item xs={12}>
                        <Deals
                          {...props}
                          type="Investment"
                          title="Active Investments"
                          deals={props.contact.investments}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Deals
                          {...props}
                          type="Client"
                          title="Active Clients"
                          deals={props.contact.borrowers}
                        />
                      </Grid>
                    </>
                  )}
                  {props.contact.contactType == enums.Contact.TYPE.CLIENT.toLowerCase() && (
                    <>
                      <Grid item xs={12}>
                        <Deals
                          {...props}
                          type="Loan"
                          title="Active Loans"
                          deals={props.contact.loans}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Deals type="Lender" title="Lenders" deals={props.contact.lenders} />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper className="p-3">
                      <Typography variant="body1" component="div" className="font-weight-bold pb-1">
                        Customer Notes
                      </Typography>
                      <ContactComments />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Deals
                      stages={props.stages}
                      type="Investment"
                      title="Archived Deals"
                      deals={props.contact.archived_deals}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

const ContactDetails = (props) => {
  return (
    <Card>
      <CardHeader
        className="pt-3 pb-0"
        title={
          <Typography variant="body1" component="span" className="font-weight-bold">
            Contact Details
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Name
          </Grid>
          <Grid item xs={7}>
            {props.contact.firstname}
            {props.contact.lastname ? ' ' + props.contact.lastname : ''}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Phone
          </Grid>
          <Grid item xs={7}>
            {props.contact.phone}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Email
          </Grid>
          <Grid item xs={7}>
            {props.contact.email}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Address
          </Grid>
          <Grid item xs={7}>
            {props.contact.address}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Person 1 Name
          </Grid>
          <Grid item xs={7}>
            {props.contact.personName1 || 'N/A'}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Person 2 Name
          </Grid>
          <Grid item xs={7}>
            {props.contact.personName2 || 'N/A'}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5} className="font-weight-bold">
            Contact Type
          </Grid>
          <Grid item xs={7}>
            {props.contact.contactType
              ? enums.Contact.TYPE_LABEL_SMALL[props.contact.contactType]
              : ''}
          </Grid>
        </Grid>
        {props.contact.contactType === 'client' && <ClientDetails {...props} />}
        {props.contact.contactType === 'investor' && <InvestorDetails {...props} />}
      </CardContent>
    </Card>
  );
};

const ClientDetails = (props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5} className="font-weight-bold">
          Approve Date
        </Grid>
        <Grid item xs={7}>
          {DateToString(props.contact.approveDate)}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={5} className="font-weight-bold">
          Approve Amount
        </Grid>
        <Grid item xs={7}>
          {props.contact.approveAmount}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={5} className="font-weight-bold">
          File Ref
        </Grid>
        <Grid item xs={7}>
          {props.contact.fileRef}
        </Grid>
      </Grid>
    </>
  );
};

const InvestorDetails = (props) => {
  // console.log(props.contact);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5} className="font-weight-bold">
          Company File Ref
        </Grid>
        <Grid item xs={7}>
          {props.contact.companyFileRef}
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}> */}
      {/*   <Grid item xs={5} className="font-weight-bold"> */}
      {/*     Docket Number */}
      {/*   </Grid> */}
      {/*   <Grid item xs={7}> */}
      {/*     {props.contact.docketNum} */}
      {/*   </Grid> */}
      {/* </Grid> */}
      {/* <Grid container spacing={2}> */}
      {/*   <Grid item xs={5} className="font-weight-bold"> */}
      {/*     Person 1 Name */}
      {/*   </Grid> */}
      {/*   <Grid item xs={7}> */}
      {/*     {props.contact.personName1} */}
      {/*   </Grid> */}
      {/* </Grid> */}
      {/* <Grid container spacing={2}> */}
      {/*   <Grid item xs={5} className="font-weight-bold"> */}
      {/*     Person 2 Name */}
      {/*   </Grid> */}
      {/*   <Grid item xs={7}> */}
      {/*     {props.contact.personName2} */}
      {/*   </Grid> */}
      {/* </Grid> */}
    </>
  );
};

const DealItem = ({ label, contact, date, amount, id, stage = '' }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ListItem dense disableGutters className={classes.listItem}>
      <ListItemAvatar>
        <Avatar className={classes.small}>
          <EventNoteIcon fontSize="small" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <NavLink to={'/deals/view/' + id}>
                <Typography
                  variant="body2"
                  component="div"
                  style={{ color: '#00acc1', fontWeight: 600 }}
                >
                  {label}
                </Typography>
              </NavLink>
              {contact && (
                <Typography variant="caption" component="div" color="textSecondary">
                  {contact}
                </Typography>
              )}
              {date && (
                <Typography variant="caption" component="div" color="textSecondary">
                  {date}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} className="text-right pr-1 pt-2">
              <Typography style={{ color: theme.palette.grey[600] }} variant="h6">
                $
                {Number(amount || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </Typography>
              <Typography style={{ color: theme.palette.grey[600] }}>Amount</Typography>
            </Grid>
            {stage && (
              <Grid
                item
                xs={3}
                className="text-right pr-3 pt-2"
                style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
              >
                <div>
                  <Typography variant="caption" component="span" className={classes.stage}>
                    {stage}
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        }
      />
      <ListItemSecondaryAction>
        <NavLink to={'/deals/view/' + id} title="View loan">
          <IconButton edge="end" aria-label="view">
            <VisibilityIcon />
          </IconButton>
        </NavLink>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
DealItem.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  contact: PropTypes.string,
  stage: PropTypes.string,
};

const Deals = ({ title, type, deals, stages = [], ...props }) => {
  return (
    <Paper className="p-3">
      <Typography variant="body1" component="div" className="font-weight-bold pb-1">
        {title}
      </Typography>
      <List dense>
        {(() => {
          if (deals.length == 0) {
            return (
              <ListItem>
                <ListItemText primary="No items yet." />
              </ListItem>
            );
          }
          if (['Lender', 'Loan', 'Client'].includes(type)) {
            return deals.map((item, index) => (
              <DealItem
                key={index}
                label={item.title}
                contact={(item.contact || {}).firstname}
                date={DateToString(item.date)}
                amount={item.value}
                id={item.id}
              />
            ));
          }
          return (
            deals &&
            deals.map((item, index) => {
              const stage = stages.filter((stage) => stage.stage_id === item.stage.stage_id);
              let stageName = '';
              if (stage.length > 0) {
                stageName = stage[0].name ? stage[0].name : stage[0].stage.name;
              }
              let label = enums.Contact.TYPE_LABEL[item.title];
              if (!label) {
                label = item.title;
              }
              return (
                <DealItem
                  key={index}
                  label={label}
                  date={DateToString(item.date)}
                  amount={item.value}
                  id={item.id}
                  stage={stageName}
                />
              );
            })
          );
        })()}
      </List>
    </Paper>
  );
};
Deals.propTypes = {
  type: PropTypes.oneOf(['Loan', 'Investment', 'Lender', 'Client']),
  title: PropTypes.string.isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({})),
  stages: PropTypes.array,
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  success: state.Contact.success,
  contact: state.Contact.contact,
  stages: state.Stage.stages,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  clearComments: () => clearComments(),
  load: (payload) => load(payload),
  loadComments: (payload) => loadComments(payload),
  loadStages: () => loadStages(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactOverview));
