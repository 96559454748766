import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, IconButton, Typography, Popover, Dialog, DialogActions, DialogContent, DialogContentText,DialogTitle } from '@material-ui/core';
import {WarningRounded, MoreHorizOutlined } from '@material-ui/icons';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ConfirmModal from '../components/ConfirmModal';

const useStyles = makeStyles(theme => ({
  action: {
		cursor: 'pointer',
		height: '30px',
		paddingTop: '4px',
		paddingLeft: '10px',
		paddingRight: '10px',
		'&:hover': {
			backgroundColor: '#e6e6e6',
	 },
	},
	ColumnTitle: {
		fontWeight: 600,
		margin: '15px',
	},
	DialogTitle: {
		margin: 'auto',
	},
	DialogFooter: {
		margin: 'auto',
	},
	WarningIcon: {
		fontSize: '85px',
		color: '#DC004E',
	},
}));

const DealBoardColumnHeader = ({data, ...props}) => {

	const classes = useStyles();
	const [deleteId, setDeleteId] = React.useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
	const [infoModalOpen, setInfoModalOpen] = React.useState(false);

	const confirmDelete = (popupState, id) => {
		popupState.setOpen(false);
		// console.log('confirmDelete', id, data)
		setDeleteId(id);
		toggleConfirmModal();
	}

	const handleDelete = () => {
		toggleConfirmModal();
		props.removeBoardColumn(deleteId);
	}

	const handleEdit = (popupState, id) => {
		popupState.setOpen(false);
		props.openBoardEditorModal(data);
	}

	const toggleConfirmModal = () => {
		setConfirmModalOpen(!confirmModalOpen);
	}

	const toggleInfoModal = () => {
		setInfoModalOpen(!infoModalOpen);
	}

	return (
		<Grid container spacing={0} >
			<Grid item xs={12}>
				<ConfirmModal
					title=""
					color="action"
					open={confirmModalOpen}
					toggleModal={() => toggleConfirmModal()}
					handleYes={() => handleDelete()}
					DialogTitle="Are you sure?"
					ContentText={<span>Are you sure you want to delete  <b>{data.title}</b> Column? This process cannot be undone. Deals will be transfered to the first column of your board.</span>}
				/>
				<Dialog
					open={infoModalOpen}
					onClose={toggleInfoModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title" className={classes.DialogTitle}>
						<WarningRounded className={classes.WarningIcon} />
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" className="text-center">
							<Typography variant="h6" component="h6">
								Unable to delete column.
							</Typography>
							<Typography variant="body1" component="div">
								Please move first deals on this column before deleting again.
							</Typography>
						</DialogContentText>
					</DialogContent>
					<DialogActions className={classes.DialogFooter}>
						<Button onClick={toggleInfoModal} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
			<Grid item xs={10}>
				<Typography variant="body1" component="div" className={classes.ColumnTitle}>
					{data.title}
				</Typography>
			</Grid>
			<Grid item xs={2} className="text-right">
				<PopupState variant="popover">
					{popupState => (
						<div>
							<IconButton
								className="mt-1"
								aria-haspopup="true"
								{...bindTrigger(popupState)}
							>
								<MoreHorizOutlined />
							</IconButton>
							<Popover
								{...bindPopover(popupState)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
							>
								<Box className="p-1" style={{width:'150px'}}>
									<Typography  variant="subtitle2" color="textSecondary" className={classes.action}
										onClick={() => handleEdit(popupState, data.id)}
									>
										Edit Column Name
									</Typography>
									<Typography variant="subtitle2" color="textSecondary" className={classes.action}
										onClick={() => confirmDelete(popupState, data.id)}
									>
										Remove Column
									</Typography>
								</Box>
							</Popover>
						</div>
					)}
				</PopupState>
			</Grid>
		</Grid>
	)
}

export default DealBoardColumnHeader;
