import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadAll, remove } from '../actions/venue';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import PageHeader from '../components/PageHeader.js';
import ConfirmModal from '../components/ConfirmModal';
import { SuccessSnackbar } from '../components/Snackbars.js';
import TableComponent from '../components/table/TableComponent';
// Icons
import ArchiveIcon from '@material-ui/icons/Archive';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    color: '#ffffff',
    border: '1px solid #82c34c',
    marginTop: '-4px',
    backgroundColor: 'rgb(109,180,78)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgb(109,180,78, 0.7)',
      boxShadow: 'none',
    },
  },
  deletedBtn: {
    color: '#ffffff',
    border: '1px solid ' + theme.palette.error.main,
    marginTop: '-4px',
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      boxShadow: 'none',
    },
  },
}));

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'user_name', label: 'Contact Person' },
  { id: 'email', label: 'Email' },
  { id: 'address', label: 'Address' },
  { id: 'created_at', label: 'Date Created', align: 'center', type: 'date' },
  {
    id: '',
    align: 'right',
    label: 'Action',
    sortable: false,
    searchable: false,
    width: 120,
  },
];

const Venues = (props) => {
  const { venues, loadAll, remove, history } = props;
  const classes = useStyles();

  useEffect(() => {
    loadAll();
  }, []);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const toggleSnackbar = () => {
    setTimeout(function () {
      setSnackbarOpen(!snackbarOpen);
    }, 1000);
  };

  const [deleteId, setDeleteId] = React.useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const confirmDelete = (venue) => {
    setDeleteId(venue.id);
    toggleConfirmModal();
  };

  const handleDelete = async () => {
    let success = await remove({ id: deleteId });
    if (success) {
      toggleConfirmModal();
      toggleSnackbar();
    }
  };

  const handleEdit = (venue) => {
    history.push(`/users/edit/${venue.id}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between">
          <Grid item sm={6} xs={12}>
            <PageHeader headerTitle="Users" icon={<GroupIcon />} />
          </Grid>
          <Grid item sm={6} xs={12} className="text-right">
            <Button
              size="small"
              className={`${classes.deletedBtn}`}
              variant="contained"
              onClick={() => props.history.push('/users/archived')}
            >
              <ArchiveIcon style={{ fontSize: 15, marginRight: 4 }} />
              Archived Users
            </Button>
            <Button
              size="small"
              className={`${classes.addBtn}`}
              variant="contained"
              onClick={() => props.history.push('/users/edit/new')}
            >
              <AddCircle style={{ fontSize: 15, marginRight: 4 }} />
              Create New
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <SuccessSnackbar
            open={snackbarOpen}
            toggleSnackbar={() => toggleSnackbar()}
            message="Venue deleted successfully."
          />
          <ConfirmModal
            title=""
            color="action"
            open={confirmModalOpen}
            toggleModal={() => toggleConfirmModal()}
            handleYes={() => handleDelete()}
            DialogTitle="Are you sure?"
            ContentText="Are you sure that you want to archive this user?"
          />
          <TableComponent
            data={venues}
            headCells={headCells}
            handleEdit={(venue) => handleEdit(venue)}
            handleArchived={(venue) => confirmDelete(venue)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  venues: state.Venue.venues,
});

const mapDispatchToProps = {
  loadAll: () => loadAll(),
  remove: (payload) => remove(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Venues));
