import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Box, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  btnGreen: {
    color: '#ffffff',
    border: '1px solid #82c34c',
    backgroundColor: 'rgb(109,180,78)',
    boxShadow: 'none',
    marginTop: '3px',
    '&:hover': {
      backgroundColor: 'rgb(109,180,78, 0.7)',
      boxShadow: 'none',
    },
  },
  btnGray: {
    color: '#ffffff',
    border: '1px solid #78909c',
    backgroundColor: '#607D8B',
    boxShadow: 'none',
    marginTop: '3px',
    '&:hover': {
      backgroundColor: '#90A4AE',
      boxShadow: 'none',
    },
  },
}));

function DatePicker({ state, dateFilter, setDateFilter, filterByDate, clearDateFilter }) {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} style={{ display: 'flex', paddingRight: 0 }}>
        <Box style={{ display: 'flex' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Date From"
              disableToolbar
              autoOk
              format="dd/MM/yyyy"
              variant="inline"
              name="date_from"
              value={dateFilter.date_from}
              onChange={(e) => setDateFilter({ ...dateFilter, date_from: e })}
              fullWidth
              animateYearScrolling={true}
              inputVariant="outlined"
              margin="dense"
            />
          </MuiPickersUtilsProvider>
          <Box style={{ width: 10 }}></Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Date To"
              disableToolbar
              autoOk
              format="dd/MM/yyyy"
              variant="inline"
              name="date_to"
              value={dateFilter.date_to}
              onChange={(e) => setDateFilter({ ...dateFilter, date_to: e })}
              fullWidth
              animateYearScrolling={true}
              inputVariant="outlined"
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box style={{ width: 10 }}></Box>
        <Box style={{ display: 'flex', position: 'relative', top: '-4px' }}>
          <Button
            disabled={
              state[0].matches('fetchReport.process') || state[0].matches('fetchReport.process')
                ? true
                : false
            }
            variant="contained"
            className={classes.btnGreen}
            onClick={() => filterByDate()}
          >
            Submit
          </Button>
          <Box style={{ width: 10 }}></Box>
          <Button
            disabled={
              state[0].matches('fetchReport.process') || state[0].matches('fetchReport.process')
                ? true
                : false
            }
            variant="contained"
            className={classes.btnGray}
            onClick={() => clearDateFilter()}
          >
            Clear
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
DatePicker.propTypes = {
  state: PropTypes.array,
  dateFilter: PropTypes.object,
  setDateFilter: PropTypes.func,
  filterByDate: PropTypes.func,
  clearDateFilter: PropTypes.func,
};

export default DatePicker;
