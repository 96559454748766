import React, { useEffect, useState, useContext } from 'react';
// import PropTypes from 'prop-types'
import moment from 'moment';

// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Popover from '@material-ui/core/Popover';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@material-ui/icons/Search';
import { useTheme, makeStyles } from '@material-ui/core/styles';

// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';

import Inputs from '../components/Inputs';
import Table from '../components/Table';

const useStyles = makeStyles((theme) => ({
  ml1: {
    marginRight: theme.spacing(1),
  },
}));

// const BANK_OPTIONS = ['All bank account', 'SPV only', 'EBX only'];
// const BANK_OPTIONS_CODE = ['ALL', 'SPV', 'EBX'];
// const INVESTMENT_OPTIONS = ['All investment', 'Non-SMSF only', 'SMSF only'];
// const INVESTMENT_OPTIONS_CODE = ['ALL', 'NON_SMSF', 'SMSF'];

const HEADER_CELLS = [
  {
    name: 'Transaction Date',
    render(row) {
      return moment(row.date).format('DD-MM-YYYY');
    },
  },
  {
    name: 'Borrower Name',
    render(row) {
      if (!row.contact) {
        return '(missing contact)';
      }
      return row.contact.firstname + ' ' + (row.contact.lastname || '');
    },
  },
  {
    name: 'Bank Account',
    width: 150,
    render(row) {
      if (row.bankacc_option) {
        return <Chip size="small" color="primary" label={row.bankacc_option} />;
      }

      if (row.ebx_bankacc) {
        return <Chip size="small" color="secondary" label={row.ebx_bankacc} />;
      }

      return <Chip size="small" label="None" />;
    },
  },
  // {
  //   name: 'Investment Type',
  //   width: 150,
  //   render(row) {
  //     const investment = row.investment_option || '';
  //     if (!investment) {
  //       return <Chip size="small" label="None" />;
  //     }
  //     const isSMSF = /non/i.test(row.investment_option || '');
  //     return (
  //       <Chip size="small" color={isSMSF ? 'secondary' : 'primary'} label={row.investment_option} />
  //     );
  //   },
  // },
  {
    name: 'Loan Amount',
    width: 150,
    render(row) {
      return <b>{row.value}</b>;
    },
  },
  {
    name: 'Created At',
    width: 150,
    render(row) {
      return moment(row.created_at).format('DD-MM-YYYY');
    },
  },
];

const Disbursements = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Services = useContext(GlobalState);
  const ReportState = useActor(Services.fundManagerReportState);
  const [search, setSearch] = useState('');
  const [debounceSearch, setDebounceSearch] = useState(0);
  const handleSearch = (event) => {
    const value = event.target.value;
    if (debounceSearch) {
      clearTimeout(debounceSearch);
    }
    setDebounceSearch(
      setTimeout(() => {
        setSearch(value);
        ReportState[1]({
          type: 'DISBURSEMENT_REFRESH',
          data: { search: value },
        });
      }, 300)
    );
  };

  useEffect(() => {
    ReportState[1]({
      type: 'DISBURSEMENT_FETCH',
      data: { search },
    });
  }, []);
  return (
    <Paper elevation={6}>
      <Grid
        container
        spacing={2}
        style={{
          padding: theme.spacing(3),
        }}
      >
        <Grid item={12}>
          <Typography variant="h5">Loan Amounts</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            className={classes.ml1}
            style={{
              flexGrow: 1,
            }}
          >
            <Inputs.Text
              name="search"
              placeholder="Search. . ."
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table
            loading={ReportState[0].matches('disbursements.fetch')}
            hideHeader={false}
            error={ReportState[0].matches('disbursements.failure')}
            onTryAgain={() => ReportState[1]({ type: 'DISBURSEMENT_RETRY', data: { search } })}
            headers={HEADER_CELLS}
            rows={ReportState[0].context.disbursements}
            rowHeight={52}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
// BankAccounts.propTypes = {

// }
// BankAccounts.defaultProps = {

// }

export default Disbursements;
