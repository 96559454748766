import React, { createContext } from 'react';
import { useInterpret } from '@xstate/react';
// Machines
import DealMachine from './deal.machine';
import ContactMachine from './contact.machine';
import DashboardMachines from './dashboard.machines';
import StageMachine from './stage.machine';
import VenueStageMachine from './venue-stage.machine';
import SettingMachine from './setting.machine';
import UserMachine from './user.machine';
import fundManagerReportMachine from './fund-manager/report.machine';

export const GlobalState = createContext({});

export const GlobalStateProvider = (props) => {
  const DealState = useInterpret(DealMachine);
  const ContactState = useInterpret(ContactMachine);
  const DashboardState = useInterpret(DashboardMachines);
  const StageState = useInterpret(StageMachine);
  const VenueStageState = useInterpret(VenueStageMachine);
  const SettingState = useInterpret(SettingMachine);
  const UserState = useInterpret(UserMachine);
  const fundManagerReportState = useInterpret(fundManagerReportMachine);
  return (
    <GlobalState.Provider
      value={{
        DealState,
        ContactState,
        DashboardState,
        StageState,
        VenueStageState,
        SettingState,
        UserState,
        fundManagerReportState,
      }}
    >
      {props.children}
    </GlobalState.Provider>
  );
};
