import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { typing, save, load, clearForm } from '../actions/venue';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Group, Visibility, VisibilityOff } from '@material-ui/icons';
import {
  Grid,
  Button,
  Paper,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import PageHeader from '../components/PageHeader.js';
import AlertFormSuccess from '../components/AlertFormSuccess';
import AlertFormError from '../components/AlertFormError';
import { isObjectEmpty } from '../helpers/GeneralFunctions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const RequiredSterisk = () => {
  const theme = useTheme();
  return <span style={{ color: theme.palette.error.main }}>*</span>;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
  },
  label: {
    color: '#636669fa',
    fontWeight: 600,
  },
}));

const VenueEdit = (props) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    props.clearForm();
    props.load({ id: props.match.params.id });
  }, []);

  const handleFormChange = (event) => {
    props.typing({ [event.target.name]: event.target.value });
  };
  const handleArchiveChange = (event) => {
    props.typing({ deleted_at: event.target.checked });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let success = await props.save();
    setSuccess(success);
  };

  if (document.getElementById('success') && props.success) {
    document.getElementById('success').focus();
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeader
          headerTitle="User"
          subTitle={props.venue.id ? 'Edit Details' : 'Create New'}
          icon={<Group />}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!isObjectEmpty(props.errors) && <AlertFormError errors={props.errors} />}
                {success && <AlertFormSuccess message="User details successfully saved." />}
              </Grid>
              {/*<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item sm={3} xs={12}>
										<label className={classes.label}>User Information</label>
									</Grid>
									<Grid item sm={9} xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<InputLabel shrink>Venue Name <RequiredSterisk /></InputLabel>
												<TextField
													fullWidth
													variant="outlined"
													margin="dense"
													name="name"
													value={props.venue.name}
													onChange={(event) => handleFormChange(event)}
													error={props.errors.hasOwnProperty('name')}
												/>
											</Grid>
											<Grid item xs={12}>
												<InputLabel shrink>Address <RequiredSterisk /></InputLabel>
												<TextField
													fullWidth
													multiline
													variant="outlined"
													margin="dense"
													name="address"
													value={props.venue.address}
													onChange={(event) => handleFormChange(event)}
													error={props.errors.hasOwnProperty('address')}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<hr style={{ width: '100%' }} />*/}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <label className={classes.label}>User Information</label>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel shrink>
                          First Name <RequiredSterisk />
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="firstname"
                          value={props.venue.firstname}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'firstname')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>
                          Last Name <RequiredSterisk />
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="lastname"
                          value={props.venue.lastname}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'lastname')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>
                          Address <RequiredSterisk />
                        </InputLabel>
                        <TextField
                          fullWidth
                          multiline
                          variant="outlined"
                          margin="dense"
                          name="address"
                          value={props.venue.address}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'address')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ width: '100%' }} />
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <label className={classes.label}>Login Details</label>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel shrink>
                          E-mail <RequiredSterisk />
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="email"
                          value={props.venue.email}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'email')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>
                          Password <RequiredSterisk />
                        </InputLabel>
                        <TextField
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          value={props.venue.password}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>
                          Password Confirm <RequiredSterisk />
                        </InputLabel>
                        <TextField
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          type={showPasswordConfirm ? 'text' : 'password'}
                          name="password_confirmation"
                          value={props.venue.password_confirmation}
                          onChange={(event) => handleFormChange(event)}
                          error={Object.prototype.hasOwnProperty.call(props.errors, 'password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                >
                                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ width: '100%' }} />
              <Grid
                item
                xs={12}
                className="text-right"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        color="primary"
                        checked={Boolean(props.venue.deleted_at)}
                        onChange={handleArchiveChange}
                      />
                    }
                    label="Archive"
                  />
                </FormGroup>
                <Button type="submit" variant="contained" color="primary">
                  {props.isProcessing ? 'Saving ...' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isProcessing: state.Venue.isProcessing,
  errors: state.Venue.errors,
  venue: state.Venue.venue,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  load: (payload) => load(payload),
  save: () => save(),
  typing: (payload) => typing(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueEdit);
