/* global global */
import { createMachine, assign } from 'xstate';

const fetchState = {
  process: {
    invoke: {
      src: 'fetching',
      onDone: {
        target: 'success',
        actions: ['setList'],
      },
      onError: 'failure',
    },
  },
  success: {},
  failure: {},
};

export default createMachine(
  {
    predictableActionArguments: true,
    id: 'VenueStage',
    on: {
      FETCH: 'fetch.process',
      FETCH_BG: 'fetchBg.process',
      CREATE_COLUMN: 'column.create.show',
      EDIT_COLUMN: {
        target: 'column.edit.show',
        actions: ['setCurrentSelection'],
      },
    },
    initial: 'idle',
    context: {
      response: {},
      selected: {},
      list: [],
    },
    states: {
      idle: {
        // on: {
        //   FETCH: 'fetch.process'
        // }
      },
      fetch: {
        states: fetchState,
      },
      fetchBg: {
        states: fetchState,
      },
      column: {
        states: {
          create: {
            states: {
              show: {
                on: {
                  SAVE: 'process',
                  CANCEL: '#VenueStage.idle',
                },
              },
              process: {
                invoke: {
                  src: 'creatingColumn',
                  // onDone: 'success',
                  onDone: '#VenueStage.fetchBg.process',
                  onError: {
                    target: 'failure',
                    actions: ['setResponse'],
                  },
                },
              },
              success: {
                on: {
                  CLOSE: '#VenueStage.idle',
                },
              },
              failure: {
                on: {
                  CLOSE: 'show',
                },
              },
            },
          },
          edit: {
            states: {
              show: {
                on: {
                  SAVE: 'process',
                  CANCEL: '#VenueStage.idle',
                },
              },
              process: {
                invoke: {
                  src: 'updatingColumn',
                  // onDone: 'success',
                  onDone: '#VenueStage.fetchBg.process',
                  onError: 'failure',
                },
              },
              success: {},
              failure: {
                on: {
                  CLOSE: '#VenueStage.idle',
                },
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      setResponse: assign({
        response: (ctx, event) => event.data.response,
      }),
      setCurrentSelection: assign({
        selected: (ctx, event) => event.data,
      }),
      setList: assign({
        list: (ctx, event) => event.data.data,
      }),
    },
    services: {
      updatingColumn(ctx, event) {
        return global.axios.post('/v2/stages/venue/update', {
          ...event.data,
          stageId: ctx.selected.id,
        });
      },
      creatingColumn(ctx, event) {
        return global.axios.post('/v2/stages/venue/create', event.data);
      },
      fetching(ctx, event) {
        return global.axios.get('/v2/stages/venue');
      },
    },
  }
);
