import Auth from './auth';
import Venue from './venue';
import Contact from './contact';
import ContactComment from './contact_comment';
import Deal from './deal';
import DealComment from './deal_comment';
import Report from './report';
import Stage from './stage';
import Setting from './setting';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
	Auth,
	Venue,
	Contact,
	ContactComment,
	Deal,
	DealComment,
	Report,
	Stage,
	Setting,
});

export default (state, action) => (
	action.type === 'LOGOUT'
			? rootReducer(undefined, action)
			: rootReducer(state, action)
)
