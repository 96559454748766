const TYPE = {
  INVESTOR: 'Investor',
  CLIENT: 'Client',
  BORROWER: 'Borrower',
};
const TYPE_LABEL = {
  [TYPE.INVESTOR]: 'Investor',
  [TYPE.CLIENT]: 'Borrower',
};
const TYPE_LABEL_SMALL = {
  [TYPE.INVESTOR.toLowerCase()]: 'Investor',
  [TYPE.CLIENT.toLowerCase()]: 'Borrower',
};

export default {
  TYPE,
  TYPE_LABEL,
  TYPE_LABEL_SMALL,
};
