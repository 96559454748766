import moment from 'moment';
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { loadStatistics } from '../actions/deal';
import { loadAll as loadVenues } from '../actions/venue';
import { loadAll as loadStages } from '../actions/stage';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  // Paper,
  // Button,
  // Card,
  // CardContent,
  // Avatar,
  Box,
} from '@material-ui/core';
import {
  // AttachMoneyRounded as AttachMoneyRoundedIcon,
  // EqualizerRounded as EqualizerRoundedIcon,
  // EventAvailableRounded as EventAvailableRoundedIcon,
  // PermContactCalendarRounded as PermContactCalendarRoundedIcon,
  // InfoOutlined as InfoOutlinedIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import CountUp from "react-countup";
import PageHeader from '../components/PageHeader';
import LatestDeals from './LatestDeals';
import LatestContacts from './LatestContacts';
// import DealsByTypeChart from "../components/charts/DealsByTypeChart";
// import DealsByStageChart from "../components/charts/DealsByStageChart";
// import DealsByDateChart from "../components/charts/DealsByDateChart";
import CustomDatePicker from '../components/dashboard-v2/DatePicker';
// import CardContainer from '../components/dashboard-v2/CardContainer';
import CardCount from '../components/dashboard-v2/CardCount';

import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';

import { formatDateTime, DateToString } from '../helpers/DateFunctions';

// import { numberWithCommas } from "../helpers/numberFormat";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '3px',
  },
  buttonLabel: {
    minWidth: '150px',
  },
  autocomplete: {
    float: 'right',
    minWidth: '300px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  paper: {
    padding: '18px',
  },
  fabButton: {
    minWidth: '20px',
    padding: '20px',
    borderRadius: '50%',
    cursor: 'text',
  },
  fabButtonIcon: {
    fontSize: '20px',
  },
  fabButton1: {
    border: '1px solid rgb(247, 184, 75, 0.6)',
    backgroundColor: 'rgb(247, 184, 75, 0.3)',
    color: 'rgb(247, 184, 75)',
  },
  fabButton2: {
    border: '1px solid rgb(79, 198, 225, 0.6)',
    backgroundColor: 'rgb(79, 198, 225, 0.3)',
    color: 'rgb(79, 198, 225)',
  },
  fabButton3: {
    border: '1px solid rgb(26, 188, 156, 0.6)',
    backgroundColor: 'rgb(26, 188, 156, 0.3)',
    color: 'rgb(26, 188, 156)',
  },
  fabButton4: {
    border: '1px solid rgb(255, 152, 171, 0.6)',
    backgroundColor: 'rgb(255, 152, 171, 0.3)',
    color: 'rgb(255, 152, 171)',
  },
  label: {
    color: '#8c8c8c',
  },
  infoIcon: {
    color: '#3f51b5',
    marginRight: '4px',
    fontSize: '20px',
    marginBottom: '3px',
  },
  infoText: {
    color: '#8c8c8c',
  },
  avatar: {
    backgroundColor: '#00ACC1',
    fontSize: '22px',
    fontWeight: 'bold',
    margin: 'auto',
    marginTop: '7px',
    marginBottom: '5px',
    padding: '28px',
  },
  card: {
    background: 'url(/images/bg-pattern-2.png)',
  },
}));

// const dateFrom = new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000);

const Dashboard = (props) => {
  const services = useContext(GlobalState);
  const DashboardState = useActor(services.DashboardState);
  const investments = DashboardState[0].context.investments;
  const transfers = DashboardState[0].context.transfers;
  const balance = DashboardState[0].context.balance;
  const clients = DashboardState[0].context.clients;

  // const classes = useStyles();
  const [venue, setVenue] = React.useState(null);

  const [dateFilter, setDateFilter] = useState({
    date_from: null,
    date_to: null,
  });

  useEffect(() => {
    props.loadStatistics({ venue_id: null });
    props.loadStages({ venue_id: null });

    if (props.user.role === 'god_admin') {
      props.loadVenues();
    }
  }, []);

  useEffect(() => {
    if (
      DashboardState[0].context.dateFilter.date_from !== null &&
      DashboardState[0].context.dateFilter.date_to !== null
    ) {
      DashboardState[1]({
        type: 'FETCH',
        data: {
          user: props.user,
          dateFilter: {
            date_from: `${formatDateTime(
              DashboardState[0].context.dateFilter.date_from,
              '-',
              'YMD'
            )} 00:00:00`,
            date_to: `${formatDateTime(
              DashboardState[0].context.dateFilter.date_to,
              '-',
              'YMD'
            )} 23:59:59`,
          },
        },
      });
    } else {
      DashboardState[1]({
        type: 'FETCH',
        data: {
          user: props.user,
          dateFilter: {
            date_from:
              dateFilter.date_from !== null
                ? `${formatDateTime(dateFilter.date_from, '-', 'YMD')} 00:00:00`
                : null,
            date_to:
              dateFilter.date_to !== null
                ? `${formatDateTime(dateFilter.date_to, '-', 'YMD')} 23:59:59`
                : null,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      DashboardState[0].context.dateFilter.date_from !== null &&
      DashboardState[0].context.dateFilter.date_to !== null
    ) {
      setDateFilter({
        date_from: DashboardState[0].context.dateFilter.date_from,
        date_to: DashboardState[0].context.dateFilter.date_to,
      });
    }
  }, []);

  const filterByDate = () => {
    DashboardState[1]({
      type: 'FETCH',
      data: {
        user: props.user,
        dateFilter: {
          date_from:
            dateFilter.date_from !== null
              ? `${formatDateTime(dateFilter.date_from, '-', 'YMD')} 00:00:00`
              : null,
          date_to:
            dateFilter.date_to !== null
              ? `${formatDateTime(dateFilter.date_to, '-', 'YMD')} 23:59:59`
              : null,
        },
      },
    });
  };

  const clearDateFilter = () => {
    setDateFilter({ date_from: null, date_to: null });
    DashboardState[1]({
      type: 'FETCH',
      data: {
        user: props.user,
        dateFilter: {
          date_from: null,
          date_to: null,
        },
      },
    });
  };

  const changeVenue = (venue) => {
    setVenue(venue);

    let venue_id = venue ? venue.id : null;
    props.loadStages({ venue_id: venue_id });
    props.loadStatistics({ venue_id: venue_id });
  };

  // const balanceToLend = () => {
  // 	return (
  // 		parseFloat(DashboardState[0].context.cardData.total_lend).toFixed(2) -
  // 		parseFloat(DashboardState[0].context.cardData.total_borrowed).toFixed(2)
  // 	);
  // };

  const handleSetDatePicker = (event) => {
    let start = event.date_from ? moment(event.date_from) : null;
    let end = event.date_to ? moment(event.date_to) : null;
    if (start && !end) {
      end = moment(start).add(5, 'days');
    } else if (end && !start) {
      start = moment(end).subtract(5, 'days');
    } else {
      if (end.isBefore(start)) {
        end = moment(start).add(3, 'days');
      } else if (start.isAfter(end)) {
        start = moment(end).subtract(3, 'days');
      }
    }
    setDateFilter({
      date_from: start._d,
      date_to: end._d,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Grid item sm={6} xs={12}>
            <PageHeader headerTitle="Dashboard" icon={<DashboardOutlinedIcon />} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <VenueSelector {...props} venue={venue} changeVenue={(venue) => changeVenue(venue)} />
          </Grid>
        </Grid>
      </Grid>
      {/* Date filter */}
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={6} xs={12}>
          <Box
            style={{
              display: 'flex',
            }}
          >
            <Box
              style={{
                color: '#1976d2',
                marginRight: 10,
                display:
                  dateFilter.date_from !== null && dateFilter.date_to !== null ? 'block' : 'none',
              }}
            >
              <ErrorOutlineIcon />
            </Box>
            <Typography component="span">
              <Box
                style={{
                  display:
                    dateFilter.date_from !== null && dateFilter.date_to !== null ? 'block' : 'none',
                }}
              >
                Showing statistics from {DateToString(dateFilter.date_from)} to{' '}
                {DateToString(dateFilter.date_to)}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          md={6}
          xs={12}
        >
          <CustomDatePicker
            state={DashboardState}
            dateFilter={dateFilter}
            setDateFilter={handleSetDatePicker}
            filterByDate={filterByDate}
            clearDateFilter={clearDateFilter}
          />
        </Grid>
      </Grid>
      {/* Dashboard Cards */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          {/* Investments */}
          <CardCount
            loading={DashboardState[0].matches('fetchReport.process')}
            title="Investor"
            nonSmsf={{
              deals: investments.nonSmsf.deals,
              amount: investments.nonSmsf.total,
              title: 'Non-SMSF SPV1',
            }}
            smsf={{
              deals: investments.smsf.deals,
              amount: investments.smsf.total,
              title: 'SMSF SPV2',
            }}
            total={{
              deals: investments.total.deals,
              amount: investments.total.total,
              title: 'Total Invest',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {/* transfer to bank */}
          <CardCount
            loading={DashboardState[0].matches('fetchReport.process')}
            title="Transfer to Bank"
            nonSmsf={{
              deals: transfers.nonSmsf.deals,
              amount: transfers.nonSmsf.total,
              title: 'Non-SMSF SPV1',
            }}
            smsf={{ deals: transfers.smsf.deals, amount: transfers.smsf.total, title: 'SMSF SPV2' }}
            total={{
              deals: transfers.total.deals,
              amount: transfers.total.total,
              title: 'Total Transfer',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {/* Balance */}
          <CardCount
            balance={true}
            loading={DashboardState[0].matches('fetchReport.process')}
            title="Balance"
            nonSmsf={{
              amount: balance.nonSmsf,
              title: 'Non-SMSF SPV1',
            }}
            smsf={{
              amount: balance.smsf,
              title: 'SMSF SPV2',
            }}
            total={{
              amount: balance.total,
              title: 'Balance',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {/* client */}
          <CardCount
            loading={DashboardState[0].matches('fetchReport.process')}
            title="Borrower Line of Credit"
            nonSmsf={{
              deals: clients.nonSmsf.deals,
              amount: clients.nonSmsf.total,
              title: 'Loan Non-SMSF SPV1',
            }}
            smsf={{
              deals: clients.smsf.deals,
              amount: clients.smsf.total,
              title: 'Loan SMSF SPV2',
            }}
            total={{
              deals: clients.total.deals,
              amount: clients.total.total,
              title: 'Total Loans',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LatestDeals
            state={DashboardState}
            statsData={DashboardState[0].context.statsData}
            dateFilter={dateFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <LatestContacts
            state={DashboardState}
            statsData={DashboardState[0].context.statsData}
            dateFilter={dateFilter}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const VenueSelector = (props) => {
  const classes = useStyles();

  if (props.user.role === 'venue') {
    return null;
  }

  const onChange = (event, venue) => {
    props.changeVenue(venue);
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      options={props.venues}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label="Select Venue" variant="outlined" fullWidth margin="dense" />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  venues: state.Venue.venues,
  stats: state.Deal.stats,
});

const mapDispatchToProps = {
  loadVenues: () => loadVenues(),
  loadStatistics: (payload) => loadStatistics(payload),
  loadStages: (payload) => loadStages(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
