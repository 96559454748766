/* global global */
import { DEAL_COMMENT } from '../constants/deal_comment';

export function clearForm() {
  return { type: DEAL_COMMENT.DEAL_COMMENT_CLEAR_FORM };
}

export function clearComments() {
  return { type: DEAL_COMMENT.DEAL_COMMENT_CLEAR };
}

export function load(payload) {
  return function (dispatch) {
    global
      .axios({
        method: 'get',
        url: `/deal_comment/${payload.id}`,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL_COMMENT.DEAL_COMMENT_LOAD, payload: data });
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };
}

export function loadAll(payload) {
  return function (dispatch) {
    global
      .axios({
        method: 'get',
        url: `/deal_comment?deal_id=${payload.id}`,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL_COMMENT.DEAL_COMMENT_LOAD_ALL, payload: data });
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };
}

export function save(payload) {
  return function (dispatch, getState) {
    let id = '';
    if (Object.keys(getState().DealComment.comment).length > 0) {
      id = getState().DealComment.comment.id;
    }

    let postData = new FormData();
    postData.append('id', id);
    postData.append('comment', payload.comment);
    postData.append('user_id', getState().Auth.user.id);
    postData.append('deal_id', getState().Deal.deal.id);
    postData.append('status', 'active');

    for (let i in payload.files) {
      postData.append('files[]', payload.files[i]);
    }

    let method = 'post';
    let url = '/deal_comment';

    if (id) {
      url = '/update_comment';
      for (let i in payload.deleteFiles) {
        postData.append('delete_files[]', payload.deleteFiles[i]);
      }
    }

    return global
      .axios({
        method: method,
        url: url,
        data: postData,
        headers: {
          'content-type': `multipart/form-data; boundary=${postData._boundary}`,
        },
      })
      .then(({ data }) => {
        dispatch({ type: DEAL_COMMENT.DEAL_COMMENT_SAVE_SUCCESS });
        dispatch({ type: DEAL_COMMENT.DEAL_COMMENT_LOAD_ALL, payload: data });
      })
      .catch(function (error) {
        console.error('error', error);
      });
  };
}

export function remove(payload) {
  return function (dispatch, getState) {
    global
      .axios({
        method: 'delete',
        url: `/deal_comment/${payload.id}?deal_id=${getState().Deal.deal.id}`,
      })
      .then(({ data }) => {
        dispatch({ type: DEAL_COMMENT.DEAL_COMMENT_LOAD_ALL, payload: data });
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };
}

export function removeFile(payload) {
  return { type: DEAL_COMMENT.DEAL_COMMENT_DELETE_FILE, payload: payload };
}
