import React from 'react';
import { connect } from 'react-redux';
import { clearForm, save } from '../actions/contact_comment';

import { Grid, Paper, Button, Box, IconButton } from '@material-ui/core';
import { AttachFile as AttachFileIcon, Close as CloseIcon } from '@material-ui/icons';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const CommentNew = (props) => {
  const [content, setContent] = React.useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setContent(editorState);
  };

  const handleSaveComment = () => {
    props.save({
      comment: draftToHtml(convertToRaw(content.getCurrentContent())),
      files: files,
    });
    setContent(EditorState.createEmpty());
    setFiles([]);
  };

  const [files, setFiles] = React.useState([]);
  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let newFiles = files.concat(Array.from(event.target.files));
      setFiles(newFiles);
      document.getElementById('file-upload').value = null;
    }
  };

  const handleRemoveFile = (index) => {
    let newFiles = files.filter((item) => item !== files[index]);
    setFiles(newFiles);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className="custom-card-border">
          <Editor
            editorState={content}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <FileUploadList files={files} handleRemoveFile={(index) => handleRemoveFile(index)} />
          </Grid>
          <Grid item xs={5} className="text-right">
            <input
              accept="image/*"
              className="d-none"
              id="file-upload"
              multiple
              type="file"
              onChange={(event) => handleFileUpload(event)}
            />
            <label htmlFor="file-upload" className="mr-1">
              <Button component="span" color="primary" size="small">
                Upload
              </Button>
            </label>
            <Button
              size="small"
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => handleSaveComment()}
              disabled={content.getCurrentContent().hasText() ? false : true}
            >
              Save Note
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FileUploadList = (props) => {
  if (props.files.length === 0) {
    return null;
  }

  return (
    <div style={{ width: '100%' }} className="mt-1">
      {props.files &&
        props.files.map((row, index) => {
          return (
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              key={index}
              className="p-0 pt-1 pl-1"
            >
              <Box flexGrow={1} bgcolor="grey.100" className="upload-file-list-title">
                <AttachFileIcon className="ml-1 mr-1" style={{ fontSize: '10px' }} />
                {row.name}
              </Box>
              <Box bgcolor="grey.100" className="upload-file-list-action">
                <label htmlFor="icon-button-file" className="m-0" title="remove">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    onClick={() => props.handleRemoveFile(index)}
                  >
                    <CloseIcon style={{ fontSize: '10px' }} />
                  </IconButton>
                </label>
              </Box>
            </Box>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  success: state.ContactComment.success,
  comments: state.ContactComment.comments,
});

const mapDispatchToProps = {
  clearForm: () => clearForm(),
  save: (payload) => save(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentNew);
