import { VENUE } from '../constants/venue';

const initialState = {
  errors: {},
  isProcessing: false,
  venues: [],
  venue: {
    id: '',
    user_id: '',
    lastname: '',
    firstname: '',
    name: '',
    address: '',
    email: '',
    password: '',
    password_confirmation: '',
    role: 'venue',
  },
};

const Venue = (state = initialState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case VENUE.VENUE_CLEAR_FORM: {
      newState.errors = {};
      newState.isProcessing = false;

      newState.venue = {
        id: '',
        user_id: '',
        lastname: '',
        firstname: '',
        name: '',
        address: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'venue',
      };

      return newState;
    }

    case VENUE.VENUE_DELETE: {
      newState.venues = action.payload;
      return newState;
    }

    case VENUE.VENUE_LOAD: {
      let data = action.payload;
      newState.venue = {
        id: data.id,
        user_id: data.user_id,
        lastname: data.user.lastname,
        firstname: data.user.firstname,
        name: data.name,
        address: data.address,
        email: data.user.email,
        password: '',
        password_confirmation: '',
        role: data.user.role,
        deleted_at: data.user.deleted_at,
      };

      return newState;
    }

    case VENUE.VENUE_LOAD_ALL: {
      newState.venues = action.payload;
      return newState;
    }

    case VENUE.VENUE_SAVE_REQUEST: {
      newState.isProcessing = true;
      newState.errors = {};

      return newState;
    }

    case VENUE.VENUE_SAVE_SUCCESS: {
      newState.errors = {};
      newState.isProcessing = false;

      newState.venue.id = action.payload.id;
      newState.venue.user_id = action.payload.user_id;

      newState.venue.password = '';
      newState.venue.password_confirmation = '';

      return newState;
    }

    case VENUE.VENUE_SAVE_ERROR: {
      newState.isProcessing = false;
      newState.venue.password = '';
      newState.venue.password_confirmation = '';
      newState.errors = action.payload;

      return newState;
    }

    case VENUE.VENUE_TYPING: {
      let key = Object.keys(action.payload)[0];
      newState.venue[key] = action.payload[key];
      return newState;
    }

    default:
      return state;
  }
};

export default Venue;
