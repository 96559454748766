import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import {connect} from 'react-redux';
import Alerts from '../components/Alerts';
import { Paper } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import TableComponent from '../components/table/TableComponent';
import DealTransferDialog from '../components/Deal.Transfer.Dialog';
import DealTransferEditDialog from '../components/Deal.Transfer.Edit.Dialog';
import DealTransferRemoveConfirm from '../components/DealTransferRemoveConfirm';
import Enums from '../enums';
import Localization from '../localization';
import { GlobalState } from '../machines/state';
import { useActor } from '@xstate/react';

const headCells = [
  { id: 'contact_name', label: 'Contact Name' },
  // { id: 'stage_name', label: 'Deal Stage', sortKey: 'stage_position' },
  {
    id: 'title',
    label: 'Title',
    render(_row, value) {
      if (value == Enums.Contact.TYPE.CLIENT) {
        return Enums.Contact.TYPE_LABEL[Enums.Contact.TYPE.CLIENT];
      }
      return value;
    },
  },
  {
    id: 'type',
    label: 'Deal Type',
    render: (row, value) => {
      let chip = null;
      if ([Enums.Deal.TYPE.INVEST, Enums.Deal.TYPE.TRANSFER].includes(row.type)) {
        chip = <Chip color="primary" size="small" label={row.investment_option} />;
      }
      if (row.type === Enums.Deal.TYPE.LOAN) {
        chip = <Chip color="secondary" size="small" label={row.ebx_bankacc} />;
      }
      return (
        <span>
          {chip} {value}
        </span>
      );
    },
  },
  { id: 'date', label: 'Transaction Date', type: 'date' },
  { id: 'value', label: 'Amount', type: 'number' },
  { id: 'created_at', label: 'Date Created', type: 'date' },
  { id: '', label: 'Action', sortable: false, searchable: false, width: 110 },
];

const DealList = (props) => {
  const Services = useContext(GlobalState);
  const history = useHistory();
  const { deals, openEditorModal, confirmDelete } = props;
  const DealState = useActor(Services.DealState);
  const dealErrors = DealState[0].context.errors;
  const transferToRemove = DealState[0].context.transferToRemove;
  const transferDetail = DealState[0].context.transferDetail;
  const [deal, setDeal] = useState(null);

  const handleView = (deal) => {
    history.push(`/deals/view/${deal.id}`);
  };

  const handleEdit = (deal) => {
    openEditorModal(deal);
  };

  const handleDelete = (deal) => {
    confirmDelete(deal);
  };
  const handleTransferSubmit = (values) => {
    // console.log('handleTransferSubmit', values)
    DealState[1]({
      type: 'CONFIRM',
      data: {
        dealId: deal.id,
        amount: values.amountTransfer,
        ebxBankAccount: values.bankAccountOpt,
        inputRef: values.referenceNumber,
        transactionDate: values.transactionDate,
        description: values.description,
      },
    });
  };
  const handleRemoveTransfer = (deal) => {
    DealState[1]({
      type: 'TRANSFER_REMOVE',
      data: deal,
    });
  };

  const handleTransfer = (deal) => {
    setDeal(deal);
    DealState[1]({ type: 'TRANSFER', data: {} });
  };

  const handleTransferDeleteConfirm = () => {
    DealState[1]({
      type: 'PROCEED',
      data: {
        id: transferToRemove?.transfer_id,
      },
    });
  };
  const handleTransferDeleteCancel = () => {
    DealState[1]('CANCEL');
  };

  const handleUpdateTransfer = (deal) => {
    // console.log('handleUpdateTransfer')
    DealState[1]({ type: 'TRANSFER_EDIT', data: deal.transfer_id });
  };
  const handleTransferEditSubmit = (values) => {
    DealState[1]({ type: 'SUBMIT', data: values });
  };

  return (
    <Paper>
      {['transfer.edit.show', 'transfer.edit.update.process', 'transfer.edit.update.failure'].some(
        DealState[0].matches
      ) && (
        <DealTransferEditDialog
          open={true}
          loading={DealState[0].matches('transfer.edit.update.process')}
          data={transferDetail}
          contact={transferDetail.deal.contact}
          errors={dealErrors}
          onSubmit={handleTransferEditSubmit}
          onClose={() => DealState[1]('CLOSE')}
        />
      )}
      <Alerts.Loading
        open={DealState[0].matches('transfer.edit.update.process')}
        message={Localization.deal.transfer.edit.update.process}
      />
      <Alerts.Failure
        open={DealState[0].matches('transfer.edit.update.failure')}
        message={Localization.deal.transfer.edit.update.failure}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Success
        open={DealState[0].matches('transfer.edit.update.success')}
        message={Localization.deal.transfer.edit.update.success}
        onClose={() => DealState[1]('CLOSE')}
      />

      <Alerts.Loading
        open={DealState[0].matches('transfer.edit.fetch.process')}
        message={Localization.deal.transfer.edit.fetch.process}
      />
      <Alerts.Failure
        open={DealState[0].matches('transfer.edit.fetch.failure')}
        message={Localization.deal.transfer.edit.fetch.failure}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={DealState[0].matches('transfer.edit.fetch.failure')}
        message={Localization.deal.transfer.edit.fetch.failure}
        onClose={() => DealState[1]('CLOSE')}
      />

      {(() => {
        if (DealState[0].matches('transfer.remove.confirm')) {
          return (
            <DealTransferRemoveConfirm
              open={true}
              onConfirm={handleTransferDeleteConfirm}
              onCancel={handleTransferDeleteCancel}
            />
          );
        }
      })()}
      <Alerts.Loading
        open={DealState[0].matches('transfer.remove.process')}
        message={Localization.deal.edit.transfer.remove.process}
      />
      <Alerts.Success
        open={DealState[0].matches('transfer.remove.success')}
        message={Localization.deal.edit.transfer.remove.success}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={DealState[0].matches('transfer.remove.failure')}
        message={Localization.deal.edit.transfer.remove.failure}
        onClose={() => DealState[1]('RETRY')}
      />
      {(() => {
        const hasDeal = Boolean(deal);
        if (hasDeal) {
          return (
            <DealTransferDialog
              isSmsf={deal.contact.has_smsf}
              maxAmount={deal.balance}
              open={['transfer.show', 'transfer.process', 'transfer.failure'].some(
                DealState[0].matches
              )}
              contact={deal.contact}
              onClose={() => DealState[1]('CANCEL')}
              onSubmit={handleTransferSubmit}
              errors={dealErrors}
              loading={DealState[0].matches('transfer.process')}
            />
          );
        }
      })()}
      <Alerts.Loading
        open={DealState[0].matches('transfer.process')}
        message={Localization.deal.edit.transfer.process}
      />
      <Alerts.Success
        open={DealState[0].matches('transfer.success')}
        message={Localization.deal.edit.transfer.success}
        onClose={() => DealState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={DealState[0].matches('transfer.failure')}
        message={Localization.deal.edit.transfer.failure}
        onClose={() => DealState[1]('CLOSE')}
      />
      <TableComponent
        data={deals}
        headCells={headCells}
        handleView={(deal) => handleView(deal)}
        handleEdit={(deal) => handleEdit(deal)}
        handleDelete={(deal) => handleDelete(deal)}
        handleTransfer={(deal) => handleTransfer(deal)}
        handleRemoveTransfer={handleRemoveTransfer}
        onUpdateTransfer={handleUpdateTransfer}
      />
    </Paper>
  );
};

DealList.propTypes = {
  deals: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

DealList.defaultValues = {
  deals: [],
};

export default DealList;
