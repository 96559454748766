const TYPE = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  INVEST: 'Invest',
  LOAN: 'Loan',
  TRANSFER: 'Transfer to Bank',
};

const INVESTMENT_OPTION_TYPE = {
  SMSF: 'SMSF',
  NON_SMSF: 'Non-SMSF',
};
const BANKACCOUNT_OPTION_TYPE = {
  SPV_1: 'SPV 1',
  SPV_2: 'SPV 2',
};
const EBX = {
  EBX_1: 'EBX 1',
  EBX_2: 'EBX 2',
};
const BACKACCOUNT_TO_EBX = {
  [BANKACCOUNT_OPTION_TYPE.SPV_1]: EBX.EBX_1,
  [BANKACCOUNT_OPTION_TYPE.SPV_2]: EBX.EBX_2,
};
const BOARD_TYPE = {
  ALL: 'Show All',
  INVEST: TYPE.INVEST,
  LOAN: TYPE.LOAN,
  BANK_TRANSFER: 'Transfer',
};

export default {
  TYPE,
  INVESTMENT_OPTION_TYPE,
  BANKACCOUNT_OPTION_TYPE,
  EBX,
  BACKACCOUNT_TO_EBX,
  BOARD_TYPE,
};
